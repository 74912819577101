import { humanize } from "constants/common"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { Card, CardBody, Col, Modal, ModalBody, Row } from "reactstrap"

const PoliciesCard = ({ policies }) => {
  const [showModal, setShowModal] = useState(false)
  const [modalData, setModalData] = useState()

  const toggleModal = data => {
    setShowModal(!showModal)
    setModalData(data)
  }

  return (
    <Card>
      <CardBody>
        <Row className="d-flex flex-column">
          {policies.map(el => {
            return (
              <div
                key={el.id}
                className="d-flex justify-content-between align-items-center p-2 rounded"
              >
                <div className="w-25">
                  <p className="mb-0 font-size-14 fw-bold">
                    {el.user.user_name}
                  </p>
                  <p className="font-size-12 text-muted mb-0">
                    {humanize(el?.user?.role?.department)}
                  </p>
                </div>
                <div className="d-flex justify-content-between align-items-center flex-column w-25">
                  {el?.interest_approval?.status ? (
                    <p
                      className={`mb-0 badge badge-pill font-size-11 badge-soft-${
                        el?.interest_approval?.status == "APPROVED"
                          ? "success"
                          : "danger"
                      }`}
                    >
                      {el?.interest_approval?.status}
                    </p>
                  ) : (
                    <p
                      className={`mb-0 badge badge-pill font-size-11 badge-soft-primary`}
                    >
                      PENDING
                    </p>
                  )}
                </div>
                <div className="d-flex justify-content-between align-items-start flex-column w-50">
                  <p className="mb-0 font-size-14 w-100">
                    {el?.interest_approval?.comment ? (
                      el.interest_approval?.comment.length > 60 ? (
                        <>
                          <span className="d-flex gap-4">
                            {el.interest_approval?.comment.substring(0, 60)}...
                            <span
                              onClick={() => toggleModal(el)}
                              className="text-primary"
                              style={{ cursor: "pointer" }}
                            >
                              Read more
                            </span>
                          </span>
                        </>
                      ) : (
                        el.interest_approval?.comment
                      )
                    ) : (
                      "NA"
                    )}
                  </p>
                </div>
              </div>
            )
          })}
        </Row>
      </CardBody>
      <Modal
        isOpen={showModal}
        toggle={toggleModal}
        backdrop="static"
        keyboard={false}
        scrollable={true}
      >
        <div className="modal-header">
          <h4 className="modal-title mt-0">User Comments</h4>
          <button
            type="button"
            onClick={toggleModal}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ModalBody className="pb-0">
          <Row>
            <Col className="row">
              <div className="mb-1">
                <h6>User Name</h6>
                <p
                  className="font-size-15"
                  style={{
                    fontSize: "13px",
                  }}
                >
                  {modalData?.user?.user_name}
                </p>
              </div>
              <div className="mb-1">
                <h6>Email</h6>
                <p
                  className="font-size-15"
                  style={{
                    fontSize: "13px",
                  }}
                >
                  {modalData?.user?.email}
                </p>
              </div>
              <div className="mb-1">
                <h6>Department</h6>
                <p
                  className="font-size-15"
                  style={{
                    fontSize: "13px",
                  }}
                >
                  {humanize(modalData?.user?.role?.department)}
                </p>
              </div>
              <div className="mb-1">
                <h6>Status</h6>
                <p
                  className="font-size-15"
                  style={{
                    fontSize: "13px",
                  }}
                >
                  {modalData?.interest_approval?.status ? (
                    <p
                      className={`mb-0 badge badge-pill font-size-11 badge-soft-${
                        modalData?.interest_approval?.status == "APPROVED"
                          ? "success"
                          : "danger"
                      }`}
                    >
                      {modalData?.interest_approval?.status}
                    </p>
                  ) : (
                    <p
                      className={`mb-0 badge badge-pill font-size-11 badge-soft-primary`}
                    >
                      PENDING
                    </p>
                  )}
                </p>
              </div>
              <div className="mb-1">
                <h6>Comments</h6>
                <p
                  className="font-size-15"
                  style={{
                    fontSize: "13px",
                  }}
                >
                  {modalData?.interest_approval?.comment}
                </p>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </Card>
  )
}

PoliciesCard.propTypes = {
  policies: PropTypes.array,
}

export default PoliciesCard
