import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import Loader from "common/Loader"
import { permissionChecker } from "common/PermissionChecker"
import Unauthorized from "common/Unauthorized"
import { toast } from "react-toastify"
import { axiosInstance } from "ConfigAxioxinstance"
import ReactSelect from "constants/ReactSelect"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import { Link } from "@material-ui/core"
import { AvField, AvForm } from "availity-reactstrap-validation"
import nodata from "../../assets/images/nodata/no-data.svg"
import paginationFactory from "react-bootstrap-table2-paginator"
import { csvDownloadData, handleValidDate } from "constants/common"
import { JSONToCSVConvertor } from "common/jsontocsv"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import ProjectDetailCard from "components/Common/ProjectDetailCard"
import BenposProjectCard from "components/Common/BenposProjectCard"
import PoliciesCard from "components/Common/PoliciesCard"
import { exceptionReportFilters } from "constants/ConstantFields"

const TrancheAllocation = () => {
  const [loading, setLoading] = useState(false)
  const [projectBenposData, setProjectBenposData] = useState([])
  const [data, setData] = useState([])
  const [selectedInvestment, setSelectedInvestment] = useState()
  const [selectedBenposDate, setSelectedBenposDate] = useState()
  const [accessControlData, setAccessControlData] = useState([])
  const [selectedBenpos, setSelectedBenpos] = useState()
  const [editModal, setEditModal] = useState(false)
  const [showError, setShowError] = useState("")
  const [inputTranches, setInputTranches] = useState([])
  const [tdsApplicable, setTdsApplicable] = useState()
  const [policies, setPolicies] = useState([])
  const [approveModal, setApproveModal] = useState(false)
  const [selectedProject, setSelectedProject] = useState()
  const [selectedAction, setSelectedAction] = useState("")
  const [actionReason, setActionReason] = useState("")
  const [consent, setConsent] = useState(false)
  const [myPolicy, setMyPolicy] = useState(null)
  const [checkedReports, setCheckedReports] = useState(
    exceptionReportFilters.map(item => ({ ...item, checked: false }))
  )

  const localUser = JSON.parse(localStorage.getItem("userInfo"))

  const params = useParams()
  const { benposdate, investmentId } = params

  useEffect(() => {
    const permissions = permissionChecker("/benpos-master")
    setAccessControlData(permissions)
  }, [])

  const getApprovalPolicy = async () => {
    try {
      const res = await axiosInstance.get(
        `/interest-approval-policy?investmentId=${investmentId}&benpos_date=${benposdate}&report_type=TRANCHE_ALLOCATION`
      )
      setMyPolicy(res.data.data.find(item => item.userId == localUser.id))
      setPolicies(res.data.data)
    } catch (error) {
      toast.error(error.message)
    }
  }

  useEffect(() => {
    getApprovalPolicy()
  }, [])

  const ProjectAndBenposDates = async () => {
    setProjectBenposData([])
    const url = `benpos-master?$limit=10&$skip=0&$sort[created_at]=-1`

    try {
      setLoading(true)
      const response = await axiosInstance.get(url)

      if (response?.data?.data?.length) {
        const respData = response.data.data

        const groupedData = respData.reduce((acc, item) => {
          if (!acc.has(item.investmentId)) {
            acc.set(item.investmentId, {
              id: item.investmentId,
              project_name: item.project_name,
              benpos_dates: [],
            })
          }

          acc.get(item.investmentId).benpos_dates.push({
            id: item.benpos_date,
            date: handleValidDate(item.benpos_date),
          })

          return acc
        }, new Map())

        setProjectBenposData(Array.from(groupedData.values()))
      }
    } catch (error) {
      toast.error(error?.message)
    } finally {
      setLoading(false)
    }
  }

  const masterData = async () => {
    const url = `benpos-report/tranche-allocation-listing?investmentId=${investmentId}&benpos_date=${benposdate}`
    try {
      setLoading(true)
      const response = await axiosInstance.get(url)
      if (response) {
        setData(response?.data?.data || response?.data)
      }
    } catch (error) {
      toast.error(error?.message)
    } finally {
      setLoading(false)
    }
  }

  const defaultSorted = [
    {
      dataField: "created_at",
      order: "desc",
    },
  ]

  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "60",
        value: 60,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  }

  const { SearchBar } = Search

  useEffect(() => {
    ProjectAndBenposDates()
  }, [])

  useEffect(() => {
    masterData()
  }, [])

  const toggleApproveModal = () => {
    setApproveModal(!approveModal)
  }
  const handleApprove = () => {
    setSelectedAction("APPROVE")
    setActionReason("")
    setConsent(false)
    setCheckedReports(checkedReports.map(prev => ({ ...prev, checked: false })))
    toggleApproveModal()
  }
  const handleReject = () => {
    setSelectedAction("REJECT")
    setActionReason("")
    setConsent(false)
    toggleApproveModal()
  }

  const handleReportAction = async (e, values) => {
    try {
      setLoading(true)
      const payload = {
        modifiedById: localUser.id,
        investmentId: investmentId,
        benpos_date: benposdate,
        status: selectedAction == "APPROVE" ? "APPROVED" : "REJECTED",
        report_type: "TRANCHE_ALLOCATION",
        comment: actionReason,
      }
      const res = await axiosInstance.post(`/interest-approval`, payload)
      if (res) {
        await getApprovalPolicy()
        toast.success(
          `${
            selectedAction == "APPROVE" ? "Approved" : "Rejected"
          } Successfully`
        )
        toggleApproveModal()
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  const TableColumns = download => {
    let columns = [
      {
        dataField: "action",
        isDummyField: true,
        text: "Action",
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, order) => {
          if (
            !(
              order?.displayName === "No. of Bonds" ||
              order?.displayName === "Common" ||
              order?.displayName === "Sub-Total" ||
              order?.displayName === "Grand-Total"
            )
          ) {
            if (
              policies.some(
                item => item.interest_approval?.status == "APPROVED"
              )
            ) {
              return "NA"
            }
            return (
              <div className="d-flex gap-3">
                <Link
                  to="#"
                  className="text-success"
                  onClick={() => {
                    toggleEditModal(order)
                  }}
                >
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                </Link>
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </div>
            )
          }
          return ""
        },
      },
      {
        dataField: "displayName",
        text: "Particulars",
        sort: false, // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => {
          if (
            cellContent === "No. of Bonds" ||
            cellContent === "Common" ||
            cellContent === "Sub-Total" ||
            cellContent === "Grand-Total"
          ) {
            return <b>{cellContent}</b>
          } else {
            return <span>{cellContent}</span>
          }
        },
      },
    ]
    // Adding Tranch Headings
    let tranchHeaders =
      data[0]?.tranches?.map(item => ({
        dataField: item?.investment_lot_name,
        text: item?.investment_lot_name,
        sort: false,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => {
          if (row?.displayName === "Sub-Total") {
            return (
              <span>
                {row?.tranches?.find(
                  el => el?.investment_lot_name === item?.investment_lot_name
                )?.total || 0}
              </span>
            )
          } else if (row?.displayName === "Grand-Total") {
            return ""
          } else {
            return (
              row?.tranches?.find(
                el => el?.investment_lot_name === item?.investment_lot_name
              )?.lot_size || 0
            )
          }
        },
      })) || []

    columns.push(...tranchHeaders)

    let totalHeader = {
      dataField: "total",
      text: "Total",
      sort: false,
    }

    let tdsHeader = {
      dataField: "tds_applicable",
      text: "TDS Applicable",
      sort: false,
    }

    columns.push(totalHeader, tdsHeader)

    return columns
  }

  const toggleEditModal = data => {
    setSelectedBenpos(data)
    setInputTranches(data?.tranches)
    setTdsApplicable(data?.tds_applicable)
    setEditModal(!editModal)
  }

  const onModalSubmitHandler = async (e, values) => {
    try {
      setLoading(true)

      const postData = []

      for (let item of inputTranches) {
        if (item.lot_size !== 0) {
          postData.push({
            investmentLotId: item.investmentId,
            benposId: selectedBenpos?.id,
            bonds: item.lot_size,
          })
        }
      }

      await axiosInstance.patch(`benpos/${selectedBenpos?.id}`, {
        tds_applicable: tdsApplicable,
      })

      const allLotSizesZero = selectedBenpos.tranches.every(
        tranche => tranche.lot_size === 0
      )

      if (
        !selectedBenpos.tranches ||
        selectedBenpos.tranches.length === 0 ||
        allLotSizesZero
      ) {
        // Bulk Post
        await axiosInstance.post("benpos-tranche-allocation", postData)
      } else {
        const postPromises = postData.map(async data => {
          const existingData = selectedBenpos.tranches.find(
            tranche => tranche.investmentId === data.investmentLotId
          )
          if (existingData) {
            if (existingData.lot_size !== data.bonds) {
              // Data is already there but value is changed
              await axiosInstance.patch(
                `benpos-tranche-allocation/${existingData.allocationId}`,
                data
              )
            }
          } else {
            await axiosInstance.post("benpos-tranche-allocation", data)
          }
        })

        await Promise.all(postPromises)
      }

      masterData()
      toggleEditModal()
      toast.success("Tranche Allocation Successful")
    } catch (error) {
      toast.error(error?.message)
    } finally {
      setLoading(false)
    }
  }
  const handleValidInputTranches = (e, item) => {
    const newValue = Number(e.target.value || 0)

    if (Array.isArray(inputTranches)) {
      const updatedTranches = inputTranches.map(tranche =>
        tranche.investmentId === item.investmentId
          ? { ...tranche, lot_size: newValue }
          : tranche
      )
      setInputTranches(updatedTranches)
    }
  }

  const validationChecker = () => {
    const no_of_bonds = data?.find(item => item.displayName === "No. of Bonds")
    const common = data?.find(item => item.displayName === "Common")
    const subTotal = data?.find(item => item.displayName === "Sub-Total")

    const inputTotal = inputTranches?.reduce(
      (acc, item) => acc + item.lot_size,
      0
    )

    inputTranches.forEach(item => {
      // commmon + all investor particular tranche sum + input <= no of bonds
      const tranchCommon = common.tranches.find(
        el => el.investment_lot_name === item.investment_lot_name
      )
      const trancheNoofBonds = no_of_bonds.tranches.find(
        el => el.investment_lot_name === item.investment_lot_name
      )

      const tranchSubTotal = subTotal.tranches.find(
        el => el.investment_lot_name === item.investment_lot_name
      )
      if (
        tranchCommon.lot_size + tranchSubTotal.total + item.lot_size >
        trancheNoofBonds.lot_size
      ) {
        setShowError(true)
      } else {
        setShowError(false)
      }
    })
    // Row Validation
    if (inputTotal > selectedBenpos?.total) {
      setShowError(true)
    } else {
      setShowError(false)
    }
  }

  useEffect(() => {
    inputTranches && inputTranches?.length > 0 && validationChecker()
  }, [inputTranches])

  const downloadData = async () => {
    try {
      const tableData = data
      const csvTableHeaders = TableColumns(true)
      const str = JSON.stringify(tableData)
      const arr = JSON.parse(str)
      arr?.forEach(item => {
        item.tranches.forEach(tranche => {
          item[tranche.investment_lot_name] =
            tranche.lot_size || tranche.total || 0
        })
        delete item.tranches
      })
      const downloadableArr = csvDownloadData(csvTableHeaders, arr)
      JSONToCSVConvertor(downloadableArr, "TranceAllocation", true)
    } catch (error) {
      toast.error(error?.message)
    }
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        {accessControlData?.permissions?.BENPOS_TRANCHE_ALLOCATION ? (
          <Container fluid>
            <Breadcrumbs breadcrumbItem={accessControlData?.pageName} />
            <BenposProjectCard
              investmentId={investmentId}
              benpos_date={benposdate}
              onLoadComplete={setSelectedProject}
            />
            <PoliciesCard policies={policies} />
            <Row>
              <Col xs="12">
                <Row className="mb-2 row justify-content-end align-items-center">
                  <div className="col-auto">
                    {myPolicy?.id ? (
                      <>
                        <Button
                          type="button"
                          color="success"
                          className="btn-rounded  mb-2 me-2"
                          style={{ marginRight: "3px" }}
                          onClick={handleApprove}
                          // disabled={
                          //   myApprovalStatus?.status == "APPROVED"
                          // }
                        >
                          <i className="mdi mdi-check-circle"></i> Approve
                        </Button>
                        <Button
                          type="button"
                          color="danger"
                          className="btn-rounded  mb-2 me-2"
                          style={{ marginRight: "3px" }}
                          onClick={handleReject}
                          // disabled={
                          //   myApprovalStatus?.status == "REJECTED"
                          // }
                        >
                          <i className="mdi mdi-close-circle"></i> Reject
                        </Button>
                      </>
                    ) : null}
                    <Button
                      type="button"
                      color="primary"
                      className="btn-rounded  mb-2 me-2"
                      style={{ marginRight: "3px" }}
                      onClick={downloadData}
                    >
                      <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                      Download
                    </Button>
                  </div>
                </Row>
                {!loading && data && data.length > 0 && (
                  <Card>
                    <CardBody>
                      <ToolkitProvider
                        keyField="id"
                        data={data}
                        columns={TableColumns()}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2 row">
                              <Col md={3}>
                                <div className="search-box me-2 mb-1 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            {/* Table and Modal start */}
                            <Row>
                              <Col xl="12">
                                {data.length ? (
                                  <BootstrapTable
                                    keyField="id"
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    columns={TableColumns()}
                                    data={data}
                                    pagination={paginationFactory(pageOptions)}
                                    wrapperClasses={
                                      "table-responsive mb-4 table-fixed"
                                    }
                                    classes={
                                      "table align-middle table-nowrap table-check"
                                    }
                                    headerWrapperClasses={"table-light"}
                                    {...toolkitProps.baseProps}
                                  />
                                ) : null}
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    </CardBody>
                  </Card>
                )}
              </Col>
            </Row>
            <Modal isOpen={editModal} toggle={toggleEditModal}>
              <ModalHeader toggle={toggleEditModal} tag="h4">
                Tranche Allocation
              </ModalHeader>
              <ModalBody>
                <AvForm onValidSubmit={onModalSubmitHandler}>
                  <Row form>
                    <Col className="col-12">
                      <div className="row">
                        <Col className="col-6">
                          <h6>Investor Name</h6>
                          <p>{selectedBenpos?.displayName}</p>
                        </Col>
                        <Col className="col-6">
                          <h6>Debentures</h6>
                          <p>{selectedBenpos?.total}</p>
                        </Col>

                        {selectedBenpos?.tranches?.map((item, index) => {
                          return (
                            <div
                              className="mb-3 col-6"
                              key={
                                item?.investmentId || item?.investment_lot_name
                              }
                            >
                              <AvField
                                name={item?.investmentId}
                                label={item?.investment_lot_name}
                                type="number"
                                value={item?.lot_size || 0}
                                onChange={e =>
                                  handleValidInputTranches(e, item)
                                }
                              />
                            </div>
                          )
                        })}

                        <div className="mb-3">
                          <AvField
                            name={"tds_status"}
                            label="TDS Applicable"
                            type="checkbox"
                            value={tdsApplicable}
                            onChange={e => {
                              setTdsApplicable(e.target.checked)
                            }}
                          />
                        </div>
                        {showError && (
                          <p className="text-danger">
                            Debentures should be less then total
                          </p>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <Button
                          type="submit"
                          color="success"
                          className="save-user"
                          disabled={showError}
                        >
                          Save
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </ModalBody>
            </Modal>
            {/* Approve Modal */}
            <Modal isOpen={approveModal} toggle={toggleApproveModal}>
              <ModalHeader toggle={toggleApproveModal} tag="h4">
                {selectedAction == "APPROVE" ? "Approve" : "Reject"} Tranche
                Allocation & TDS Status
              </ModalHeader>
              <ModalBody>
                <AvForm onValidSubmit={handleReportAction}>
                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <div>Project Name</div>
                        <p
                          className="fw-medium font-size-15"
                          style={{
                            fontSize: "13px",
                          }}
                        >
                          {selectedProject?.project_name || "NA"}
                        </p>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <div>Benpos Date</div>
                        <p
                          className="fw-medium font-size-15"
                          style={{
                            fontSize: "13px",
                          }}
                        >
                          {handleValidDate(benposdate) || "NA"}
                        </p>
                      </div>
                    </Col>

                    <div className="mt-2">
                      <AvField
                        name="description"
                        label={
                          selectedAction == "APPROVE" ? "Comment" : "Reason"
                        }
                        type="textarea"
                        errorMessage="Invalid value"
                        validate={{
                          required: { value: true },
                        }}
                        value={actionReason || ""}
                        onChange={e => setActionReason(e.target.value)}
                        rows={3}
                      ></AvField>
                    </div>
                    <div className="mt-4">
                      <AvField
                        name={"consent"}
                        label={
                          <span className={consent ? "" : "text-muted"}>
                            I have checked the tranche allocation & tds status
                            and i {selectedAction} it.
                          </span>
                        }
                        type="checkbox"
                        checked={consent}
                        value={consent}
                        onChange={e => setConsent(e.target.checked)}
                      ></AvField>
                    </div>
                    <Col>
                      <div className="text-end gap-4 mt-2">
                        <button
                          type="submit"
                          className={`btn w-100 ${
                            selectedAction == "APPROVE"
                              ? "btn-success"
                              : "btn-danger"
                          } save-user`}
                          disabled={!consent}
                        >
                          {selectedAction == "APPROVE" ? "Approve" : "Reject"}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </ModalBody>
            </Modal>
          </Container>
        ) : (
          <Unauthorized />
        )}
      </div>
    </React.Fragment>
  )
}

export default TrancheAllocation
