import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import Breadcrumbs from "components/Common/Breadcrumb"
import Loader from "common/Loader"
import { Button, Card, CardBody, Col, Container, Row, Input } from "reactstrap"
import { permissionChecker } from "common/PermissionChecker"
import ReactSelect from "constants/ReactSelect"
import { csvDownloadData } from "constants/common"
import Unauthorized from "common/Unauthorized"
import { JSONToCSVConvertor } from "common/jsontocsv"
import { withRouter, useLocation, useHistory } from "react-router-dom"
import ParentDropdown from "components/Common/ParentDropdown"

const PayoutSummary = () => {
  const [loading, setLoading] = useState(false)
  const [tableData, setTableData] = useState([])
  const [limit, setLimit] = useState(10)
  const [skip, setSkip] = useState(0)
  const [total, setTotal] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [distributorList, setDistributorList] = useState([])
  const [rmManager, setRmManager] = useState([])
  const [users, setUsers] = useState([])

  const [userFilter, setUserFilter] = useState("")
  const [distributorFilter, setDistributorFilter] = useState()
  const [rmFilter, setRmFilter] = useState()
  const [parentFilter, setParentFilter] = useState()
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()

  const [accessControlData, setAccessControlData] = useState([])

  const localUser = JSON.parse(localStorage.getItem("userInfo"))
  const isDist = localUser?.role?.department == "Distributor"
  const isRm = localUser?.role?.department == "Relationship_Manager"

  const location = useLocation()
  const history = useHistory()
  const urlData = new URLSearchParams(location.search)
  let urlStateDateFilter = urlData.get("start_date")
  let urlEndDateFilter = urlData.get("end_date")

  useEffect(() => {
    const initializeFilters = () => {
      if (urlStateDateFilter) {
        setStartDate(urlStateDateFilter)
      } else {
        setStartDate("")
      }
      if (urlEndDateFilter) {
        setEndDate(urlEndDateFilter)
      } else {
        setEndDate("")
      }
    }

    initializeFilters()
  }, [location])

  useEffect(() => {
    const permissions = permissionChecker()
    setAccessControlData(permissions)
  }, [])

  useEffect(() => {
    if (!isDist) {
      GetDistributorAndRMDetails()
    }
  }, [])

  const GetDistributorAndRMDetails = async () => {
    try {
      const response = await axiosInstance.get(`users?permissions=OPS_USER`)
      const userResponse = response?.data?.data || response?.data
      if (userResponse) {
        const distributor = userResponse?.filter(user => {
          if (user?.role?.department === "Distributor") {
            // user.user_name = user.distributor_kyc?.name
            if (isRm && user.relManagerId != localUser.id) {
              return false
            }
            return true
          }
        })
        setDistributorList(distributor)
        const relationship_manager = userResponse?.filter(
          ele => ele.roleId === 1
        )
        setRmManager(relationship_manager)
      }
    } catch (error) {
      toast.error(error?.message)
    }
  }

  const updateQueryParams = (key, value) => {
    const currentParams = new URLSearchParams(window.location.search || "")

    if (value) {
      currentParams.set(key, value)
    } else {
      currentParams.delete(key)
    }

    history.replace({
      search: currentParams.toString() ? `?${currentParams.toString()}` : "",
    })
  }

  const defaultSorted = [
    {
      dataField: "updated_at",
      order: "desc",
    },
  ]

  useEffect(() => {
    if (
      !((urlStateDateFilter && !startDate) || (urlEndDateFilter && !endDate))
    ) {
      masterData()
    }
  }, [
    limit,
    skip,
    distributorFilter,
    rmFilter,
    startDate,
    endDate,
    parentFilter,
  ])

  useEffect(() => {
    if (!userFilter || userFilter?.length >= 3) {
      masterData()
    }
  }, [userFilter])

  const dollarIndianLocale = Intl.NumberFormat("en-IN")

  const handleAmount = amount => {
    return dollarIndianLocale.format(Math.round(amount))
  }

  const filterUrl = `${userFilter.length >= 3 ? `&name=${userFilter}` : ``}${
    isDist ? `&createdById=${localUser.id}` : ""
  }${distributorFilter ? `&createdById=${distributorFilter.id}` : ""}${
    isRm ? `&relManagerId=${localUser.id}` : ""
  }${rmFilter ? `&relManagerId=${rmFilter.id}` : ""}${
    parentFilter ? `&parentId=${parentFilter.id}` : ""
  }${startDate ? `&start_date=${startDate}` : ""}${
    endDate ? `&end_date=${endDate}` : ""
  }`

  const downloadData = async () => {
    try {
      setLoading(true)

      const getUrl = `/payout-summary?$sort[updated_at]=-1${filterUrl}`
      const response = await axiosInstance.get(getUrl)
      if (response) {
        const data = response?.data?.data || response?.data
        const csvTableHeaders = TableColumns()
        const str = JSON.stringify(data)
        const arr = JSON.parse(str)
        arr.forEach(el => {
          el.distributor_name = el?.distributor_name || "DIRECT"
          el.interest = handleAmount(el.interest)
          el.tds = handleAmount(el.tds)
          el.repayment = handleAmount(el.repayment)
          el.prepayment = handleAmount(el.prepayment)
          el.total = handleAmount(el.total)
          el.family_interest = handleAmount(el.family_interest)
          el.family_tds = handleAmount(el.family_tds)
          el.family_repayment = handleAmount(el.family_repayment)
          el.family_prepayment = handleAmount(el.family_prepayment)
          el.family_total = handleAmount(el.family_total)
          return el
        })
        const downloadableArr = csvDownloadData(csvTableHeaders, arr)
        JSONToCSVConvertor(downloadableArr, "Payout Summary", true)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      toast.error(error?.message)
    }
  }

  const TableColumns = () => {
    const columns = [
      {
        dataField: "investor_name",
        text: "Investor Pan Name",
        sort: true,
      },
      {
        dataField: "interest",
        text: "Interest Payment",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <div className="text-end">{handleAmount(cellContent)}</div>
        ),
      },
      {
        dataField: "tds",
        text: "TDS",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <div className="text-end">{handleAmount(cellContent)}</div>
        ),
      },
      {
        dataField: "repayment",
        text: "Repayment",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <div className="text-end">{handleAmount(cellContent)}</div>
        ),
      },
      {
        dataField: "prepayment",
        text: "Prepayment",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <div className="text-end">{handleAmount(cellContent)}</div>
        ),
      },
      {
        dataField: "total",
        text: "Total Payout",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <div className="text-end fw-bold">{handleAmount(cellContent)}</div>
        ),
      },
      {
        dataField: "family_interest",
        text: "Family Interest Payment",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <div className="text-end">{handleAmount(cellContent)}</div>
        ),
      },
      {
        dataField: "family_tds",
        text: "Family TDS",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <div className="text-end">{handleAmount(cellContent)}</div>
        ),
      },
      {
        dataField: "family_repayment",
        text: "Family Repayment",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <div className="text-end">{handleAmount(cellContent)}</div>
        ),
      },
      {
        dataField: "family_prepayment",
        text: "Family Prepayment",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <div className="text-end">{handleAmount(cellContent)}</div>
        ),
      },
      {
        dataField: "family_total",
        text: "Family Total Payout",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <div className="text-end fw-bold">{handleAmount(cellContent)}</div>
        ),
      },
      {
        dataField: "parent_name",
        text: "Parent Name",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => <div>{cellContent}</div>,
      },
    ]
    if (!isDist) {
      columns.push({
        dataField: "distributor_name",
        text: "Distributor Name",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <div>{cellContent ? cellContent : "DIRECT"}</div>
        ),
      })
    }
    if (!isDist && !isRm) {
      columns.push({
        dataField: "rm_name",
        text: "RM Name",
        sort: true,
      })
    }
    return columns
  }

  const masterData = async () => {
    setLoading(true)

    try {
      const getUrl = `/payout-summary?$sort[updated_at]=-1&$limit=${limit}&$skip=${skip}${filterUrl}`
      let response = await axiosInstance.get(getUrl)

      if (response) {
        setTotal(response.data.total)
        const data = response?.data?.data || response?.data
        setTableData(data)
        let pages = Math.ceil(
          (response.data?.total || response.total) /
            (response.data?.limit || response.limit)
        )
        setTotalPage(pages)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }

  // SSP Filters
  const handleFilterChange = (e, key) => {
    switch (key) {
      case "limit":
        setLimit(Number(e?.target?.value))
        break
      case "user":
        setUserFilter(e?.target?.value)
        break
      case "distributor":
        setDistributorFilter(e)
        break
      case "rm":
        setRmFilter(e)
        break
      case "parent":
        setParentFilter(e)
        break
      case "start_date":
        setStartDate(e?.target?.value)
        updateQueryParams("start_date", e?.target?.value)
        break
      case "end_date":
        setEndDate(e?.target?.value)
        updateQueryParams("end_date", e?.target?.value)
        break
      default:
        break
    }
    setSkip(0)
    setCurrentPage(1)
  }

  const onChangePagination = e => {
    const { value } = e.target
    if (value < 1 || value > totalPage) {
      return
    }
    setCurrentPage(value)
    if (value) {
      setSkip((value - 1) * limit)
    }
  }
  const handleNext = prev => {
    setSkip(prev + limit)
    let page = (prev + limit) / limit + 1
    setCurrentPage(page)
  }
  const handlePrevious = prev => {
    setSkip(prev - limit)
    let page = (prev - limit) / limit + 1
    setCurrentPage(page)
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        {accessControlData?.permissions?.PAYOUT_SUMMARY_VIEW ? (
          <Container fluid>
            <Breadcrumbs breadcrumbItem="Payout Summary" />
            <p
              className="text-muted font-size-13"
              style={{ marginTop: "-20px", marginBottom: "25px" }}
            >
              Note: Family Payout represents total payout of all the investors
              grouped by family
            </p>
            <Row>
              <Col xs="12">
                <Row className="mb-2 row justify-content-between">
                  <Col md={2}>
                    <select
                      className="form-select w-75"
                      value={limit}
                      onChange={e => handleFilterChange(e, "limit")}
                    >
                      {[10, 30, 50, 100].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                          Show {pageSize}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <div className="col-auto">
                    {accessControlData?.permissions
                      ?.PAYOUT_SUMMARY_DOWNLOAD && (
                      <Button
                        type="button"
                        color="primary"
                        className="btn-rounded  mb-2 me-2"
                        onClick={downloadData}
                      >
                        <i className="mdi mdi-arrow-down-bold-circle me-1" />
                        {
                          accessControlData?.permissions
                            ?.PAYOUT_SUMMARY_DOWNLOAD
                        }
                      </Button>
                    )}
                  </div>
                </Row>
                <Card>
                  <CardBody>
                    <ToolkitProvider
                      keyField="id"
                      data={tableData}
                      columns={TableColumns()}
                      bootstrap4
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2 row justify-content-start">
                            <Col md={3}>
                              <div className="position-relative">
                                <label>Search Investor (By Pan Name)</label>
                                <input
                                  onChange={e => handleFilterChange(e, "user")}
                                  id="search-bar-0"
                                  type="text"
                                  className="form-control rounded custom-input-height"
                                  placeholder={`Search`}
                                  value={userFilter || ""}
                                />
                              </div>
                            </Col>
                            {!isDist ? (
                              <Col md={3}>
                                <div className="mb-3">
                                  <label>Distributor</label>
                                  <ReactSelect
                                    users={[
                                      {
                                        id: "null",
                                        user_name: "DIRECT",
                                        email: "",
                                        phone: "",
                                      },
                                      ...distributorList,
                                    ]}
                                    setSelectedOption={e =>
                                      handleFilterChange(e, "distributor")
                                    }
                                    multiOptionLabel={true}
                                    optionLabelKeys={[
                                      "user_name",
                                      "email",
                                      "phone",
                                    ]}
                                    isClearable={true}
                                  />
                                </div>
                              </Col>
                            ) : (
                              <></>
                            )}
                            {!isDist && !isRm ? (
                              <Col md={3}>
                                <div className="mb-3">
                                  <label>Relationship Manager</label>
                                  <ReactSelect
                                    users={rmManager}
                                    setSelectedOption={e =>
                                      handleFilterChange(e, "rm")
                                    }
                                    multiOptionLabel={true}
                                    optionLabelKeys={[
                                      "user_name",
                                      "email",
                                      "phone",
                                    ]}
                                    isClearable={true}
                                  />
                                </div>
                              </Col>
                            ) : (
                              <></>
                            )}
                            <ParentDropdown
                              users={users}
                              setUsers={setUsers}
                              setLoading={setLoading}
                              handleFilterChange={handleFilterChange}
                            />
                            <Col md={3}>
                              <div className="mb-3">
                                <label>Payout Start Date</label>
                                <Input
                                  name="date"
                                  type="date"
                                  value={startDate}
                                  onChange={e => {
                                    handleFilterChange(e, "start_date")
                                  }}
                                  className="custom-input-height"
                                  max={endDate || undefined}
                                />
                              </div>
                            </Col>
                            <Col md={3}>
                              <div className="mb-3">
                                <label>Payout End Date</label>
                                <Input
                                  name="date"
                                  type="date"
                                  value={endDate}
                                  onChange={e =>
                                    handleFilterChange(e, "end_date")
                                  }
                                  className="custom-input-height"
                                  min={startDate || undefined}
                                />
                              </div>
                            </Col>
                          </Row>
                          {/* Table and Modal start */}
                          <Row>
                            <Col xl="12">
                              <BootstrapTable
                                keyField="id"
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                columns={TableColumns()}
                                data={tableData}
                                wrapperClasses={"table-responsive mb-4"}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                {...toolkitProps.baseProps}
                              />
                            </Col>
                          </Row>
                          {/* Table and Modal End */}
                          {/* SSP Pagination Start */}
                          <Row className="justify-content-md-space-between justify-content-center align-items-center">
                            <Col className="col-12 col-md-auto mb-3">
                              {`Showing ${total ? skip + 1 + " to" : ""} ${
                                limit > total || limit + skip > total
                                  ? total
                                  : limit + skip
                              } rows of ${total}
                 `}
                            </Col>
                            <Col>
                              <Row className="justify-content-md-end justify-content-center align-items-center">
                                <Col className="col-md-auto">
                                  <div className="d-flex gap-1">
                                    <Button
                                      color="primary"
                                      onClick={() => handlePrevious(limit)}
                                      disabled={currentPage == 1}
                                    >
                                      {"<<"}
                                    </Button>
                                    <Button
                                      color="primary"
                                      onClick={() => handlePrevious(skip)}
                                      disabled={currentPage == 1}
                                    >
                                      {"<"}
                                    </Button>
                                  </div>
                                </Col>
                                <Col className="col-md-auto d-none d-md-block">
                                  Page{" "}
                                  <strong>{`${
                                    currentPage ? currentPage : 1
                                  } of ${totalPage ? totalPage : 1}`}</strong>
                                </Col>
                                <Col className="col-md-auto">
                                  <Input
                                    type="number"
                                    min={1}
                                    style={{ width: 70 }}
                                    max={total == 0 ? 1 : totalPage}
                                    value={currentPage || 1}
                                    defaultValue={1}
                                    onChange={onChangePagination}
                                    disabled={total == 0}
                                  />
                                </Col>

                                <Col className="col-md-auto">
                                  <div className="d-flex gap-1">
                                    <Button
                                      color="primary"
                                      onClick={() => handleNext(skip)}
                                      disabled={
                                        currentPage == totalPage || total == 0
                                      }
                                    >
                                      {">"}
                                    </Button>
                                    <Button
                                      color="primary"
                                      onClick={() =>
                                        handleNext((totalPage - 2) * limit)
                                      }
                                      disabled={
                                        currentPage == totalPage || total == 0
                                      }
                                    >
                                      {">>"}
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          {/* SSP Pagination End */}
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        ) : (
          <Unauthorized />
        )}
      </div>
    </React.Fragment>
  )
}

export default withRouter(PayoutSummary)
