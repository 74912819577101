import React, { useEffect, useState } from "react"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import PropTypes from "prop-types"
import { toast } from "react-toastify"
import { axiosInstance } from "ConfigAxioxinstance"
import Loader from "common/Loader"
import "./styles.css"
import moment from "moment"

const AuditTrail = ({ recordId, setModal, modal }) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [currentLog, setCurrentLog] = useState(null)

  const toggleModal = () => {
    setModal(!modal)
  }

  useEffect(() => {
    if (modal) {
      fetchAuditData()
    }
  }, [modal])

  const fetchAuditData = async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(
        `audit-logs?$sort[created_at]=-1&recordId=${recordId}`
      )
      setData(response.data?.data || response.data || [])
    } catch (error) {
      toast.error(error?.message)
    } finally {
      setLoading(false)
    }
  }

  const toggleAccordion = id => {
    setCurrentLog(prev => (prev === id ? null : id))
  }

  return (
    <div>
      {loading && <Loader />}
      <Modal isOpen={modal} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal} tag="h4">
          Audit Trail
        </ModalHeader>
        <ModalBody>
          {data && data.length > 0 ? (
            <div className="timeline">
              {data.map(item => (
                <div
                  key={item.id}
                  className={`timeline-event ${
                    currentLog === item.id ? "expanded" : "collapsed"
                  }`}
                >
                  <div
                    className="timeline-header"
                    onClick={() => toggleAccordion(item.id)}
                  >
                    <div className="timeline-dot"></div>
                    <div className="timeline-content">
                      <div>
                        <p className="user-info">
                          {item.previous_state ? "Updated" : "Created"} By{" "}
                          {item.modifiedBy?.user_name || "Admin"}
                        </p>
                        <p className="timestamp">
                          {moment(item.created_at).format(
                            "DD MMM YYYY HH:mm:ss"
                          )}
                        </p>
                      </div>
                      <div className="timestamp-icon">
                        {currentLog === item.id ? (
                          <i className="fas fa-chevron-up"></i>
                        ) : (
                          <i className="fas fa-chevron-down"></i>
                        )}
                      </div>
                    </div>
                  </div>
                  {currentLog === item.id && (
                    <ul className="timeline-details mt-3">
                      {item.remark &&
                        item.remark.map((element, index) => (
                          <li key={index}>{element}</li>
                        ))}
                    </ul>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <p>No Audit trail found!</p>
          )}
        </ModalBody>
      </Modal>
    </div>
  )
}

AuditTrail.propTypes = {
  recordId: PropTypes.string,
  modal: PropTypes.bool,
  setModal: PropTypes.func,
}

export default AuditTrail
