import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { axiosInstance } from "ConfigAxioxinstance"
import PropTypes from "prop-types"
import { toast } from "react-toastify"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import Loader from "common/Loader"
import { permissionChecker } from "common/PermissionChecker"
import { useParams, Link } from "react-router-dom"
import BenposProjectCard from "components/Common/BenposProjectCard"
import AuditTrail from "common/AuditTrail/AuditTrail"
import Unauthorized from "common/Unauthorized"

const InterestAndRepaymentDashboard = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [accessControlData, setAccessControlData] = useState([])
  const localUser = JSON.parse(localStorage.getItem("userInfo"))
  const [selectedLogId, setSelectedLogId] = useState("")
  const [auditTrailModal, setAuditTrailModal] = useState(false)

  useEffect(() => {
    const permissions = permissionChecker("/benpos-master")
    setAccessControlData(permissions)
  }, [])

  const params = useParams()
  const { benposdate, investmentId } = params

  const masterData = async () => {
    try {
      setLoading(true)
      const response = await axiosInstance.get(
        `/interest-approval-policy?investmentId=${investmentId}&benpos_date=${benposdate}`
      )

      if (response) {
        const data = response?.data?.data || response?.data
        setData(data)
      }
    } catch (error) {
      toast.error(error?.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    masterData()
  }, [])

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        {accessControlData?.permissions?.BENPOS_VIEW ? (
          <Container fluid>
            <Breadcrumbs breadcrumbItem={"Interest and Repayment Dashboard"} />
            <BenposProjectCard
              benpos_date={benposdate}
              investmentId={investmentId}
            />
            <Row>
              <Col lg={4}>
                <Card className="mini-stats-wid h-100">
                  <CardBody>
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="fw-bold font-size-16">Exception Report</h5>
                      {/* {localUser?.permissions === "Admin" && (
                        <>
                          <Link
                            to="#"
                            onClick={() => {
                              // setSelectedLogId(row.id)
                              // setAuditTrailModal(!auditTrailModal)
                            }}
                          >
                            <i
                              className="mdi mdi-content-paste font-size-18"
                              id="logsToolTipException"
                            />
                          </Link>
                          <UncontrolledTooltip
                            placement="top"
                            target="logsToolTipException"
                          >
                            Logs
                          </UncontrolledTooltip>
                        </>
                      )} */}
                    </div>
                    <hr className="my-2" />
                    <div className="d-flex flex-column mt-3">
                      {data.map(item => {
                        if (item.report_type == "EXCEPTION_REPORT") {
                          return <UserDetails item={item} />
                        }
                      })}
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={4}>
                <Card className="mini-stats-wid h-100">
                  <CardBody>
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="fw-bold font-size-16">
                        Tranche Allocation & TDS Status
                      </h5>
                      {/* {localUser?.permissions === "Admin" && (
                        <>
                          <Link
                            to="#"
                            onClick={() => {
                              // setSelectedLogId(row.id)
                              // setAuditTrailModal(!auditTrailModal)
                            }}
                          >
                            <i
                              className="mdi mdi-content-paste font-size-18"
                              id="logsToolTipTranche"
                            />
                          </Link>
                          <UncontrolledTooltip
                            placement="top"
                            target="logsToolTipTranche"
                          >
                            Logs
                          </UncontrolledTooltip>
                        </>
                      )} */}
                    </div>
                    <hr className="my-2" />
                    <div className="d-flex flex-column mt-3">
                      {data.map(item => {
                        if (item.report_type == "TRANCHE_ALLOCATION") {
                          return <UserDetails item={item} />
                        }
                      })}
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={4}>
                <Card className="mini-stats-wid h-100">
                  <CardBody>
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="fw-bold font-size-16">
                        Interest Calculation
                      </h5>
                      {/* {localUser?.permissions === "Admin" && (
                        <>
                          <Link
                            to="#"
                            onClick={() => {
                              // setSelectedLogId(row.id)
                              // setAuditTrailModal(!auditTrailModal)
                            }}
                          >
                            <i
                              className="mdi mdi-content-paste font-size-18"
                              id="logsToolTipInterest"
                            />
                          </Link>
                          <UncontrolledTooltip
                            placement="top"
                            target="logsToolTipInterest"
                          >
                            Logs
                          </UncontrolledTooltip>
                        </>
                      )} */}
                    </div>
                    <hr className="my-2" />
                    <div className="d-flex flex-column mt-3">
                      {data.map(item => {
                        if (item.report_type == "INTEREST_CALCULATION") {
                          return <UserDetails item={item} />
                        }
                      })}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="my-4">
              <Col>
                <Card>
                  <CardBody>
                    <h5>Reports</h5>
                    <hr className="my-3" />
                    <div className="d-flex flex-column mt-3">
                      <Link
                        to={`/benpos-master?type=uploaded&investmentId=${investmentId}&benpos-date=${benposdate}`}
                        className="mb-3 font-size-14"
                      >
                        Uploaded BenPos File
                      </Link>
                      <Link
                        to={`/benpos-master?type=validation&investmentId=${investmentId}&benpos-date=${benposdate}`}
                        className="mb-3 font-size-14"
                      >
                        Validation Report
                      </Link>
                      <Link
                        to={`/benpos-master/bond-reconciliation?investmentId=${investmentId}&benpos-date=${benposdate}`}
                        className="mb-3 font-size-14"
                      >
                        Bond Reconciliation
                      </Link>
                      <Link
                        to={`/benpos-master/exception-report?investmentId=${investmentId}&benpos-date=${benposdate}`}
                        className="mb-3 font-size-14"
                      >
                        Exception Report
                      </Link>
                      <Link
                        to={`/benpos-master/tranche-allocation/${investmentId}/${benposdate}`}
                        className="mb-3 font-size-14"
                      >
                        Tranche Allocation & TDS Status
                      </Link>
                      <Link
                        to={`/benpos-master?type=interest&investmentId=${investmentId}&benpos-date=${benposdate}`}
                        className="mb-3 font-size-14"
                      >
                        Interest Calculation
                      </Link>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <AuditTrail
              recordId={selectedLogId}
              modal={auditTrailModal}
              setModal={setAuditTrailModal}
            />
          </Container>
        ) : (
          <Unauthorized />
        )}
      </div>
    </React.Fragment>
  )
}

const UserDetails = ({ item }) => {
  return (
    <div className="d-flex justify-content-between align-items-center">
      <div>
        <p className="mb-0 font-size-14">{item.user.user_name}</p>
        <p className="font-size-12 text-muted">
          {item.user?.role?.department?.replaceAll("_", " ")}
        </p>
      </div>
      {item.interest_approval?.status ? (
        <p
          className={`mb-0 badge badge-pill font-size-11 badge-soft-${
            item.interest_approval?.status == "APPROVED" ? "success" : "danger"
          }`}
        >
          {item.interest_approval?.status}
        </p>
      ) : (
        <p className={`mb-0 badge badge-pill font-size-11 badge-soft-primary`}>
          PENDING
        </p>
      )}
    </div>
  )
}
UserDetails.propTypes = {
  item: PropTypes.any,
}

export default InterestAndRepaymentDashboard
