import Loader from "common/Loader"
import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"

import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { toast } from "react-toastify"
import { axiosInstance } from "ConfigAxioxinstance"
import { JSONToCSVConvertor } from "common/jsontocsv"
import BootstrapTable from "react-bootstrap-table-next"

import moment from "moment"
import ReactSelect from "constants/ReactSelect"
import { permissionChecker } from "common/PermissionChecker"
import Unauthorized from "common/Unauthorized"
import { AvForm } from "availity-reactstrap-validation"
import ReactMultiSelect from "constants/ReactMultiSelect"

const departmentList = [
  { id: 1, value: "Investment_Team", label: "Investment Team" },
  { id: 2, value: "Data_Management_Team", label: "Asset Management Team" },
  { id: 3, value: "Operations_Team", label: "Operations Team" },
]

const REPORTS = [
  {
    id: "EXCEPTION_REPORT",
    name: "Exception Report",
    teams: {
      operation_team: {
        key: "operation_team",
        label: "Operation Team",
        selectedUsers: [],
      },
      asset_management_team: {
        key: "asset_management_team",
        label: "Asset Management Team",
        selectedUsers: [],
      },
    },
  },
  {
    id: "TRANCHE_ALLOCATION",
    name: "Tranche Allocation & TDS Status",
    teams: {
      operation_team: {
        key: "operation_team",
        label: "Operation Team",
        selectedUsers: [],
      },
      asset_management_team: {
        key: "asset_management_team",
        label: "Asset Management Team",
        selectedUsers: [],
      },
    },
  },
  {
    id: "INTEREST_CALCULATION",
    name: "Interest Calculation",
    teams: {
      operation_team: {
        key: "operation_team",
        label: "Operation Team",
        selectedUsers: [],
      },
      investment_team: {
        key: "investment_team",
        label: "Investment Team",
        selectedUsers: [],
      },
      asset_management_team: {
        key: "asset_management_team",
        label: "Asset Management Team",
        selectedUsers: [],
      },
    },
  },
]
const InterestApprovalPolicy = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [total, setTotal] = useState(0)
  const [investmentList, setInvestmentList] = useState([])
  const [selectedInvestment, setSelectedInvestment] = useState()

  const [accessControlData, setAccessControlData] = useState([])
  const [modal, setModal] = useState(false)
  const [userFilter, setUserFilter] = useState("")
  const [investmentFilter, setInvestmentFilter] = useState({})
  const [departmentFilter, setDepartmentFilter] = useState("")
  const [reportTypeFilter, setReportTypeFilter] = useState({})

  const [opsUsers, setOpsUsers] = useState({
    operation_team: [],
    investment_team: [],
    asset_management_team: [],
  })

  const [usersPerReport, setUsersPerReport] = useState(
    JSON.parse(JSON.stringify(REPORTS))
  )

  useEffect(() => {
    const permissions = permissionChecker()
    setAccessControlData(permissions)
  }, [])

  const filterUrl = `${
    investmentFilter?.id ? `&investmentId=${investmentFilter.id}` : ""
  }${departmentFilter ? `&department=${departmentFilter}` : ``}${
    reportTypeFilter?.id ? `&report_type=${reportTypeFilter.id}` : ""
  }`

  const getUrl = `/interest-approval-policy?$sort[created_at]=-1&$limit=${limit}&$skip=${skip}${filterUrl}`

  const masterData = async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(getUrl)
      if (response) {
        setData(response.data?.data || response.data)

        setTotal(response.data.total)
        let pages = Math.ceil(
          (response.data?.total || response.total) /
            (response.data?.limit || response.limit)
        )
        setTotalPage(pages)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }

  useEffect(() => {
    masterData()
  }, [limit, skip, investmentFilter, departmentFilter, reportTypeFilter])

  const getFilters = async () => {
    try {
      const investmentGetUrl = `investment?$sort[created_at]=-1`
      const invResponse = await axiosInstance.get(investmentGetUrl)
      const opsUsersRes = await axiosInstance.get(
        `/users?$sort[created_at]=-1&permissions=OPS_USER`
      )

      if (invResponse) {
        setInvestmentList(invResponse.data?.data || invResponse.data)
      }
      if (opsUsersRes) {
        const users = {
          operation_team: [],
          investment_team: [],
          asset_management_team: [],
        }

        for (const item of opsUsersRes.data.data) {
          const user = {
            ...item,
            label: `${item.user_name} (${item?.role?.role_name})`,
          }
          if (item?.role?.department == "Operations_Team") {
            users.operation_team.push(user)
          }
          if (item?.role?.department == "Investment_Team") {
            users.investment_team.push(user)
          }
          if (item?.role?.department == "Data_Management_Team") {
            users.asset_management_team.push(user)
          }
        }
        setOpsUsers(users)
      }
    } catch (error) {
      toast.error(error?.message)
    }
  }

  useEffect(() => {
    getFilters()
  }, [])

  const handleNext = prev => {
    setSkip(prev + limit)
    let page = (prev + limit) / limit + 1
    setCurrentPage(page)
  }

  const handlePrevious = prev => {
    setSkip(prev - limit)
    let page = (prev - limit) / limit + 1
    setCurrentPage(page)
  }

  const handleFilterChange = (e, key) => {
    switch (key) {
      case "projectType":
        setInvestmentFilter(e)
        break
      case "reportType":
        setReportTypeFilter(e)
        break
      case "department":
        setDepartmentFilter(e?.value)
        break
      case "limit":
        setLimit(Number(e.target.value))
        break
      default:
        break
    }
    setSkip(0)
    setCurrentPage(1)
  }

  const onChangePagination = e => {
    const { value } = e.target
    if (value < 1 || value > totalPage) {
      return
    }
    setCurrentPage(value)
    if (value) {
      setSkip((value - 1) * limit)
    }
  }

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM Y hh:mm a")
    return date1
  }

  const DataColumns = toggleModal => [
    {
      dataField: "investment.project_name",
      text: "Project Name",
      sort: true,
    },
    {
      dataField: "report_type",
      text: "Report Type",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (report_type, row) =>
        REPORTS.find(report => report.id == report_type)?.name,
    },
    {
      dataField: "user.user_name",
      text: "User Name",
      sort: true,
    },
    {
      dataField: "user.role.department",
      text: "Department",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (department, row) => department.replaceAll("_", " "),
    },
    {
      dataField: "user.role.role_name",
      text: "Role",
      sort: true,
    },

    {
      dataField: "user.email",
      text: "User Email",
      sort: true,
    },
    {
      dataField: "user.phone",
      text: "Phone Number",
      sort: true,
    },
    {
      dataField: "created_at",
      text: "Created On",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.created_at),
    },
  ]

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  const toggle = () => {
    setModal(!modal)
  }
  const handleAddUpdate = () => {
    setSelectedInvestment()
    setUsersPerReport(JSON.parse(JSON.stringify(REPORTS)))
    toggle()
  }

  const getPolicyByInvestmentId = async investmentId => {
    try {
      setLoading(true)
      const res = await axiosInstance.get(
        `/interest-approval-policy?investmentId=${investmentId}`
      )

      const reports_copy = JSON.parse(JSON.stringify(REPORTS))

      for (const report of reports_copy) {
        const policyPerReport = res.data.data.filter(
          policy => policy.report_type == report.id
        )
        for (const policy of policyPerReport) {
          const user = {
            ...policy.user,
            label: `${policy.user.user_name} (${policy.user?.role?.role_name})`,
          }

          if (user?.role?.department == "Operations_Team") {
            report.teams.operation_team.selectedUsers.push(user)
          }
          if (policy?.user?.role?.department == "Investment_Team") {
            report.teams?.investment_team?.selectedUsers?.push(user)
          }
          if (policy?.user?.role?.department == "Data_Management_Team") {
            report.teams.asset_management_team.selectedUsers.push(user)
          }
        }
      }
      setUsersPerReport(reports_copy)
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (selectedInvestment?.id) {
      getPolicyByInvestmentId(selectedInvestment?.id)
    }
  }, [selectedInvestment])

  const handleValidOrderSubmit = async (e, values) => {
    try {
      setLoading(true)
      if (!selectedInvestment?.id) {
        toast.error("Select a Project")
        return
      }

      const allUsers = []

      for (const report of usersPerReport) {
        for (const key of Object.keys(report.teams)) {
          if (report.teams[key].selectedUsers.length == 0) {
            toast.error("Select user from each team for each report")
            return
          }
          for (const user of report.teams[key].selectedUsers) {
            allUsers.push({
              investmentId: selectedInvestment?.id,
              userId: user.id,
              report_type: report.id,
            })
          }
        }
      }

      const payload = {
        investmentId: selectedInvestment?.id,
        payload: allUsers,
      }
      const res = await axiosInstance.post(`/interest-approval-policy`, payload)
      if (res) {
        await masterData()
        toast.success("Added Successfully")
        toggle()
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  const handleUserSelect = (users, key, report_id) => {
    setUsersPerReport(
      usersPerReport.map(report => {
        if (report.id == report_id) {
          report.teams[key].selectedUsers = users
        }
        return report
      })
    )
  }
  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        {accessControlData?.permissions?.INTEREST_APPROVAL_POLICY_VIEW ? (
          <Container fluid>
            <Breadcrumbs breadcrumbItem="Interest Approval" />
            <Row>
              <Col xs="12">
                <ToolkitProvider
                  keyField="id"
                  data={data}
                  columns={DataColumns()}
                  bootstrap4
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <Row className="mb-2 row justify-content-between">
                        <Col md={2}>
                          <select
                            className="form-select w-75"
                            value={limit}
                            onChange={e => handleFilterChange(e, "limit")}
                          >
                            {[10, 30, 50, 100].map(pageSize => (
                              <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                              </option>
                            ))}
                          </select>
                        </Col>

                        <div className="col-auto">
                          {accessControlData?.permissions
                            ?.INTEREST_APPROVAL_POLICY_ADD && (
                            <Button
                              type="button"
                              color="success"
                              className="btn-rounded  mb-2 me-2"
                              onClick={handleAddUpdate}
                              style={{ marginRight: "3px" }}
                            >
                              <i className="mdi mdi-plus me-1" />
                              Add/update
                            </Button>
                          )}
                        </div>
                      </Row>
                      <Card>
                        <CardBody>
                          <Row className="mb-2 row justify-content-start">
                            {/* <Col md={3}>
                              <div className="position-relative">
                                <label>Search User</label>
                                <input
                                  onChange={e => handleFilterChange(e, "user")}
                                  id="search-bar-0"
                                  type="text"
                                  className="form-control rounded custom-input-height"
                                  placeholder={`Name, Email, Phone, Pan Name or Pan`}
                                  value={userFilter || ""}
                                />
                              </div>
                            </Col> */}
                            <Col md={3}>
                              <div className="mb-3">
                                <label>Project Name</label>
                                <ReactSelect
                                  users={investmentList}
                                  setSelectedOption={e =>
                                    handleFilterChange(e, "projectType")
                                  }
                                  multiOptionLabel={true}
                                  optionLabelKeys={["project_name"]}
                                  isClearable={true}
                                />
                              </div>
                            </Col>
                            <Col md={3}>
                              <div className="mb-3">
                                <label>Report Type</label>
                                <ReactSelect
                                  users={REPORTS}
                                  setSelectedOption={e =>
                                    handleFilterChange(e, "reportType")
                                  }
                                  multiOptionLabel={true}
                                  optionLabelKeys={["name"]}
                                  isClearable={true}
                                />
                              </div>
                            </Col>
                            <Col md={3}>
                              <div className="mb-3">
                                <label>Department</label>
                                <ReactSelect
                                  users={departmentList}
                                  setSelectedOption={e =>
                                    handleFilterChange(e, "department")
                                  }
                                  multiOptionLabel={true}
                                  optionLabelKeys={["label"]}
                                  isClearable={true}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  keyField="id"
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  classes={
                                    "table align-middle table-nowrap table-check"
                                  }
                                  headerWrapperClasses={"table-light"}
                                  {...toolkitProps.baseProps}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row className="justify-content-md-space-between justify-content-center align-items-center">
                            <Col className="col-12 col-md-auto mb-3">
                              {`Showing ${total ? skip + 1 + " to" : ""} ${
                                limit > total || limit + skip > total
                                  ? total
                                  : limit + skip
                              } rows of ${total}`}
                            </Col>
                            <Col>
                              <Row className="justify-content-md-end justify-content-center align-items-center">
                                <Col className="col-md-auto">
                                  <div className="d-flex gap-1">
                                    <Button
                                      color="primary"
                                      onClick={() => handlePrevious(limit)}
                                      disabled={currentPage == 1}
                                    >
                                      {"<<"}
                                    </Button>
                                    <Button
                                      color="primary"
                                      onClick={() => handlePrevious(skip)}
                                      disabled={currentPage == 1}
                                    >
                                      {"<"}
                                    </Button>
                                  </div>
                                </Col>
                                <Col className="col-md-auto d-none d-md-block">
                                  Page{" "}
                                  <strong>{`${
                                    currentPage ? currentPage : 1
                                  } of ${totalPage ? totalPage : 1}`}</strong>
                                </Col>
                                <Col className="col-md-auto">
                                  <Input
                                    type="number"
                                    min={1}
                                    style={{ width: 70 }}
                                    max={total == 0 ? 1 : totalPage}
                                    value={currentPage || 1}
                                    defaultValue={1}
                                    onChange={onChangePagination}
                                    disabled={total == 0}
                                  />
                                </Col>

                                <Col className="col-md-auto">
                                  <div className="d-flex gap-1">
                                    <Button
                                      color="primary"
                                      onClick={() => handleNext(skip)}
                                      disabled={
                                        currentPage == totalPage || total == 0
                                      }
                                    >
                                      {">"}
                                    </Button>
                                    <Button
                                      color="primary"
                                      onClick={() =>
                                        handleNext((totalPage - 2) * limit)
                                      }
                                      disabled={
                                        currentPage == totalPage || total == 0
                                      }
                                    >
                                      {">>"}
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              </Col>
            </Row>
            <Modal isOpen={modal} toggle={toggle} size="lg">
              <ModalHeader toggle={toggle} tag="h4">
                Add/Update Policy
              </ModalHeader>
              <ModalBody>
                <AvForm onValidSubmit={handleValidOrderSubmit}>
                  <Row form>
                    <Col className="col-12">
                      <div className="mb-3">
                        <label>Project Name</label>
                        <ReactSelect
                          users={investmentList}
                          setSelectedOption={setSelectedInvestment}
                          selectedOption={selectedInvestment}
                          multiOptionLabel={true}
                          optionLabelKeys={["project_name"]}
                        />
                      </div>
                    </Col>
                    {usersPerReport.map((item, index) => {
                      return (
                        <div key={index} className="mb-3 border-bottom pb-2">
                          <b>{item.name}</b>
                          <Row className="p-2">
                            {Object.keys(item.teams).map(key => {
                              const team = item.teams[key]
                              return (
                                <Col
                                  key={key}
                                  // md={12 / Object.keys(item.teams).length}
                                  md={6}
                                >
                                  <div className="mb-3">
                                    <label>{team.label}</label>
                                    <ReactMultiSelect
                                      options={opsUsers[key]}
                                      multiOptionLabel={true}
                                      optionLabelKeys={["label"]}
                                      isDisabled={!selectedInvestment}
                                      selectedOption={team.selectedUsers}
                                      setSelectedOption={users =>
                                        handleUserSelect(users, key, item.id)
                                      }
                                    />
                                  </div>
                                </Col>
                              )
                            })}
                          </Row>
                        </div>
                      )
                    })}
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <button
                          type="submit"
                          className="btn btn-success save-user"
                        >
                          Save
                        </button>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </ModalBody>
            </Modal>
          </Container>
        ) : (
          <Unauthorized />
        )}
      </div>
    </React.Fragment>
  )
}

export default InterestApprovalPolicy
