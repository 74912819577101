import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { withRouter, Link } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Input,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import Loader from "common/Loader"
import { permissionChecker } from "common/PermissionChecker"
import Unauthorized from "common/Unauthorized"
import { AvField, AvForm } from "availity-reactstrap-validation"
import ReactSelect from "constants/ReactSelect"

const StfDetails = () => {
  const [loading, setLoading] = useState(false)
  const [tableData, setTableData] = useState([])
  const [limit, setLimit] = useState(10)
  const [skip, setSkip] = useState(0)
  const [total, setTotal] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [selectedDetails, setSelectedDetails] = useState({})
  const [showDetailsModal, setShowDetailsModal] = useState(false)
  const [debentureTrusteeList, setDebentureTrusteeList] = useState([])
  const [accessControlData, setAccessControlData] = useState([])
  const [selectedDebentureTrustee, setSelectedDebentureTrustee] = useState()

  useEffect(() => {
    const permissions = permissionChecker()
    setAccessControlData(permissions)
  }, [])

  const defaultSorted = [
    {
      dataField: "created_at",
      order: "desc",
    },
  ]
  const TableColumns = download => {
    const columns = [
      {
        dataField: "",
        isDummyField: true,
        text: "Actions",
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, order) => (
          <>
            {accessControlData?.permissions?.STF_DETAILS_EDIT ? (
              <Link
                to="#"
                className="text-success"
                onClick={() => toggleStfDetailsModal(order)}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  {accessControlData?.permissions?.STF_DETAILS_EDIT}
                </UncontrolledTooltip>
              </Link>
            ) : (
              "NA"
            )}
          </>
        ),
      },
      {
        dataField: "project_name",
        text: "Project Name",
        sort: true,
      },
      {
        dataField: "isin_number",
        text: "ISIN Number",
        sort: true,
      },
      {
        dataField: "issuer_cin",
        text: "Issuer CIN",
        sort: true,
      },
      {
        dataField: "issuer_code",
        text: "Issuer Code",
        sort: true,
      },
      {
        dataField: "isin_description",
        text: "ISIN Description",
        sort: true,
      },
      {
        dataField: "redemption_start_date",
        text: "Redemption Start Date",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => row.redemption_start_date,
      },
      {
        dataField: "debenture_trustee.debenture_trustee_name",
        text: "Debenture Trustee Name",
        sort: true,
      },
      {
        dataField: "debenture_trustee.debenture_trustee_cin",
        text: "Debenture CIN",
        sort: true,
      },
    ]
    return columns
  }

  const toggleStfDetailsModal = details => {
    setSelectedDetails(details)
    setSelectedDebentureTrustee({
      ...details?.debenture_trustee,
      id: details?.debenture_trustee?.debentureTrusteeId,
    })
    setShowDetailsModal(!showDetailsModal)
  }

  const getDebentureTrusteeDetails = async () => {
    const debentureTrusteeRes = await axiosInstance.get(`debenture-trustee`)
    const debentureTrusteeData =
      debentureTrusteeRes?.data?.data || debentureTrusteeRes?.data
    setDebentureTrusteeList(debentureTrusteeData)
  }

  useEffect(() => {
    if (showDetailsModal) {
      getDebentureTrusteeDetails()
    }
  }, [showDetailsModal])

  const masterData = async () => {
    setTableData([])
    setLoading(true)
    try {
      const response = await axiosInstance.get(
        `investment?$limit=1111111&$skip=${skip}&$sort[created_at]=-1&status=Approved`
      )
      if (response) {
        setTotal(response.data.total)
        const data = response?.data?.data || response?.data
        setTableData(data)
        let pages = Math.ceil(
          (response.data?.total || response.total) /
            (response.data?.limit || response.limit)
        )
        setTotalPage(pages)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }

  const updateStfDetailHandler = async e => {
    e.preventDefault()
    setLoading(true)
    const values = e.target.elements
    const updateOrder = {
      isin_number: values.isin_number.value,
      stf_disclaimer: values.stf_disclaimer.value,
      stf_risk_disclosures: values.stf_risk_disclosures.value,
      issuer_cin: values.issuer_cin.value,
      issuer_code: values.issuer_code.value,
      isin_description: values.isin_description.value,
      redemption_start_date: values.redemption_start_date.value,
      debentureTrusteeId: selectedDebentureTrustee?.id,
    }
    console.log(updateOrder)
    try {
      const response = await axiosInstance.patch(
        `investment/${selectedDetails.id}`,
        updateOrder
      )
      if (response) {
        setSelectedDetails(null)
        toggleStfDetailsModal()
        masterData()
        toast.success("Successfully Edited")
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  // SSP Filters
  const handleFilterChange = (e, key) => {
    switch (key) {
      case "limit":
        setLimit(Number(e.target.value))
        break
      default:
        break
    }
    setSkip(0)
    setCurrentPage(1)
  }

  // Pagination handler - Start
  useEffect(async () => {
    masterData()
  }, [limit, skip])

  const onChangePagination = e => {
    const { value } = e.target
    if (value < 1 || value > totalPage) {
      return
    }
    setCurrentPage(value)
    if (value) {
      setSkip((value - 1) * limit)
    }
  }
  const handleNext = prev => {
    setSkip(prev + limit)
    let page = (prev + limit) / limit + 1
    setCurrentPage(page)
  }
  const handlePrevious = prev => {
    setSkip(prev - limit)
    let page = (prev - limit) / limit + 1
    setCurrentPage(page)
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        {accessControlData?.permissions?.STF_DETAILS_VIEW ? (
          <Container fluid>
            <Breadcrumbs breadcrumbItem={accessControlData?.pageName} />
            <Row>
              <Col xs="12">
                <Row className="mb-2 row justify-content-between">
                  <Col md={2}>
                    <select
                      className="form-select w-75"
                      value={limit}
                      onChange={e => handleFilterChange(e, "limit")}
                    >
                      {[10, 30, 50, 100].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                          Show {pageSize}
                        </option>
                      ))}
                    </select>
                  </Col>
                </Row>
                <Card>
                  <CardBody>
                    <ToolkitProvider
                      keyField="id"
                      data={tableData}
                      columns={TableColumns()}
                      bootstrap4
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2 row">
                            <Col md={3}></Col>
                          </Row>
                          {/* Table and Modal start */}
                          <Row>
                            <Col xl="12">
                              {tableData.length ? (
                                <BootstrapTable
                                  keyField="id"
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  columns={TableColumns()}
                                  data={tableData}
                                  wrapperClasses={"table-responsive mb-4"}
                                  classes={
                                    "table align-middle table-nowrap table-check"
                                  }
                                  headerWrapperClasses={"table-light"}
                                  {...toolkitProps.baseProps}
                                />
                              ) : null}
                            </Col>
                          </Row>
                          {/* Table and Modal End */}
                          {/* SSP Pagination Start */}
                          <Row className="justify-content-md-space-between justify-content-center align-items-center">
                            <Col className="col-12 col-md-auto mb-3">
                              {`Showing ${total ? skip + 1 + " to" : ""} ${
                                limit > total || limit + skip > total
                                  ? total
                                  : limit + skip
                              } rows of ${total}
                 `}
                            </Col>
                            <Col>
                              <Row className="justify-content-md-end justify-content-center align-items-center">
                                <Col className="col-md-auto">
                                  <div className="d-flex gap-1">
                                    <Button
                                      color="primary"
                                      onClick={() => handlePrevious(limit)}
                                      disabled={currentPage == 1}
                                    >
                                      {"<<"}
                                    </Button>
                                    <Button
                                      color="primary"
                                      onClick={() => handlePrevious(skip)}
                                      disabled={currentPage == 1}
                                    >
                                      {"<"}
                                    </Button>
                                  </div>
                                </Col>
                                <Col className="col-md-auto d-none d-md-block">
                                  Page{" "}
                                  <strong>{`${
                                    currentPage ? currentPage : 1
                                  } of ${totalPage ? totalPage : 1}`}</strong>
                                </Col>
                                <Col className="col-md-auto">
                                  <Input
                                    type="number"
                                    min={1}
                                    style={{ width: 70 }}
                                    max={total == 0 ? 1 : totalPage}
                                    value={currentPage || 1}
                                    defaultValue={1}
                                    onChange={onChangePagination}
                                    disabled={total == 0}
                                  />
                                </Col>

                                <Col className="col-md-auto">
                                  <div className="d-flex gap-1">
                                    <Button
                                      color="primary"
                                      onClick={() => handleNext(skip)}
                                      disabled={
                                        currentPage == totalPage || total == 0
                                      }
                                    >
                                      {">"}
                                    </Button>
                                    <Button
                                      color="primary"
                                      onClick={() =>
                                        handleNext((totalPage - 2) * limit)
                                      }
                                      disabled={
                                        currentPage == totalPage || total == 0
                                      }
                                    >
                                      {">>"}
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          {/* SSP Pagination End */}
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Modal isOpen={showDetailsModal} toggle={toggleStfDetailsModal}>
              <ModalHeader toggle={toggleStfDetailsModal} tag="h4">
                Manage Stf Details{" "}
              </ModalHeader>
              <ModalBody>
                <AvForm onValidSubmit={updateStfDetailHandler}>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <AvField
                        name="issuer_cin"
                        label="Issuer CIN"
                        type="text"
                        errorMessage="Invalid Issuer CIN"
                        value={selectedDetails?.issuer_cin || ""}
                      ></AvField>
                    </div>
                    <div className="mb-3">
                      <AvField
                        name="issuer_code"
                        label="Issuer Code"
                        type="text"
                        errorMessage="Invalid Issuer Code"
                        value={selectedDetails?.issuer_code || ""}
                      ></AvField>
                    </div>
                    <div className="mb-3">
                      <AvField
                        name="isin_number"
                        label="ISIN Number"
                        type="text"
                        errorMessage="Invalid ISIN Number"
                        validate={{
                          required: { value: true },
                        }}
                        value={selectedDetails?.isin_number || ""}
                      ></AvField>
                    </div>
                    <div className="mb-3">
                      <AvField
                        name="isin_description"
                        label="ISIN Description"
                        type="text"
                        errorMessage="ISIN Description Required"
                        value={selectedDetails?.isin_description || ""}
                      ></AvField>
                    </div>
                    <div className="mb-3">
                      <label>Select Debenture Trustee</label>
                      <ReactSelect
                        users={debentureTrusteeList}
                        setSelectedOption={setSelectedDebentureTrustee}
                        multiOptionLabel={true}
                        optionLabelKeys={[
                          "debenture_trustee_name",
                          "debenture_trustee_cin",
                        ]}
                        selectedOption={selectedDebentureTrustee}
                      />
                    </div>
                    <div className="mb-3">
                      <AvField
                        name="redemption_start_date"
                        label="Redemption Start Date"
                        type="date"
                        errorMessage="Invalid redemption_start_date"
                        value={selectedDetails?.redemption_start_date || ""}
                      ></AvField>
                    </div>
                    <div className="mb-3">
                      <AvField
                        name="stf_disclaimer"
                        label="STF Disclaimer"
                        type="textarea"
                        errorMessage=" Invalid input"
                        value={selectedDetails?.stf_disclaimer || ""}
                      ></AvField>
                    </div>
                    <div className="mb-3">
                      <AvField
                        name="stf_risk_disclosures"
                        label="STF Risk Disclosure"
                        type="textarea"
                        errorMessage=" Invalid input"
                        value={selectedDetails?.stf_risk_disclosures || ""}
                      ></AvField>
                    </div>
                  </div>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <button
                          type="submit"
                          className="btn btn-success save-user"
                        >
                          Submit
                        </button>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </ModalBody>
            </Modal>
          </Container>
        ) : (
          <Unauthorized />
        )}
      </div>
    </React.Fragment>
  )
}

export default withRouter(StfDetails)
