import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import moment from "moment"

export const picUrl = process.env.REACT_APP_PIC_URL

export const validateEmail = function (email) {
  var re = /\S+@\S+\.\S+/
  return re.test(email)
}

export const usersData = async (kyc_status, searchName) => {
  try {
    const response = await axiosInstance.get(
      `/users?$sort[created_at]=-1&$or[0][permissions]=USER&$or[1][permissions]=OPS_USER${
        kyc_status ? "&kycStatus=" + kyc_status : ""
      }${searchName ? `&name=${searchName}&$limit=50&$skip=0` : ""}`
    )
    if (response) return response
  } catch (error) {}
}

export const distributorUsers = async () => {
  try {
    const response = await axiosInstance.get(
      `/users?$sort[created_at]=-1&permissions=OPS_USER`
    )
    if (response) return response
  } catch (error) {}
}

export const distributorUsersData = async () => {
  const localUser = JSON.parse(localStorage.getItem("userInfo"))
  try {
    const response = await axiosInstance.get(
      `/users?$sort[created_at]=-1&$or[0][permissions]=USER&$or[0][permissions]=OPS_USER&createdById=${localUser.id}`
    )
    if (response) return response
  } catch (error) {}
}
export const distributorUserInvData = async searchName => {
  const localUser = JSON.parse(localStorage.getItem("userInfo"))
  const isDist = localUser?.role?.department === "Distributor"
  try {
    const response = await axiosInstance.get(
      `/users?$sort[created_at]=-1&$or[0][permissions]=USER&$or[0][permissions]=OPS_USER${
        (isDist ? "&createdById=" : "&relManagerId=") + localUser.id
      }&kycStatus=VERIFIED${
        searchName ? `&name=${searchName}&$limit=50&$skip=0` : ""
      }`
    )
    if (response) return response
  } catch (error) {}
}

export const getActiveInvestmentLot = async investmentId => {
  try {
    const invLotRes = await axiosInstance.get(
      `investment-lot?investmentId=${investmentId}&status=true`
    )
    if (invLotRes) {
      const invLotData = invLotRes?.data?.data || invLotRes?.data
      return invLotData[0]
    }
    return null
  } catch (error) {}
}

export const getInvestmentLotByProject = async investmentId => {
  try {
    const invLotRes = await axiosInstance.get(
      `investment-lot?$sort[created_at]=-1&investmentId=${investmentId}`
    )
    if (invLotRes) {
      const invLotData = invLotRes?.data?.data || invLotRes?.data
      return invLotData
    }
    return []
  } catch (error) {}
}

export const investmentData = async (statusOne, statusTwo, statusThree) => {
  try {
    const response = await axiosInstance.get(
      `/investment?$sort[created_at]=-1${
        statusOne ? `&$or[0][status]=${statusOne}` : ""
      }${statusTwo ? `&$or[0][status]=${statusTwo}` : ""}${
        statusThree ? `&$or[0][status]=${statusThree}` : ""
      }`
    )
    if (response) return response
  } catch (error) {}
}

export const distributorInvData = async () => {
  try {
    const response = await axiosInstance.get(
      `/investment?$sort[created_at]=-1&status=Approved`
    )
    if (response) return response
  } catch (error) {
    toast.error("Not able fetch Deals. Please try again after sometime")
  }
}

export const isInvestmentTrancheOpen = obj => {
  const currentDate = new Date()
  const openDate = new Date(obj.investment_tranche?.open_date)
  const closeDate = new Date(obj.investment_tranche?.close_date)
  openDate.setHours(0, 0, 0, 0)
  closeDate.setHours(23, 59, 59, 59)
  if (
    currentDate > openDate &&
    currentDate < closeDate &&
    obj.investment_tranche?.distributor_open_status
  ) {
    return true
  }
  return false
}

// Email Template
export const kycEmailTemplate = [
  { templateName: "KYC Verified", id: 16 },
  { templateName: "KYC Verification in process (In progress)", id: 15 },
  {
    templateName: "KYC Verification in process - clarification (Resubmit)",
    id: 17,
  },
]
export const invoiceEmailTemplate = [
  { templateName: "Upfront Fee Invoice Request Sent", id: 550 },
  { templateName: "Upfront Fee Invoice Resubmit", id: 555 },
  {
    templateName: "Upfront Fee Invoice Paid",
    id: 556,
  },
]
export const userInvestmentEmailTemplate = [
  { templateName: "Investment - Manual Payment", id: 257 },
  // { templateName: "Investment Commitment", id: 51 },
  { templateName: "Investment Payment Completed", id: 62 },
  { templateName: "Token Payment Completed", id: 57 },
  { templateName: "Token - Manual Payment", id: 56 },
  // { templateName: "User Commitment", id: 53 },
]

export const repaymentEmailTemplate = [
  { templateName: "Interest Payment", id: 312 },
  { templateName: "Principal Prepayment", id: 313 },
  { templateName: "Principal Repayment", id: 314 },
  { id: 563, templateName: "ABFL Payout One time" },
  { id: 539, templateName: "Consolidated Payout Communications" },
]
export const whatsappTemplate = [
  { templateName: "Individual Payout (ABFL)", id: 7159881 },
  { templateName: "Family Payout (ABFL)", id: 7159884 },
  { templateName: "Individual Payout", id: 7157406 },
  { templateName: "Family Payout", id: 7157410 },
]

export const distributorRepaymentEmailTemplate = [
  { templateName: "Distributor Repayment Communication", id: 400 },
]
export const allEmailTemplate = [
  ...kycEmailTemplate,
  ...userInvestmentEmailTemplate,
  ...repaymentEmailTemplate,
  ...distributorRepaymentEmailTemplate,
  ...invoiceEmailTemplate,
]
export const getEmailTemplate = templateId => {
  const template = allEmailTemplate.find(el => el.id == templateId)
  return template
}
export const getWhatsappTemplate = templateId => {
  const template = whatsappTemplate.find(el => el.id == templateId)
  return template
}

export function getSearchOptionLabel({ option, optionLabelKeys }) {
  if (option) {
    if (optionLabelKeys?.length) {
      var str = ""
      for (let i = 0; i < optionLabelKeys.length; i++) {
        str =
          str +
          (option[optionLabelKeys[i]] ? option[optionLabelKeys[i]] + ", " : "")
      }
      return str.slice(0, -2)
    }
  }
}

export function getOptionLabel({
  option,
  transaction = false,
  investment = false,
  roles = false,
  keys = [],
}) {
  if (option) {
    if (keys.length) {
      var str = ""
      for (let i = 0; i < keys.length; i++) {
        str = str + eval("option?." + keys[i]) + ", "
      }
      return str.slice(0, -2)
    }

    const data = `${option.user_name ? option.user_name : ""}${
      option.email ? `, ${option.email}` : ""
    }${option.phone ? `, ${option.phone}` : ""}`

    const transactionData = `${option.project_name ? option.project_name : ""}${
      option.user_name ? `, ${option.user_name}` : ""
    }${option.email ? `, ${option.email}` : ""}${
      option.phone ? `, ${option.phone}` : ""
    }`

    const investmentData = `${option.project_name ? option.project_name : ""}${
      option.location ? `, ${option.location}` : ""
    }`

    const rolesData = `${option.role_name}  (${humanize(option.department)})`

    return transaction
      ? transactionData
      : investment
      ? investmentData
      : roles
      ? rolesData
      : data
  }
}
export function humanize(str) {
  var i,
    frags = str?.split("_")
  for (i = 0; i < frags?.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)
  }
  return frags?.join(" ")
}

// KYC Status
export const statusEnum = str => {
  switch (str) {
    case "NOT_SUBMIT":
      return "NOT SUBMITTED"
      break
    case "RE_SUBMIT":
      return "RE-SUBMIT"
      break
    case "VERIFIED":
      return "VERIFIED"
      break
    case "IN_VERIFICATION":
      return "IN-VERIFICATION"
      break
    default:
      return str
      break
  }
}

export const flattenObject = obj => {
  let flat = {}
  for (const [key, value] of Object.entries(obj)) {
    if (typeof value === "object" && value !== null) {
      for (const [subkey, subvalue] of Object.entries(value)) {
        // avoid overwriting duplicate keys: merge instead into array
        typeof flat[subkey] === "undefined"
          ? (flat[subkey] = subvalue)
          : Array.isArray(flat[subkey])
          ? flat[subkey].push(subvalue)
          : (flat[subkey] = [flat[subkey], subvalue])
      }
    } else {
      flat = { ...flat, ...{ [key]: value } }
    }
  }
  return flat
}

export function csvDownloadData(csvTableHeaders, arr) {
  const downloadArray = []
  if (arr.length) {
    arr.forEach(element => {
      const row = {}
      csvTableHeaders.forEach(header => {
        const keyArray = header.dataField ? header.dataField.split(".") : []
        const key = header.text.split(" ").join("_")
        if (keyArray.length == 2) {
          if (element[keyArray[0]]) {
            if (Array.isArray(element[keyArray[0]])) {
              const value = element[keyArray[0]][0][keyArray[1]]
              row[key] = isDate(value) ? handleValidDate(value) : value
            } else {
              const value = element[keyArray[0]][keyArray[1]]
              row[key] = isDate(value) ? handleValidDate(value) : value
            }
          } else {
            row[key] = getFalsyValue(element[keyArray[0]], header?.isNumber)
          }
        } else if (keyArray.length == 3) {
          if (element[keyArray[0]]) {
            if (Array.isArray(element[keyArray[0]])) {
              const value = element[keyArray[0]][0][keyArray[1]][keyArray[2]]
              row[key] = isDate(value) ? handleValidDate(value) : value
            } else {
              const value = element[keyArray[0]][keyArray[1]][keyArray[2]]
              row[key] = isDate(value) ? handleValidDate(value) : value
            }
          } else {
            row[key] = getFalsyValue(element[keyArray[0]], header?.isNumber)
          }
        } else if (element[header.dataField]) {
          const value = element[header.dataField]
          row[key] = isDate(value) ? handleValidDate(value) : value
        } else {
          row[key] = getFalsyValue(element[header.dataField], header?.isNumber)
        }
      })
      downloadArray.push(row)
    })
  }
  return downloadArray
}

export const isDate = value => {
  return (
    typeof value === "string" && moment(value, moment.ISO_8601, true).isValid()
  )
}

export const handleValidDate = date => {
  const date1 = moment(date).format("DD MMM YYYY")
  return date1
}

export const getTimestamp = date => {
  return date + " 12:00:00+05:30"
}

export const getFalsyValue = (falsyValue, isNumber) => {
  switch (falsyValue) {
    case false:
      return false
    case 0:
      return 0
    default:
      if (isNumber) return 0
      return "-"
  }
}

export const Pagination = ({ limit, skip, handlePrevious, handleNext }) => {
  return (
    <div style={{ float: "right", fontSize: "15px" }}>
      {limit?.total > 0
        ? `Showing ${skip + 1} to ${
            limit?.total >= 10 ? skip + limit?.limit : limit?.total
          } of
                  ${limit?.total}`
        : `No Data Available`}
      <button
        type="button"
        className="btn btn-secondary"
        onClick={handlePrevious}
        style={{ margin: "10px" }}
        disabled={skip == 0 ? true : false}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-chevron-left"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
          />
        </svg>
      </button>
      <button
        type="button"
        className="btn btn-secondary"
        onClick={handleNext}
        disabled={limit?.total > skip + 10 ? false : true}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-chevron-right"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
          />
        </svg>
      </button>
    </div>
  )
}

Pagination.propTypes = {
  limit: PropTypes.object,
  skip: PropTypes.number,
  handleNext: PropTypes.func,
  handlePrevious: PropTypes.func,
}

export const AccessDenied = "NA"

export const getChartColorsArray = colors => {
  colors = JSON.parse(colors)
  return colors.map(function (value) {
    var newValue = value.replace(" ", "")
    if (newValue.indexOf(",") === -1) {
      var color = getComputedStyle(document.documentElement).getPropertyValue(
        newValue
      )

      if (color.indexOf("#") !== -1) color = color.replace(" ", "")
      if (color) return color
      else return newValue
    } else {
      var val = value.split(",")
      if (val.length === 2) {
        var rgbaColor = getComputedStyle(
          document.documentElement
        ).getPropertyValue(val[0])
        rgbaColor = "rgba(" + rgbaColor + "," + val[1] + ")"
        return rgbaColor
      } else {
        return newValue
      }
    }
  })
}

export const enumSelector = str => {
  switch (str) {
    case "Tds":
      return "TDS"
    case "Balance_Investment":
      return "Principal Investment"
    case "Interest_Payment":
      return "Interest Payment"
    case "Principal_Prepayment":
      return "Principal Prepayment"
    case "Principal_Repayment":
      return "Principal Repayment"
    case "Token_Refund":
      return "Token Refund"
    case "Direct":
      return "Principal Investment"
    case "Token":
      return "Token"
    case "Bank_Transfer":
      return "Bank Transfer"
    case "Online":
      return "Online"
    case "Cash":
      return "Cash"
    case "Cheque":
      return "Cheque"
    case "RTGS":
      return "RTGS"
    case "IMPS":
      return "IMPS"
    case "NEFT":
      return "NEFT"
    case "UPI":
      return "UPI"
    case "Token_Refund":
      return "Token Refund"
    case "Initiated":
      return "Initiated"
    case "Initiate_Balance_Payment":
      return "Initiate Balance Payment"
    case "Awaiting_Manual_Transfer":
      return "Awaiting Manual Transfer"
    case "Partially_Paid":
      return "Partially Paid"
    case "Declined":
      return "Declined"
    case "Completed":
      return "Completed"
    default:
      return ""
  }
}

export const getAuthorizedSignatory = (user, execution_date) => {
  switch (user?.user_pan?.pan_type) {
    case "Person":
      if (moment(execution_date).diff(user?.dob, "year") < 18) {
        return "Guardian"
      }
      return "N/A"
    case "Firm/ Limited Liability Partnership":
      return "Partner"
    case "Company":
      return "Director"
    case "Sole Proprietor":
      return "Sole Proprietor"
    case "Partnership Firm":
      return "Partnership Firm"
    case "Hindu Undivided Family (HUF)":
      return "Karta"
    case "Trust":
      return "Managing Trustee"
    default:
      return user?.user_pan?.pan_type
  }
}

export const getImgUrl = (picUrl, img) => {
  return picUrl && img
    ? img.indexOf("google") == -1
      ? picUrl + img
      : img
    : null
}

export const clientCategory = [
  {
    id: 1,
    code: 0,
    name: "OTHERS",
  },
  {
    id: 2,
    code: 1,
    name: "MUTUAL FUNDS",
  },
  {
    id: 3,
    code: 2,
    name: "INSURANCE COMPANY",
  },
  {
    id: 4,
    code: 3,
    name: "BANKS",
  },
  {
    id: 5,
    code: 4,
    name: "NBFC",
  },
  {
    id: 6,
    code: 5,
    name: "BROKER",
  },
  {
    id: 7,
    code: 6,
    name: "CORPORATE",
  },
  {
    id: 8,
    code: 7,
    name: "FII",
  },
  {
    id: 9,
    code: 8,
    name: "FII-SUB",
  },
  {
    id: 10,
    code: 9,
    name: "VC",
  },
  {
    id: 11,
    code: 10,
    name: "FVCI",
  },
  {
    id: 12,
    code: 11,
    name: "PMS",
  },
  {
    id: 13,
    code: 12,
    name: "DFI",
  },
  {
    id: 14,
    code: 13,
    name: "TRUST",
  },
  {
    id: 15,
    code: 14,
    name: "PD",
  },
  {
    id: 16,
    code: 21,
    name: "CUSTODY",
  },
  {
    id: 17,
    code: 24,
    name: "INDIVIDUAL",
  },
  {
    id: 18,
    code: 25,
    name: "Pension Fund",
  },
  {
    id: 19,
    code: 26,
    name: "Provident Fund",
  },
  {
    id: 20,
    code: 27,
    name: "OBP Broker",
  },
  {
    id: 21,
    code: 28,
    name: "AIF",
  },
]

export const createFullAdress = (
  address_line_1,
  address_line_2,
  zip_code,
  city,
  state,
  country
) => {
  let fullAdress = ""
  if (address_line_1) {
    fullAdress += address_line_1 + ", "
  }
  if (address_line_2) {
    fullAdress += address_line_2 + ", "
  }
  if (city) {
    fullAdress += city + ", "
  }
  if (state) {
    fullAdress += state + ", "
  }
  if (state && country) {
    fullAdress += country + ", "
  }
  if (zip_code) {
    fullAdress += zip_code
  }
  return fullAdress
}

export const indianStates = [
  { id: 1, name: "Andaman and Nicobar Islands" },
  { id: 2, name: "Andhra Pradesh" },
  { id: 3, name: "Arunachal Pradesh" },
  { id: 4, name: "Assam" },
  { id: 5, name: "Bihar" },
  { id: 6, name: "Chandigarh" },
  { id: 7, name: "Chhattisgarh" },
  { id: 8, name: "Dadra and Nagar Haveli" },
  { id: 9, name: "Daman and Diu" },
  { id: 10, name: "Delhi" },
  { id: 11, name: "Goa" },
  { id: 12, name: "Gujarat" },
  { id: 13, name: "Haryana" },
  { id: 14, name: "Himachal Pradesh" },
  { id: 15, name: "Jammu and Kashmir" },
  { id: 16, name: "Jharkhand" },
  { id: 17, name: "Karnataka" },
  { id: 18, name: "Kerala" },
  { id: 19, name: "Ladakh" },
  { id: 20, name: "Lakshadweep" },
  { id: 21, name: "Madhya Pradesh" },
  { id: 22, name: "Maharashtra" },
  { id: 23, name: "Manipur" },
  { id: 24, name: "Meghalaya" },
  { id: 25, name: "Mizoram" },
  { id: 26, name: "Nagaland" },
  { id: 27, name: "Odisha" },
  { id: 28, name: "Puducherry" },
  { id: 29, name: "Punjab" },
  { id: 30, name: "Rajasthan" },
  { id: 31, name: "Sikkim" },
  { id: 32, name: "Tamil Nadu" },
  { id: 33, name: "Telangana" },
  { id: 34, name: "Tripura" },
  { id: 35, name: "Uttar Pradesh" },
  { id: 36, name: "Uttarakhand" },
  { id: 37, name: "West Bengal" },
]

export const countryList = [
  {
    id: 1,
    name: "India",
  },
]

export const getBillingCycle = (cycle = "MONTHLY") => {
  const thresholdDate = { month: 10, year: 2024 }
  const monthsData = []
  const today = new Date()

  // Determine if we are in the first or second half of the current month
  const isFirstHalf = today.getDate() <= 15

  for (let i = 0; i < 6; i++) {
    const monthDate = new Date(today.getFullYear(), today.getMonth() - i, 1)

    const year = monthDate.getFullYear()
    const month = monthDate.toLocaleString("default", { month: "long" })

    if (
      thresholdDate.month > monthDate.getMonth() + 1 &&
      thresholdDate.year == year
    ) {
      continue
    }

    if (cycle === "SEMI_MONTHLY") {
      // Only include first half if we are in the second half of the current month
      if (!isFirstHalf && i === 0) {
        // Current month (second half)
        const firstHalfStartDate = new Date(year, monthDate.getMonth(), 1)
        const firstHalfEndDate = new Date(year, monthDate.getMonth(), 15)

        monthsData.push({
          id: 1,
          name: `${moment(firstHalfStartDate).format("DD MMM Y")} to ${moment(
            firstHalfEndDate
          ).format("DD MMM Y")}`,
          start_date: moment(firstHalfStartDate).format("YYYY-MM-DD"),
          end_date: moment(firstHalfEndDate).format("YYYY-MM-DD"),
        })
      } else if (i > 0) {
        // Previous months
        const firstHalfStartDate = new Date(year, monthDate.getMonth(), 1)
        const firstHalfEndDate = new Date(year, monthDate.getMonth(), 15)

        const secondHalfStartDate = new Date(year, monthDate.getMonth(), 16)
        const secondHalfEndDate = new Date(year, monthDate.getMonth() + 1, 0)

        monthsData.push({
          id: i * 2 + 2,
          name: `${moment(secondHalfStartDate).format("DD MMM Y")} to ${moment(
            secondHalfEndDate
          ).format("DD MMM Y")}`,
          start_date: moment(secondHalfStartDate).format("YYYY-MM-DD"),
          end_date: moment(secondHalfEndDate).format("YYYY-MM-DD"),
        })
        monthsData.push({
          id: i * 2 + 1,
          name: `${moment(firstHalfStartDate).format("DD MMM Y")} to ${moment(
            firstHalfEndDate
          ).format("DD MMM Y")}`,
          start_date: moment(firstHalfStartDate).format("YYYY-MM-DD"),
          end_date: moment(firstHalfEndDate).format("YYYY-MM-DD"),
        })
      }
    } else {
      // For MONTHLY cycle
      if (i > 0) {
        // Exclude the current month
        const startDate = new Date(year, monthDate.getMonth(), 1)
        const endDate = new Date(year, monthDate.getMonth() + 1, 0)

        monthsData.push({
          id: i,
          name: `${month} ${year}`,
          start_date: moment(startDate).format("YYYY-MM-DD"),
          end_date: moment(endDate).format("YYYY-MM-DD"),
        })
      }
    }
  }

  return monthsData
}

export const generateFinancialYearList = (startYear = 2021) => {
  const today = new Date()
  const currentYear = today.getFullYear()
  const currentMonth = today.getMonth() + 1

  const fiscalYear = []
  const endYear = currentMonth >= 4 ? currentYear : currentYear - 1
  for (let year = startYear; year <= endYear; year++) {
    fiscalYear.push({
      id: `${year}-${year + 1}`,
      startDate: `${year}-04-01`,
      endDate: `${year + 1}-03-31`,
    })
  }

  return fiscalYear
}

export const departmentList = [
  { id: 1, value: "Customer", label: "Customer" },
  { id: 2, value: "Super_Admin", label: "Super Admin" },
  { id: 3, value: "Admin", label: "Admin" },
  { id: 4, value: "Investment_Team", label: "Investment Team" },
  { id: 5, value: "Data_Management_Team", label: "Data Management Team" },
  { id: 6, value: "Sales_Team", label: "Sales Team" },
  { id: 7, value: "Operations_Team", label: "Operations Team" },
  { id: 8, value: "Investor", label: "Investor" },
  { id: 9, value: "Investor_Rep", label: "Investor Rep" },
  { id: 10, value: "Tech_Team", label: "Tech Team" },
  { id: 11, value: "Relationship_Manager", label: "Relationship Manager" },
  { id: 12, value: "Distributor", label: "Distributor" },
]

export const maskEmail = email => {
  const localUser = JSON.parse(localStorage.getItem("userInfo"))
  if (localUser.permissions === "Admin") return email
  if (!email) return ""

  const [name, domain] = email.split("@")
  const maskedName = name[0] + "***" + name.slice(-1)

  const domainParts = domain.split(".")
  const mainDomain = domainParts[0]
  const domainExtension = domainParts.slice(1).join(".")
  const maskedDomain = mainDomain[0] + "***" + mainDomain.slice(-1)

  return `${maskedName}@${maskedDomain}.${domainExtension}`
}

export const maskPhoneNumber = phone => {
  const localUser = JSON.parse(localStorage.getItem("userInfo"))
  if (localUser.permissions === "Admin") return phone
  if (!phone) return ""
  if (phone.length <= 6) {
    return phone
  }
  const start = phone.slice(0, 4)
  const end = phone.slice(-2)
  const middle = "*".repeat(phone.length - 6)
  return `${start}${middle}${end}`
}
