export const SidebarOptions = [
  {
    id: "DASHBOARD",
    key: "DASHBOARD",
    displayName: "Dashboard",
    expandable: true,
    pages: [
      {
        id: "PLATFORM_DASHBOARD",
        key: "PLATFORM_DASHBOARD",
        displayName: "Platform Dashboard",
        path: "/platform-dashboard",
        sectionId: "DASHBOARD",
        actions: [
          {
            id: "PLATFORM_DASHBOARD_VIEW",
            key: "PLATFORM_DASHBOARD_VIEW",
            displayName: "View",
          },
          {
            id: "PLATFORM_DASHBOARD_DOWNLOAD",
            key: "PLATFORM_DASHBOARD_DOWNLOAD",
            displayName: "Download",
          },
        ],
      },
      {
        id: "DEAL_DASHBOARD",
        key: "DEAL_DASHBOARD",
        displayName: "Deal Dashboard",
        path: "/dashboard",
        sectionId: "DASHBOARD",
        actions: [
          {
            id: "DEAL_DASHBOARD_VIEW",
            key: "DEAL_DASHBOARD_VIEW",
            displayName: "View",
          },
          {
            id: "DEAL_DASHBOARD_DOWNLOAD",
            key: "DEAL_DASHBOARD_DOWNLOAD",
            displayName: "Download",
          },
        ],
      },
    ],
  },
  {
    id: "DEAL_CREATION",
    key: "DEAL_CREATION",
    displayName: "Deal Creation",
    expandable: true,
    pages: [
      {
        id: "REQUESTS",
        key: "REQUESTS",
        displayName: "Requests",
        path: "/investment-creation-request",
        sectionId: "DEAL_CREATION",
        actions: [
          {
            id: "REQUESTS_VIEW",
            key: "REQUESTS_VIEW",
            displayName: "View",
          },
          {
            id: "REQUESTS_ADD",
            key: "REQUESTS_ADD",
            displayName: "Add",
          },
          {
            id: "REQUESTS_EDIT",
            key: "REQUESTS_EDIT",
            displayName: "Edit",
          },
          {
            id: "REQUESTS_DELETE",
            key: "REQUESTS_DELETE",
            displayName: "Delete",
          },
        ],
      },
      {
        id: "APPROVALS",
        key: "APPROVALS",
        displayName: "Approvals",
        path: "/creation-approval-queue",
        sectionId: "DEAL_CREATION",
        actions: [
          {
            id: "APPROVALS_VIEW",
            key: "APPROVALS_VIEW",
            displayName: "View",
          },
          {
            id: "APPROVALS_UPDATE",
            key: "APPROVALS_UPDATE",
            displayName: "Update",
          },
        ],
      },
    ],
  },
  {
    id: "DEAL_MANAGEMENT",
    key: "DEAL_MANAGEMENT",
    displayName: "Deal Management",
    expandable: true,
    pages: [
      {
        id: "DEAL_MANAGEMENT_INVESTMENTS",
        key: "DEAL_MANAGEMENT_INVESTMENTS",
        displayName: "Investments",
        path: "/investment",
        sectionId: "DEAL_MANAGEMENT",
        actions: [
          {
            id: "INVESTMENTS_VIEW",
            key: "INVESTMENTS_VIEW",
            displayName: "View",
          },
          {
            id: "INVESTMENTS_ADD",
            key: "INVESTMENTS_ADD",
            displayName: "Add",
          },
          {
            id: "INVESTMENTS_SEND_FOR_APPROVAL",
            key: "INVESTMENTS_SEND_FOR_APPROVAL",
            displayName: "Send For Approval",
          },
          {
            id: "INVESTMENTS_UPDATE_STATUS",
            key: "INVESTMENTS_UPDATE_STATUS",
            displayName: "Update Status",
          },
          {
            id: "INVESTMENTS_EDIT",
            key: "INVESTMENTS_EDIT",
            displayName: "Edit",
          },
          {
            id: "INVESTMENTS_VIEW_DRAFT",
            key: "INVESTMENTS_VIEW_DRAFT",
            displayName: "View Draft",
          },
        ],
      },
      {
        id: "SECTION_HEADERS",
        key: "SECTION_HEADERS",
        displayName: "Section Headers",
        path: "/investment-section-header",
        sectionId: "DEAL_MANAGEMENT",
        actions: [
          {
            id: "SECTION_HEADERS_VIEW",
            key: "SECTION_HEADERS_VIEW",
            displayName: "View",
          },
          {
            id: "SECTION_HEADERS_MANAGE_SECTION_HEADERS",
            key: "SECTION_HEADERS_MANAGE_SECTION_HEADERS",
            displayName: "Manage Section Headers",
          },
        ],
      },
      {
        id: "INVESTMENT_ATTRIBUTES",
        key: "INVESTMENT_ATTRIBUTES",
        displayName: "Investment Attributes",
        path: "/investment-attribute",
        sectionId: "DEAL_MANAGEMENT",
        actions: [
          {
            id: "INVESTMENT_ATTRIBUTES_VIEW",
            key: "INVESTMENT_ATTRIBUTES_VIEW",
            displayName: "View",
          },
          {
            id: "INVESTMENT_ATTRIBUTES_ADD",
            key: "INVESTMENT_ATTRIBUTES_ADD",
            displayName: "Add",
          },
          {
            id: "INVESTMENT_ATTRIBUTES_EDIT",
            key: "INVESTMENT_ATTRIBUTES_EDIT",
            displayName: "Edit",
          },
          {
            id: "INVESTMENT_ATTRIBUTES_DELETE",
            key: "INVESTMENT_ATTRIBUTES_DELETE",
            displayName: "Delete",
          },
        ],
      },
      {
        id: "WHY_INVEST",
        key: "WHY_INVEST",
        displayName: "Why Invest",
        path: "/why-invest",
        sectionId: "DEAL_MANAGEMENT",
        actions: [
          {
            id: "WHY_INVEST_VIEW",
            key: "WHY_INVEST_VIEW",
            displayName: "View",
          },
          {
            id: "WHY_INVEST_ADD",
            key: "WHY_INVEST_ADD",
            displayName: "Add",
          },
          {
            id: "WHY_INVEST_EDIT",
            key: "WHY_INVEST_EDIT",
            displayName: "Edit",
          },
          {
            id: "WHY_INVEST_DELETE",
            key: "WHY_INVEST_DELETE",
            displayName: "Delete",
          },
        ],
      },
      {
        id: "UNDERLYING_ASSET",
        key: "UNDERLYING_ASSET",
        displayName: "Underlying Asset",
        path: "/underlying-asset",
        sectionId: "DEAL_MANAGEMENT",
        actions: [
          {
            id: "UNDERLYING_ASSET_VIEW",
            key: "UNDERLYING_ASSET_VIEW",
            displayName: "View",
          },
          {
            id: "UNDERLYING_ASSET_ADD",
            key: "UNDERLYING_ASSET_ADD",
            displayName: "Add",
          },
          {
            id: "UNDERLYING_ASSET_EDIT",
            key: "UNDERLYING_ASSET_EDIT",
            displayName: "Edit",
          },
          {
            id: "UNDERLYING_ASSET_DELETE",
            key: "UNDERLYING_ASSET_DELETE",
            displayName: "Delete",
          },
        ],
      },
      {
        id: "ASSET_LOCATION",
        key: "ASSET_LOCATION",
        displayName: "Asset Location",
        path: "/underlying-asset-location-data",
        sectionId: "DEAL_MANAGEMENT",
        actions: [
          {
            id: "ASSET_LOCATION_VIEW",
            key: "ASSET_LOCATION_VIEW",
            displayName: "View",
          },
          {
            id: "ASSET_LOCATION_ADD",
            key: "ASSET_LOCATION_ADD",
            displayName: "Add",
          },
          {
            id: "ASSET_LOCATION_EDIT",
            key: "ASSET_LOCATION_EDIT",
            displayName: "Edit",
          },
          {
            id: "ASSET_LOCATION_DELETE",
            key: "ASSET_LOCATION_DELETE",
            displayName: "Delete",
          },
        ],
      },
      {
        id: "ASSET_MEDIA",
        key: "ASSET_MEDIA",
        displayName: "Asset Media",
        path: "/underlying-asset-media",
        sectionId: "DEAL_MANAGEMENT",
        actions: [
          {
            id: "ASSET_MEDIA_VIEW",
            key: "ASSET_MEDIA_VIEW",
            displayName: "View",
          },
          {
            id: "ASSET_MEDIA_ADD",
            key: "ASSET_MEDIA_ADD",
            displayName: "Add",
          },
          {
            id: "ASSET_MEDIA_EDIT",
            key: "ASSET_MEDIA_EDIT",
            displayName: "Edit",
          },
          {
            id: "ASSET_MEDIA_DELETE",
            key: "ASSET_MEDIA_DELETE",
            displayName: "Delete",
          },
        ],
      },
      {
        id: "SPONSOR_MASTER",
        key: "SPONSOR_MASTER",
        displayName: "Sponsor Master",
        path: "/sponsor-master",
        sectionId: "DEAL_MANAGEMENT",
        actions: [
          {
            id: "SPONSOR_MASTER_VIEW",
            key: "SPONSOR_MASTER_VIEW",
            displayName: "View",
          },
          {
            id: "SPONSOR_MASTER_ADD",
            key: "SPONSOR_MASTER_ADD",
            displayName: "Add",
          },
          {
            id: "SPONSOR_MASTER_EDIT",
            key: "SPONSOR_MASTER_EDIT",
            displayName: "Edit",
          },
          {
            id: "SPONSOR_MASTER_DELETE",
            key: "SPONSOR_MASTER_DELETE",
            displayName: "Delete",
          },
        ],
      },
      {
        id: "SPONSOR_OTHER_PROJECTS",
        key: "SPONSOR_OTHER_PROJECTS",
        displayName: "Sponsor Other Projects",
        path: "/sponsor-other-projects",
        sectionId: "DEAL_MANAGEMENT",
        actions: [
          {
            id: "SPONSOR_OTHER_PROJECTS_VIEW",
            key: "SPONSOR_OTHER_PROJECTS_VIEW",
            displayName: "View",
          },
          {
            id: "SPONSOR_OTHER_PROJECTS_ADD",
            key: "SPONSOR_OTHER_PROJECTS_ADD",
            displayName: "Add",
          },
          {
            id: "SPONSOR_OTHER_PROJECTS_EDIT",
            key: "SPONSOR_OTHER_PROJECTS_EDIT",
            displayName: "Edit",
          },
          {
            id: "SPONSOR_OTHER_PROJECTS_DELETE",
            key: "SPONSOR_OTHER_PROJECTS_DELETE",
            displayName: "Delete",
          },
        ],
      },
      {
        id: "SPONSOR_PROMOTER",
        key: "SPONSOR_PROMOTER",
        displayName: "Sponsor Promoter",
        path: "/sponsor-promoter",
        sectionId: "DEAL_MANAGEMENT",
        actions: [
          {
            id: "SPONSOR_PROMOTER_VIEW",
            key: "SPONSOR_PROMOTER_VIEW",
            displayName: "View",
          },
          {
            id: "SPONSOR_PROMOTER_ADD",
            key: "SPONSOR_PROMOTER_ADD",
            displayName: "Add",
          },
          {
            id: "SPONSOR_PROMOTER_EDIT",
            key: "SPONSOR_PROMOTER_EDIT",
            displayName: "Edit",
          },
          {
            id: "SPONSOR_PROMOTER_DELETE",
            key: "SPONSOR_PROMOTER_DELETE",
            displayName: "Delete",
          },
        ],
      },
      {
        id: "PERFORMANCE",
        key: "PERFORMANCE",
        displayName: "Performance",
        path: "/investment-perf-history",
        sectionId: "DEAL_MANAGEMENT",
        actions: [
          {
            id: "PERFORMANCE_VIEW",
            key: "PERFORMANCE_VIEW",
            displayName: "View",
          },
          {
            id: "PERFORMANCE_ADD",
            key: "PERFORMANCE_ADD",
            displayName: "Add",
          },
          {
            id: "PERFORMANCE_EDIT",
            key: "PERFORMANCE_EDIT",
            displayName: "Edit",
          },
          {
            id: "PERFORMANCE_DELETE",
            key: "PERFORMANCE_DELETE",
            displayName: "Delete",
          },
        ],
      },
      {
        id: "INVESTMENT_RISKS",
        key: "INVESTMENT_RISKS",
        displayName: "Investment Risks",
        path: "/investment-risk",
        sectionId: "DEAL_MANAGEMENT",
        actions: [
          {
            id: "INVESTMENT_RISKS_VIEW",
            key: "INVESTMENT_RISKS_VIEW",
            displayName: "View",
          },
          {
            id: "INVESTMENT_RISKS_ADD",
            key: "INVESTMENT_RISKS_ADD",
            displayName: "Add",
          },
          {
            id: "INVESTMENT_RISKS_EDIT",
            key: "INVESTMENT_RISKS_EDIT",
            displayName: "Edit",
          },
          {
            id: "INVESTMENT_RISKS_DELETE",
            key: "INVESTMENT_RISKS_DELETE",
            displayName: "Delete",
          },
        ],
      },
      {
        id: "INVESTMENT_FAQS",
        key: "INVESTMENT_FAQS",
        displayName: "Investment FAQs",
        path: "/investment-faq",
        sectionId: "DEAL_MANAGEMENT",
        actions: [
          {
            id: "INVESTMENT_FAQS_VIEW",
            key: "INVESTMENT_FAQS_VIEW",
            displayName: "View",
          },
          {
            id: "INVESTMENT_FAQS_ADD",
            key: "INVESTMENT_FAQS_ADD",
            displayName: "Add",
          },
          {
            id: "INVESTMENT_FAQS_EDIT",
            key: "INVESTMENT_FAQS_EDIT",
            displayName: "Edit",
          },
          {
            id: "INVESTMENT_FAQS_DELETE",
            key: "INVESTMENT_FAQS_DELETE",
            displayName: "Delete",
          },
        ],
      },
      {
        id: "INVESTMENT_RESOURCES",
        key: "INVESTMENT_RESOURCES",
        displayName: "Investment Resources",
        path: "/investment-resource",
        sectionId: "DEAL_MANAGEMENT",
        actions: [
          {
            id: "INVESTMENT_RESOURCES_VIEW",
            key: "INVESTMENT_RESOURCES_VIEW",
            displayName: "View",
          },
          {
            id: "INVESTMENT_RESOURCES_ADD",
            key: "INVESTMENT_RESOURCES_ADD",
            displayName: "Add",
          },
          {
            id: "INVESTMENT_RESOURCES_EDIT",
            key: "INVESTMENT_RESOURCES_EDIT",
            displayName: "Edit",
          },
          {
            id: "INVESTMENT_RESOURCES_DELETE",
            key: "INVESTMENT_RESOURCES_DELETE",
            displayName: "Delete",
          },
        ],
      },
    ],
  },
  {
    id: "DEAL_PUBLISHING",
    key: "DEAL_PUBLISHING",
    displayName: "Deal Publishing",
    expandable: true,
    pages: [
      {
        id: "DEAL_PUBLISHING_APPROVALS",
        key: "DEAL_PUBLISHING_APPROVALS",
        displayName: "Approvals",
        path: "/publishing-approval-queue",
        sectionId: "DEAL_PUBLISHING",
        actions: [
          {
            id: "DEAL_PUBLISHING_APPROVALS_VIEW",
            key: "DEAL_PUBLISHING_APPROVALS_VIEW",
            displayName: "View",
          },
          {
            id: "DEAL_PUBLISHING_APPROVALS_UPDATE",
            key: "DEAL_PUBLISHING_APPROVALS_UPDATE",
            displayName: "Update",
          },
        ],
      },
    ],
  },
  {
    id: "KYC_MANAGEMENT",
    key: "KYC_MANAGEMENT",
    displayName: "KYC Management",
    expandable: true,
    pages: [
      {
        id: "KYC_LIST",
        key: "KYC_LIST",
        displayName: "KYC List",
        path: "/kyc-list",
        sectionId: "KYC_MANAGEMENT",
        actions: [
          {
            id: "KYC_LIST_VIEW",
            key: "KYC_LIST_VIEW",
            displayName: "View",
          },
          {
            id: "KYC_LIST_ADD_UPDATE",
            key: "KYC_LIST_ADD_UPDATE",
            displayName: "Add/Update",
          },
          {
            id: "KYC_LIST_UPDATE",
            key: "KYC_LIST_UPDATE",
            displayName: "Update",
          },
          // {
          //   id: "KYC_LIST_SEND_EMAIL",
          //   key: "KYC_LIST_SEND_EMAIL",
          //   displayName: "Send Email",
          // },
          {
            id: "KYC_LIST_DOWNLOAD",
            key: "KYC_LIST_DOWNLOAD",
            displayName: "Download",
          },
        ],
      },
      {
        id: "UNIQUE_CLIENT_CODE",
        key: "UNIQUE_CLIENT_CODE",
        displayName: "Unique Client Code",
        path: "/unique-client-code",
        sectionId: "KYC_MANAGEMENT",
        actions: [
          {
            id: "UNIQUE_CLIENT_CODE_VIEW",
            key: "UNIQUE_CLIENT_CODE_VIEW",
            displayName: "View",
          },
          {
            id: "UNIQUE_CLIENT_CODE_ADD",
            key: "UNIQUE_CLIENT_CODE_ADD",
            displayName: "Add",
          },
          {
            id: "UNIQUE_CLIENT_CODE_EDIT",
            key: "UNIQUE_CLIENT_CODE_EDIT",
            displayName: "Edit",
          },
          {
            id: "UNIQUE_CLIENT_CODE_DOWNLOAD",
            key: "UNIQUE_CLIENT_CODE_DOWNLOAD",
            displayName: "Download",
          },
        ],
      },
      {
        id: "DEMAT_BENEFICIARY_DOCUMENTS",
        key: "DEMAT_BENEFICIARY_DOCUMENTS",
        displayName: "Demat Beneficiary Documents",
        path: "/demat-beneficiary-documents",
        sectionId: "KYC_MANAGEMENT",
        actions: [
          {
            id: "DEMAT_BENEFICIARY_DOCUMENTS_VIEW",
            key: "DEMAT_BENEFICIARY_DOCUMENTS_VIEW",
            displayName: "View",
          },
          {
            id: "DEMAT_BENEFICIARY_DOCUMENTS_GENERATE",
            key: "DEMAT_BENEFICIARY_DOCUMENTS_GENERATE",
            displayName: "Generate",
          },
          {
            id: "DEMAT_BENEFICIARY_DOCUMENTS_EDIT",
            key: "DEMAT_BENEFICIARY_DOCUMENTS_EDIT",
            displayName: "Edit",
          },
          {
            id: "DEMAT_BENEFICIARY_DOCUMENTS_DELETE",
            key: "DEMAT_BENEFICIARY_DOCUMENTS_DELETE",
            displayName: "Delete",
          },
          {
            id: "DEMAT_BENEFICIARY_DOCUMENTS_ADD",
            key: "DEMAT_BENEFICIARY_DOCUMENTS_ADD",
            displayName: "Add",
          },
          {
            id: "DEMAT_BENEFICIARY_DOCUMENTS_DOWNLOAD",
            key: "DEMAT_BENEFICIARY_DOCUMENTS_DOWNLOAD",
            displayName: "Download",
          },
        ],
      },
      {
        id: "DEMAT_BENEFICIARY_REPORT",
        key: "DEMAT_BENEFICIARY_REPORT",
        displayName: "Demat Beneficiary Report",
        path: "/demat-beneficiary-report",
        sectionId: "KYC_MANAGEMENT",
        actions: [
          {
            id: "DEMAT_BENEFICIARY_REPORT_VIEW",
            key: "DEMAT_BENEFICIARY_REPORT_VIEW",
            displayName: "View",
          },
          {
            id: "DEMAT_BENEFICIARY_REPORT_DOWNLOAD",
            key: "DEMAT_BENEFICIARY_REPORT_DOWNLOAD",
            displayName: "Download",
          },
        ],
      },
    ],
  },
  {
    id: "INVESTMENTS",
    key: "INVESTMENTS",
    displayName: "Investments",
    expandable: true,
    pages: [
      {
        id: "USERS_INVESTMENT",
        key: "USERS_INVESTMENT",
        displayName: "Users Investment",
        path: "/user-investment",
        sectionId: "INVESTMENTS",
        actions: [
          {
            id: "USERS_INVESTMENT_VIEW",
            key: "USERS_INVESTMENT_VIEW",
            displayName: "View",
          },
          {
            id: "USERS_INVESTMENT_ADD",
            key: "USERS_INVESTMENT_ADD",
            displayName: "Add",
          },
          {
            id: "USERS_INVESTMENT_EDIT",
            key: "USERS_INVESTMENT_EDIT",
            displayName: "Edit",
          },
          {
            id: "USERS_INVESTMENT_SEND_EMAIL",
            key: "USERS_INVESTMENT_SEND_EMAIL",
            displayName: "Send Email",
          },
          {
            id: "USERS_INVESTMENT_DOWNLOAD_EXCEL",
            key: "USERS_INVESTMENT_DOWNLOAD_EXCEL",
            displayName: "Download Excel",
          },
        ],
      },
      {
        id: "TRANSACTIONS",
        key: "TRANSACTIONS",
        displayName: "Transactions",
        path: "/transactions",
        sectionId: "INVESTMENTS",
        actions: [
          {
            id: "TRANSACTIONS_VIEW",
            key: "TRANSACTIONS_VIEW",
            displayName: "View",
          },
          {
            id: "TRANSACTIONS_ADD",
            key: "TRANSACTIONS_ADD",
            displayName: "Add",
          },
          {
            id: "TRANSACTIONS_UPLOAD",
            key: "TRANSACTIONS_UPLOAD",
            displayName: "Upload",
          },
          {
            id: "TRANSACTIONS_SAMPLE_CSV",
            key: "TRANSACTIONS_SAMPLE_CSV",
            displayName: "Sample CSV",
          },
          {
            id: "TRANSACTIONS_DOWNLOAD_EXCEL",
            key: "TRANSACTIONS_DOWNLOAD_EXCEL",
            displayName: "Download Excel",
          },
          {
            id: "TRANSACTIONS_UPDATE",
            key: "TRANSACTIONS_UPDATE",
            displayName: "Update",
          },
        ],
      },
      {
        id: "TCS_TRANSACTIONS",
        key: "TCS_TRANSACTIONS",
        displayName: "TCS Transactions",
        path: "/tcs-transactions",
        sectionId: "INVESTMENTS",
        actions: [
          {
            id: "TCS_TRANSACTIONS_VIEW",
            key: "TCS_TRANSACTIONS_VIEW",
            displayName: "View",
          },
          {
            id: "TCS_TRANSACTIONS_ADD",
            key: "TCS_TRANSACTIONS_ADD",
            displayName: "Add",
          },
          {
            id: "TCS_TRANSACTIONS_DOWNLOAD",
            key: "TCS_TRANSACTIONS_DOWNLOAD",
            displayName: "Download",
          },
          {
            id: "TCS_TRANSACTIONS_UPDATE",
            key: "TCS_TRANSACTIONS_UPDATE",
            displayName: "Update",
          },
        ],
      },
      {
        id: "TOPUP_USERS_INVESTMENT",
        key: "TOPUP_USERS_INVESTMENT",
        displayName: "Top-Up Users Investment",
        path: "/user-investment-topup",
        sectionId: "INVESTMENTS",
        actions: [
          {
            id: "TOPUP_USERS_INVESTMENT_VIEW",
            key: "TOPUP_USERS_INVESTMENT_VIEW",
            displayName: "View",
          },
          {
            id: "TOPUP_USERS_INVESTMENT_ADD",
            key: "TOPUP_USERS_INVESTMENT_ADD",
            displayName: "Add",
          },
          {
            id: "TOPUP_USERS_INVESTMENT_SEND_EMAIL",
            key: "TOPUP_USERS_INVESTMENT_SEND_EMAIL",
            displayName: "Send Email",
          },
          {
            id: "TOPUP_USERS_INVESTMENT_MANAGE_DISTRIBUTOR",
            key: "TOPUP_USERS_INVESTMENT_MANAGE_DISTRIBUTOR",
            displayName: "Manage Distributor",
          },
          {
            id: "TOPUP_USERS_INVESTMENT_UPDATE_ADDENDUM",
            key: "TOPUP_USERS_INVESTMENT_UPDATE_ADDENDUM",
            displayName: "Update Addendum",
          },
          {
            id: "TOPUP_USERS_INVESTMENT_SET_TOPUP_STATUS",
            key: "TOPUP_USERS_INVESTMENT_SET_TOPUP_STATUS",
            displayName: "Set Topup Status",
          },
          // {
          //   id: "TOPUP_USERS_INVESTMENT_GENERATE_PDF",
          //   key: "TOPUP_USERS_INVESTMENT_GENERATE_PDF",
          //   displayName: "Generate PDF",
          // },
          {
            id: "TOPUP_USERS_INVESTMENT_DOWNLOAD_EXCEL",
            key: "TOPUP_USERS_INVESTMENT_DOWNLOAD_EXCEL",
            displayName: "Download Excel",
          },
          {
            id: "TOPUP_USERS_INVESTMENT_EDIT",
            key: "TOPUP_USERS_INVESTMENT_EDIT",
            displayName: "Edit",
          },
        ],
      },
      {
        id: "TOPUP_TRANSACTIONS",
        key: "TOPUP_TRANSACTIONS",
        displayName: "Top-Up Transactions",
        path: "/transactions-topup",
        sectionId: "INVESTMENTS",
        actions: [
          {
            id: "TOPUP_TRANSACTIONS_VIEW",
            key: "TOPUP_TRANSACTIONS_VIEW",
            displayName: "View",
          },
          {
            id: "TOPUP_TRANSACTIONS_ADD",
            key: "TOPUP_TRANSACTIONS_ADD",
            displayName: "Add",
          },
          {
            id: "TOPUP_TRANSACTIONS_UPLOAD",
            key: "TOPUP_TRANSACTIONS_UPLOAD",
            displayName: "Upload",
          },
          {
            id: "TOPUP_TRANSACTIONS_DOWNLOAD_EXCEL",
            key: "TOPUP_TRANSACTIONS_DOWNLOAD_EXCEL",
            displayName: "Download Excel",
          },
          {
            id: "TOPUP_TRANSACTIONS_UPDATE",
            key: "TOPUP_TRANSACTIONS_UPDATE",
            displayName: "Update",
          },
        ],
      },
      {
        id: "TOPUP_TCS_TRANSACTIONS",
        key: "TOPUP_TCS_TRANSACTIONS",
        displayName: "Top-Up TCS Transactions",
        path: "/top-up-tcs-transactions",
        sectionId: "INVESTMENTS",
        actions: [
          {
            id: "TOPUP_TCS_TRANSACTIONS_VIEW",
            key: "TOPUP_TCS_TRANSACTIONS_VIEW",
            displayName: "View",
          },
          {
            id: "TOPUP_TCS_TRANSACTIONS_ADD",
            key: "TOPUP_TCS_TRANSACTIONS_ADD",
            displayName: "Add",
          },
          {
            id: "TOPUP_TCS_TRANSACTIONS_DOWNLOAD",
            key: "TOPUP_TCS_TRANSACTIONS_DOWNLOAD",
            displayName: "Download",
          },
          {
            id: "TOPUP_TCS_TRANSACTIONS_UPDATE",
            key: "TOPUP_TCS_TRANSACTIONS_UPDATE",
            displayName: "Update",
          },
        ],
      },
      {
        id: "WEBHOOK_DUMP",
        key: "WEBHOOK_DUMP",
        displayName: "Webhook Dump",
        path: "/transaction-dump",
        sectionId: "INVESTMENTS",
        actions: [
          {
            id: "WEBHOOK_DUMP_VIEW",
            key: "WEBHOOK_DUMP_VIEW",
            displayName: "View",
          },
          {
            id: "WEBHOOK_DUMP_MAP_TRANSACTION",
            key: "WEBHOOK_DUMP_MAP_TRANSACTION",
            displayName: "Map Transaction",
          },
          {
            id: "WEBHOOK_DUMP_DOWNLOAD",
            key: "WEBHOOK_DUMP_DOWNLOAD",
            displayName: "Download",
          },
        ],
      },
      {
        id: "STF_DETAILS",
        key: "STF_DETAILS",
        displayName: "STF Details",
        path: "/stf-details",
        sectionId: "INVESTMENTS",
        actions: [
          {
            id: "STF_DETAILS_VIEW",
            key: "STF_DETAILS_VIEW",
            displayName: "View",
          },
          {
            id: "STF_DETAILS_EDIT",
            key: "STF_DETAILS_EDIT",
            displayName: "Edit",
          },
        ],
      },
      {
        id: "STF_DOCUMENTS",
        key: "STF_DOCUMENTS",
        displayName: "STF Documents",
        path: "/stf-documents",
        sectionId: "INVESTMENTS",
        actions: [
          {
            id: "STF_DOCUMENTS_VIEW",
            key: "STF_DOCUMENTS_VIEW",
            displayName: "View",
          },
          {
            id: "STF_DOCUMENTS_EDIT",
            key: "STF_DOCUMENTS_EDIT",
            displayName: "Edit",
          },
          {
            id: "STF_DOCUMENTS_GENERATE_STF",
            key: "STF_DOCUMENTS_GENERATE_STF",
            displayName: "Generate STF",
          },
          {
            id: "STF_DOCUMENTS_UPLOAD_STF_DOCUMENT",
            key: "STF_DOCUMENTS_UPLOAD_STF_DOCUMENT",
            displayName: "Upload STF Document",
          },
          {
            id: "STF_DOCUMENTS_UPLOAD",
            key: "STF_DOCUMENTS_UPLOAD",
            displayName: "Upload",
          },
          {
            id: "STF_DOCUMENTS_BULK_SIGN",
            key: "STF_DOCUMENTS_BULK_SIGN",
            displayName: "Bulk Sign",
          },
          {
            id: "STF_DOCUMENTS_DOWNLOAD",
            key: "STF_DOCUMENTS_DOWNLOAD",
            displayName: "Download",
          },
          {
            id: "STF_DOCUMENTS_UPDATE",
            key: "STF_DOCUMENTS_UPDATE",
            displayName: "Update",
          },
          {
            id: "STF_DOCUMENTS_DELETE",
            key: "STF_DOCUMENTS_DELETE",
            displayName: "Delete",
          },
          {
            id: "STF_DOCUMENTS_SIGN",
            key: "STF_DOCUMENTS_SIGN",
            displayName: "Sign,Cancel,Decline",
          },
          {
            id: "STF_DOCUMENTS_INVESTOR_SIGN_LINK",
            key: "STF_DOCUMENTS_INVESTOR_SIGN_LINK",
            displayName: "Investor Sign Link",
          },
          {
            id: "STF_DOCUMENTS_TRANSFEROR_SIGN_LINK",
            key: "STF_DOCUMENTS_TRANSFEROR_SIGN_LINK",
            displayName: "Transferor Sign Link",
          },
        ],
      },
      {
        id: "DEMAT_TRANSFER_REQUESTS",
        key: "DEMAT_TRANSFER_REQUESTS",
        displayName: "Demat Transfer Requests",
        path: "/demat-transfer-requests",
        sectionId: "INVESTMENTS",
        actions: [
          {
            id: "DEMAT_TRANSFER_REQUESTS_VIEW",
            key: "DEMAT_TRANSFER_REQUESTS_VIEW",
            displayName: "View",
          },
          {
            id: "DEMAT_TRANSFER_REQUESTS_CREATE_REQUEST",
            key: "DEMAT_TRANSFER_REQUESTS_CREATE_REQUEST",
            displayName: "Create Request",
          },
          {
            id: "DEMAT_TRANSFER_REQUESTS_RE_INITIATE",
            key: "DEMAT_TRANSFER_REQUESTS_RE_INITIATE",
            displayName: "Re-Initiate Request",
          },
          {
            id: "DEMAT_TRANSFER_REQUESTS_MARK_FAILED",
            key: "DEMAT_TRANSFER_REQUESTS_MARK_FAILED",
            displayName: "Mark as Failed",
          },
          {
            id: "DEMAT_TRANSFER_REQUESTS_DOWNLOAD",
            key: "DEMAT_TRANSFER_REQUESTS_DOWNLOAD",
            displayName: "Download",
          },
        ],
      },
      {
        id: "DEMAT_TRANSFERS",
        key: "DEMAT_TRANSFERS",
        displayName: "Demat Transfers",
        path: "/demat-transfers",
        sectionId: "INVESTMENTS",
        actions: [
          {
            id: "DEMAT_TRANSFERS_VIEW",
            key: "DEMAT_TRANSFERS_VIEW",
            displayName: "View",
          },
          {
            id: "DEMAT_TRANSFERS_ADD",
            key: "DEMAT_TRANSFERS_ADD",
            displayName: "Add",
          },
          {
            id: "DEMAT_TRANSFERS_UPLOAD_EXCEL",
            key: "DEMAT_TRANSFERS_UPLOAD_EXCEL",
            displayName: "Upload Excel",
          },
          {
            id: "DEMAT_TRANSFERS_DOWNLOAD",
            key: "DEMAT_TRANSFERS_DOWNLOAD",
            displayName: "Download",
          },
          {
            id: "DEMAT_TRANSFERS_MAP",
            key: "DEMAT_TRANSFERS_MAP",
            displayName: "Map",
          },
        ],
      },
    ],
  },
  {
    id: "PORTFOLIO_MANAGEMENT",
    key: "PORTFOLIO_MANAGEMENT",
    displayName: "Portfolio Management",
    expandable: true,
    pages: [
      {
        id: "DOCUMENT_TYPE",
        key: "DOCUMENT_TYPE",
        displayName: "Document Type",
        path: "/portfolio-document-type",
        sectionId: "PORTFOLIO_MANAGEMENT",
        actions: [
          {
            id: "DOCUMENT_TYPE_VIEW",
            key: "DOCUMENT_TYPE_VIEW",
            displayName: "View",
          },
          {
            id: "DOCUMENT_TYPE_ADD",
            key: "DOCUMENT_TYPE_ADD",
            displayName: "Add",
          },
          {
            id: "DOCUMENT_TYPE_EDIT",
            key: "DOCUMENT_TYPE_EDIT",
            displayName: "Edit",
          },
          {
            id: "DOCUMENT_TYPE_DELETE",
            key: "DOCUMENT_TYPE_DELETE",
            displayName: "Delete",
          },
        ],
      },
      {
        id: "PORTFOLIO_DOCUMENTS",
        key: "PORTFOLIO_DOCUMENTS",
        displayName: "Portfolio Documents",
        path: "/portfolio-document",
        sectionId: "PORTFOLIO_MANAGEMENT",
        actions: [
          {
            id: "PORTFOLIO_DOCUMENTS_VIEW",
            key: "PORTFOLIO_DOCUMENTS_VIEW",
            displayName: "View",
          },
          {
            id: "PORTFOLIO_DOCUMENTS_ADD",
            key: "PORTFOLIO_DOCUMENTS_ADD",
            displayName: "Add",
          },
          {
            id: "PORTFOLIO_DOCUMENTS_EDIT",
            key: "PORTFOLIO_DOCUMENTS_EDIT",
            displayName: "Edit",
          },
          {
            id: "PORTFOLIO_DOCUMENTS_DELETE",
            key: "PORTFOLIO_DOCUMENTS_DELETE",
            displayName: "Delete",
          },
        ],
      },
      {
        id: "INVESTMENT_INFO",
        key: "INVESTMENT_INFO",
        displayName: "Investment Info",
        path: "/investment-asset-management",
        sectionId: "PORTFOLIO_MANAGEMENT",
        actions: [
          {
            id: "INVESTMENT_INFO_VIEW",
            key: "INVESTMENT_INFO_VIEW",
            displayName: "View",
          },
          {
            id: "INVESTMENT_INFO_ADD",
            key: "INVESTMENT_INFO_ADD",
            displayName: "Add",
          },
          {
            id: "INVESTMENT_INFO_EDIT",
            key: "INVESTMENT_INFO_EDIT",
            displayName: "Edit",
          },
          {
            id: "INVESTMENT_INFO_DELETE",
            key: "INVESTMENT_INFO_DELETE",
            displayName: "Delete",
          },
        ],
      },
      {
        id: "PROGRESS_MEDIA",
        key: "PROGRESS_MEDIA",
        displayName: "Progress Media",
        path: "/investment-progress-media",
        sectionId: "PORTFOLIO_MANAGEMENT",
        actions: [
          {
            id: "PROGRESS_MEDIA_VIEW",
            key: "PROGRESS_MEDIA_VIEW",
            displayName: "View",
          },
          {
            id: "PROGRESS_MEDIA_ADD",
            key: "PROGRESS_MEDIA_ADD",
            displayName: "Add",
          },
          {
            id: "PROGRESS_MEDIA_EDIT",
            key: "PROGRESS_MEDIA_EDIT",
            displayName: "Edit",
          },
          {
            id: "PROGRESS_MEDIA_DELETE",
            key: "PROGRESS_MEDIA_DELETE",
            displayName: "Delete",
          },
        ],
      },
      // {
      //   id: "INVESTMENT_PROJECTIONS",
      //   key: "INVESTMENT_PROJECTIONS",
      //   displayName: "Investment Projections",
      //   path: "/investment-projection",
      //   sectionId: "PORTFOLIO_MANAGEMENT",
      //   actions: [
      //     {
      //       id: "INVESTMENT_PROJECTIONS_VIEW",
      //       key: "INVESTMENT_PROJECTIONS_VIEW",
      //       displayName: "View",
      //     },
      //     {
      //       id: "INVESTMENT_PROJECTIONS_ADD",
      //       key: "INVESTMENT_PROJECTIONS_ADD",
      //       displayName: "Add",
      //     },
      //     {
      //       id: "INVESTMENT_PROJECTIONS_EDIT",
      //       key: "INVESTMENT_PROJECTIONS_EDIT",
      //       displayName: "Edit",
      //     },
      //     {
      //       id: "INVESTMENT_PROJECTIONS_DELETE",
      //       key: "INVESTMENT_PROJECTIONS_DELETE",
      //       displayName: "Delete",
      //     },
      //     {
      //       id: "INVESTMENT_PROJECTIONS_CSV_DOWNLOAD",
      //       key: "INVESTMENT_PROJECTIONS_CSV_DOWNLOAD",
      //       displayName: "Click Here",
      //     },
      //     {
      //       id: "INVESTMENT_PROJECTIONS_SAMPLE_CSV",
      //       key: "INVESTMENT_PROJECTIONS_SAMPLE_CSV",
      //       displayName: "Sample CSV",
      //     },
      //   ],
      // },
      {
        id: "BENPOS",
        key: "BENPOS",
        displayName: "BenPos",
        path: "/benpos-master",
        sectionId: "PORTFOLIO_MANAGEMENT",
        actions: [
          {
            id: "BENPOS_VIEW",
            key: "BENPOS_VIEW",
            displayName: "View",
          },
          {
            id: "BENPOS_ADD_UPDATE",
            key: "BENPOS_ADD_UPDATE",
            displayName: "Add/Update",
          },
          {
            id: "BENPOS_DOWNLOAD",
            key: "BENPOS_DOWNLOAD",
            displayName: "Download",
          },
          {
            id: "BENPOS_SAMPLE_CSV",
            key: "BENPOS_SAMPLE_CSV",
            displayName: "Sample CSV",
          },
          {
            id: "BENPOS_INTEREST_REPAYMENT_REPORTS",
            key: "BENPOS_INTEREST_REPAYMENT_REPORTS",
            displayName: "Interest and Repayment Reports",
          },
          {
            id: "BENPOS_VALIDATION_REPORT",
            key: "BENPOS_VALIDATION_REPORT",
            displayName: "Validation Report",
          },
          {
            id: "BENPOS_INTEREST_CALCULATION",
            key: "BENPOS_INTEREST_CALCULATION",
            displayName: "Interest Calculation",
          },
          {
            id: "BENPOS_EXCEPTION_REPORT",
            key: "BENPOS_EXCEPTION_REPORT",
            displayName: "Exception Report",
          },
          {
            id: "BENPOS_BOND_RECONCILIATION",
            key: "BENPOS_BOND_RECONCILIATION",
            displayName: "Bond Reconciliation",
          },
          {
            id: "BENPOS_TRANCHE_ALLOCATION",
            key: "BENPOS_TRANCHE_ALLOCATION",
            displayName: "Tranche Allocation & TDS Status",
          },
        ],
      },
      {
        id: "REPAYMENTS",
        key: "REPAYMENTS",
        displayName: "Repayments",
        path: "/repayment",
        sectionId: "PORTFOLIO_MANAGEMENT",
        actions: [
          {
            id: "REPAYMENTS_VIEW",
            key: "REPAYMENTS_VIEW",
            displayName: "View",
          },
          {
            id: "REPAYMENTS_ADD",
            key: "REPAYMENTS_ADD",
            displayName: "Add",
          },
          {
            id: "REPAYMENTS_UPLOAD_BULK_TRANSACTIONS",
            key: "REPAYMENTS_UPLOAD_BULK_TRANSACTIONS",
            displayName: "Upload Bulk Transactions",
          },
          {
            id: "REPAYMENTS_UPLOAD",
            key: "REPAYMENTS_UPLOAD",
            displayName: "Upload",
          },
          {
            id: "REPAYMENTS_SEND_PAYOUT_INFO",
            key: "REPAYMENTS_SEND_PAYOUT_INFO",
            displayName: "Send Payout Info",
          },
          {
            id: "REPAYMENTS_DISTRIBUTOR_EMAILS",
            key: "REPAYMENTS_DISTRIBUTOR_EMAILS",
            displayName: "Distributor Payout info",
          },
          {
            id: "REPAYMENTS_PREVIEW_PAYOUT_DATA",
            key: "REPAYMENTS_PREVIEW_PAYOUT_DATA",
            displayName: "Preview Payout Data",
          },
          {
            id: "REPAYMENTS_REVIEW_PAYOUT_DATA",
            key: "REPAYMENTS_REVIEW_PAYOUT_DATA",
            displayName: "Review Payout Data",
          },
          {
            id: "REPAYMENTS_DOWNLOAD",
            key: "REPAYMENTS_DOWNLOAD",
            displayName: "Download",
          },
          {
            id: "REPAYMENTS_EDIT",
            key: "REPAYMENTS_EDIT",
            displayName: "Edit",
          },
          {
            id: "REPAYMENTS_DELETE",
            key: "REPAYMENTS_DELETE",
            displayName: "Delete",
          },
          {
            id: "REPAYMENTS_SAMPLE_CSV",
            key: "REPAYMENTS_SAMPLE_CSV",
            displayName: "Sample CSV",
          },
        ],
      },
      {
        id: "IRR_REPORT",
        key: "IRR_REPORT",
        displayName: "IRR Report",
        path: "/irr-report",
        sectionId: "PORTFOLIO_MANAGEMENT",
        actions: [
          {
            id: "IRR_REPORT_VIEW",
            key: "IRR_REPORT_VIEW",
            displayName: "View",
          },
          {
            id: "IRR_REPORT_DOWNLOAD",
            key: "IRR_REPORT_DOWNLOAD",
            displayName: "Download",
          },
        ],
      },
      // {
      //   id: "INVESTMENT_CASH_FLOWS",
      //   key: "INVESTMENT_CASH_FLOWS",
      //   displayName: "Investment Cash Flows",
      //   path: "/investment-cash-flows",
      //   sectionId: "PORTFOLIO_MANAGEMENT",
      //   actions: [
      //     {
      //       id: "INVESTMENT_CASH_FLOWS_VIEW",
      //       key: "INVESTMENT_CASH_FLOWS_VIEW",
      //       displayName: "View",
      //     },
      //     {
      //       id: "INVESTMENT_CASH_FLOWS_ADD",
      //       key: "INVESTMENT_CASH_FLOWS_ADD",
      //       displayName: "Add",
      //     },
      //     {
      //       id: "INVESTMENT_CASH_FLOWS_EDIT",
      //       key: "INVESTMENT_CASH_FLOWS_EDIT",
      //       displayName: "Edit",
      //     },
      //     {
      //       id: "INVESTMENT_CASH_FLOWS_DELETE",
      //       key: "INVESTMENT_CASH_FLOWS_DELETE",
      //       displayName: "Delete",
      //     },
      //     {
      //       id: "INVESTMENT_CASH_FLOWS_CSV_DOWNLOAD",
      //       key: "INVESTMENT_CASH_FLOWS_CSV_DOWNLOAD",
      //       displayName: "Click Here",
      //     },
      //   ],
      // },
      {
        id: "CASH_FLOW_MASTER",
        key: "CASH_FLOW_MASTER",
        displayName: "Cash Flow Master",
        path: "/cash-flow-master",
        sectionId: "PORTFOLIO_MANAGEMENT",
        actions: [
          {
            id: "CASH_FLOW_MASTER_VIEW",
            key: "CASH_FLOW_MASTER_VIEW",
            displayName: "View",
          },
          {
            id: "CASH_FLOW_MASTER_ADD",
            key: "CASH_FLOW_MASTER_ADD",
            displayName: "Add",
          },
          {
            id: "CASH_FLOW_MASTER_DOWNLOAD",
            key: "CASH_FLOW_MASTER_DOWNLOAD",
            displayName: "Download",
          },
          {
            id: "CASH_FLOW_MASTER_SAMPLE_CSV",
            key: "CASH_FLOW_MASTER_SAMPLE_CSV",
            displayName: "Sample CSV",
          },
        ],
      },
      {
        id: "BOND_PRICE_MASTER",
        key: "BOND_PRICE_MASTER",
        displayName: "Bond Price Master",
        path: "/bond-price-master",
        sectionId: "PORTFOLIO_MANAGEMENT",
        actions: [
          {
            id: "BOND_PRICE_MASTER_VIEW",
            key: "BOND_PRICE_MASTER_VIEW",
            displayName: "View",
          },
          {
            id: "BOND_PRICE_MASTER_ADD",
            key: "BOND_PRICE_MASTER_ADD",
            displayName: "Add",
          },
          {
            id: "BOND_PRICE_MASTER_DOWNLOAD",
            key: "BOND_PRICE_MASTER_DOWNLOAD",
            displayName: "Download",
          },
          {
            id: "BOND_PRICE_MASTER_SAMPLE_CSV",
            key: "BOND_PRICE_MASTER_SAMPLE_CSV",
            displayName: "Sample CSV",
          },
        ],
      },
    ],
  },
  {
    id: "USERS",
    key: "USERS",
    displayName: "Users",
    expandable: true,
    pages: [
      {
        id: "EXTERNAL_USERS",
        key: "EXTERNAL_USERS",
        displayName: "External Users",
        path: "/users",
        sectionId: "USERS",
        actions: [
          {
            id: "EXTERNAL_USERS_VIEW",
            key: "EXTERNAL_USERS_VIEW",
            displayName: "View",
          },
          {
            id: "EXTERNAL_USERS_ADD",
            key: "EXTERNAL_USERS_ADD",
            displayName: "Add",
          },
          {
            id: "EXTERNAL_USERS_MANAGE_DISTRIBUTOR",
            key: "EXTERNAL_USERS_MANAGE_DISTRIBUTOR",
            displayName: "Manage Distributor",
          },
          {
            id: "EXTERNAL_USERS_SET_ROLE",
            key: "EXTERNAL_USERS_SET_ROLE",
            displayName: "Set Role",
          },
          {
            id: "EXTERNAL_USERS_MANAGE_RM",
            key: "EXTERNAL_USERS_MANAGE_RM",
            displayName: "Manage RM",
          },
          {
            id: "EXTERNAL_USERS_ASSIGN_PARENT",
            key: "EXTERNAL_USERS_ASSIGN_PARENT",
            displayName: "Assign Parent",
          },
          {
            id: "EXTERNAL_USERS_SET_NIL_TDS",
            key: "EXTERNAL_USERS_SET_NIL_TDS",
            displayName: "Set Nil TDS",
          },
          {
            id: "EXTERNAL_USERS_CREATE_VPA",
            key: "EXTERNAL_USERS_CREATE_VPA",
            displayName: "Create VPA",
          },
          {
            id: "EXTERNAL_USERS_DOWNLOAD",
            key: "EXTERNAL_USERS_DOWNLOAD",
            displayName: "Download",
          },
          {
            id: "EXTERNAL_USERS_DELETE",
            key: "EXTERNAL_USERS_DELETE",
            displayName: "Delete",
          },
        ],
      },
      {
        id: "SUBSCRIPTIONS_DATA",
        key: "SUBSCRIPTIONS_DATA",
        displayName: "Subscriptions Data",
        path: "/email-subscribe",
        sectionId: "USERS",
        actions: [
          {
            id: "SUBSCRIPTIONS_DATA_VIEW",
            key: "SUBSCRIPTIONS_DATA_VIEW",
            displayName: "View",
          },
          {
            id: "SUBSCRIPTIONS_DATA_DOWNLOAD",
            key: "SUBSCRIPTIONS_DATA_DOWNLOAD",
            displayName: "Download",
          },
        ],
      },
    ],
  },
  {
    id: "MARKETING",
    key: "MARKETING",
    displayName: "Marketing",
    expandable: true,
    pages: [
      {
        id: "EMAILS",
        key: "EMAILS",
        displayName: "Emails",
        path: "/marketing-emails",
        sectionId: "MARKETING",
        actions: [
          {
            id: "EMAILS_VIEW",
            key: "EMAILS_VIEW",
            displayName: "View",
          },
          {
            id: "EMAILS_CREATE_CAMPAIGN",
            key: "EMAILS_CREATE_CAMPAIGN",
            displayName: "Create Campaign",
          },
          {
            id: "EMAILS_DELETE",
            key: "EMAILS_DELETE",
            displayName: "Delete",
          },
          // {
          //   id: "EMAILS_TEST",
          //   key: "EMAILS_TEST",
          //   displayName: "Test",
          // },
          // {
          //   id: "EMAILS_VERIFY",
          //   key: "EMAILS_VERIFY",
          //   displayName: "Verify",
          // },
          // {
          //   id: "EMAILS_SCHEDULE",
          //   key: "EMAILS_SCHEDULE",
          //   displayName: "Schedule",
          // },
          // {
          //   id: "EMAILS_SUSPEND",
          //   key: "EMAILS_SUSPEND",
          //   displayName: "Suspend",
          // },
          {
            id: "EMAILS_DOWNLOAD_SUMMARY",
            key: "EMAILS_DOWNLOAD_SUMMARY",
            displayName: "Download Summary",
          },
        ],
      },
    ],
  },
  {
    id: "ADMIN",
    key: "ADMIN",
    displayName: "Admin",
    expandable: true,
    pages: [
      {
        id: "INVESTMENT_TRANCHE",
        key: "INVESTMENT_TRANCHE",
        displayName: "Investment Tranche",
        path: "/investment-tranche",
        sectionId: "ADMIN",
        actions: [
          {
            id: "INVESTMENT_TRANCHE_VIEW",
            key: "INVESTMENT_TRANCHE_VIEW",
            displayName: "View",
          },
          {
            id: "INVESTMENT_TRANCHE_ADD",
            key: "INVESTMENT_TRANCHE_ADD",
            displayName: "Add",
          },
          {
            id: "INVESTMENT_TRANCHE_EDIT",
            key: "INVESTMENT_TRANCHE_EDIT",
            displayName: "Edit",
          },
          {
            id: "INVESTMENT_TRANCHE_DELETE",
            key: "INVESTMENT_TRANCHE_DELETE",
            displayName: "Delete",
          },
        ],
      },
      {
        id: "INVESTMENT_LOT",
        key: "INVESTMENT_LOT",
        displayName: "Investment Lot",
        path: "/investment-lot",
        sectionId: "ADMIN",
        actions: [
          {
            id: "INVESTMENT_LOT_VIEW",
            key: "INVESTMENT_LOT_VIEW",
            displayName: "View",
          },
          {
            id: "INVESTMENT_LOT_ADD",
            key: "INVESTMENT_LOT_ADD",
            displayName: "Add",
          },
          {
            id: "INVESTMENT_LOT_DOWNLOAD",
            key: "INVESTMENT_LOT_DOWNLOAD",
            displayName: "Download",
          },
          {
            id: "INVESTMENT_LOT_EDIT",
            key: "INVESTMENT_LOT_EDIT",
            displayName: "Edit",
          },
        ],
      },
      {
        id: "BOND_INVENTORY_MANAGEMENT",
        key: "BOND_INVENTORY_MANAGEMENT",
        displayName: "Bond Inventory Management",
        path: "/bond-inventory-management",
        sectionId: "ADMIN",
        actions: [
          {
            id: "BOND_INVENTORY_MANAGEMENT_VIEW",
            key: "BOND_INVENTORY_MANAGEMENT_VIEW",
            displayName: "View",
          },
          {
            id: "BOND_INVENTORY_MANAGEMENT_ADD_UPDATE",
            key: "BOND_INVENTORY_MANAGEMENT_ADD_UPDATE",
            displayName: "Add/Update",
          },
          {
            id: "BOND_INVENTORY_MANAGEMENT_DOWNLOAD",
            key: "BOND_INVENTORY_MANAGEMENT_DOWNLOAD",
            displayName: "Download",
          },
        ],
      },
      {
        id: "ROLES",
        key: "ROLES",
        displayName: "Roles",
        path: "/role",
        sectionId: "ADMIN",
        actions: [
          {
            id: "ROLES_VIEW",
            key: "ROLES_VIEW",
            displayName: "View",
          },
          {
            id: "ROLES_ADD",
            key: "ROLES_ADD",
            displayName: "Add",
          },
          {
            id: "ROLES_EDIT",
            key: "ROLES_EDIT",
            displayName: "Edit",
          },
          {
            id: "ROLES_DELETE",
            key: "ROLES_DELETE",
            displayName: "Delete",
          },
        ],
      },
      // {
      //   id: "PERMISSIONS",
      //   key: "PERMISSIONS",
      //   displayName: "Permissions",
      //   path: "/permission",
      //   sectionId: "ADMIN",
      //   actions: [
      //     {
      //       id: "PERMISSIONS_VIEW",
      //       key: "PERMISSIONS_VIEW",
      //       displayName: "View",
      //     },
      //   ],
      // },
      {
        id: "NEW_PERMISSIONS",
        key: "NEW_PERMISSIONS",
        displayName: "Permissions",
        path: "/permissions",
        sectionId: "ADMIN",
        actions: [
          {
            id: "NEW_PERMISSIONS_VIEW",
            key: "NEW_PERMISSIONS_VIEW",
            displayName: "View",
          },
          {
            id: "NEW_PERMISSIONS_ADD_UPDATE",
            key: "NEW_PERMISSIONS_ADD_UPDATE",
            displayName: "Add/Update",
          },
        ],
      },
      // {
      //   id: "ROLE_PERMISSIONS",
      //   key: "ROLE_PERMISSIONS",
      //   displayName: "Role Permissions",
      //   path: "/role-permission",
      //   sectionId: "ADMIN",
      //   actions: [
      //     {
      //       id: "ROLE_PERMISSIONS_VIEW",
      //       key: "ROLE_PERMISSIONS_VIEW",
      //       displayName: "View",
      //     },
      //     {
      //       id: "ROLE_PERMISSIONS_MANAGE_ROLE_PERMISSIONS",
      //       key: "ROLE_PERMISSIONS_MANAGE_ROLE_PERMISSIONS",
      //       displayName: "Manage Role Permissions",
      //     },
      //   ],
      // },
      {
        id: "INTERNAL_USERS",
        key: "INTERNAL_USERS",
        displayName: "Internal Users",
        path: "/ops-users",
        sectionId: "ADMIN",
        actions: [
          {
            id: "INTERNAL_USERS_VIEW",
            key: "INTERNAL_USERS_VIEW",
            displayName: "View",
          },
          {
            id: "INTERNAL_USERS_UPDATE_PASSWORD",
            key: "INTERNAL_USERS_UPDATE_PASSWORD",
            displayName: "Update Password",
          },
          {
            id: "INTERNAL_USERS_DOWNLOAD",
            key: "INTERNAL_USERS_DOWNLOAD",
            displayName: "Download",
          },
          {
            id: "INTERNAL_USERS_EDIT",
            key: "INTERNAL_USERS_EDIT",
            displayName: "Edit",
          },
          {
            id: "INTERNAL_USERS_DELETE",
            key: "INTERNAL_USERS_DELETE",
            displayName: "Delete",
          },
        ],
      },
      {
        id: "VPA_REPORTS",
        key: "VPA_REPORTS",
        displayName: "VPA Reports",
        path: "/vpa-reports",
        sectionId: "ADMIN",
        actions: [
          {
            id: "VPA_REPORTS_VIEW",
            key: "VPA_REPORTS_VIEW",
            displayName: "View",
          },
          {
            id: "VPA_REPORTS_REFRESH",
            key: "VPA_REPORTS_REFRESH",
            displayName: "Refresh",
          },
          {
            id: "VPA_REPORTS_DOWNLOAD",
            key: "VPA_REPORTS_DOWNLOAD",
            displayName: "Download",
          },
        ],
      },
      {
        id: "DEPOSITORY_MASTER",
        key: "DEPOSITORY_MASTER",
        displayName: "Depository Master",
        path: "/depository-master",
        sectionId: "ADMIN",
        actions: [
          {
            id: "DEPOSITORY_MASTER_VIEW",
            key: "DEPOSITORY_MASTER_VIEW",
            displayName: "View",
          },
          {
            id: "DEPOSITORY_MASTER_ADD",
            key: "DEPOSITORY_MASTER_ADD",
            displayName: "Add",
          },
          {
            id: "DEPOSITORY_MASTER_UPLOAD",
            key: "DEPOSITORY_MASTER_UPLOAD",
            displayName: "Upload",
          },
          {
            id: "DEPOSITORY_MASTER_DOWNLOAD",
            key: "DEPOSITORY_MASTER_DOWNLOAD",
            displayName: "Download",
          },
          {
            id: "DEPOSITORY_MASTER_EDIT",
            key: "DEPOSITORY_MASTER_EDIT",
            displayName: "Edit",
          },
          {
            id: "DEPOSITORY_MASTER_DELETE",
            key: "DEPOSITORY_MASTER_DELETE",
            displayName: "Delete",
          },
          {
            id: "DEPOSITORY_MASTER_SAMPLE_CSV",
            key: "DEPOSITORY_MASTER_SAMPLE_CSV",
            displayName: "Sample CSV",
          },
        ],
      },
      {
        id: "DEBENTURE_TRUSTEE_MASTER",
        key: "DEBENTURE_TRUSTEE_MASTER",
        displayName: "Debenture Trustee Master",
        path: "/debenture-trustee-master",
        sectionId: "ADMIN",
        actions: [
          {
            id: "DEBENTURE_TRUSTEE_MASTER_VIEW",
            key: "DEBENTURE_TRUSTEE_MASTER_VIEW",
            displayName: "View",
          },
          {
            id: "DEBENTURE_TRUSTEE_MASTER_ADD",
            key: "DEBENTURE_TRUSTEE_MASTER_ADD",
            displayName: "Add",
          },
          {
            id: "DEBENTURE_TRUSTEE_MASTER_DOWNLOAD",
            key: "DEBENTURE_TRUSTEE_MASTER_DOWNLOAD",
            displayName: "Download",
          },
          {
            id: "DEBENTURE_TRUSTEE_MASTER_EDIT",
            key: "DEBENTURE_TRUSTEE_MASTER_EDIT",
            displayName: "Edit",
          },
          {
            id: "DEBENTURE_TRUSTEE_MASTER_DELETE",
            key: "DEBENTURE_TRUSTEE_MASTER_DELETE",
            displayName: "Delete",
          },
        ],
      },
      {
        id: "TRANSFEROR_MASTER",
        key: "TRANSFEROR_MASTER",
        displayName: "Transferor Master",
        path: "/transferor-master",
        sectionId: "ADMIN",
        actions: [
          {
            id: "TRANSFEROR_MASTER_VIEW",
            key: "TRANSFEROR_MASTER_VIEW",
            displayName: "View",
          },
          {
            id: "TRANSFEROR_MASTER_ADD",
            key: "TRANSFEROR_MASTER_ADD",
            displayName: "Add",
          },
          {
            id: "TRANSFEROR_MASTER_DOWNLOAD",
            key: "TRANSFEROR_MASTER_DOWNLOAD",
            displayName: "Download",
          },
          {
            id: "TRANSFEROR_MASTER_EDIT",
            key: "TRANSFEROR_MASTER_EDIT",
            displayName: "Edit",
          },
          {
            id: "TRANSFEROR_MASTER_DELETE",
            key: "TRANSFEROR_MASTER_DELETE",
            displayName: "Delete",
          },
        ],
      },
      {
        id: "APPROVE_ADDENDUM",
        key: "APPROVE_ADDENDUM",
        displayName: "Approve Addendum",
        path: "/approve-addendum",
        sectionId: "ADMIN",
        actions: [
          {
            id: "APPROVE_ADDENDUM_VIEW",
            key: "APPROVE_ADDENDUM_VIEW",
            displayName: "View",
          },
          {
            id: "APPROVE_ADDENDUM_DOWNLOAD",
            key: "APPROVE_ADDENDUM_DOWNLOAD",
            displayName: "Download",
          },
          {
            id: "APPROVE_ADDENDUM_APPROVE",
            key: "APPROVE_ADDENDUM_APPROVE",
            displayName: "Approve",
          },
        ],
      },
    ],
  },
  {
    id: "POLICIES",
    key: "POLICIES",
    displayName: "Policies",
    expandable: true,
    pages: [
      {
        id: "INVESTMENT_CREATION",
        key: "INVESTMENT_CREATION",
        displayName: "Investment Creation",
        path: "/investment-creation-policy",
        sectionId: "POLICIES",
        actions: [
          {
            id: "INVESTMENT_CREATION_VIEW",
            key: "INVESTMENT_CREATION_VIEW",
            displayName: "View",
          },
          {
            id: "INVESTMENT_CREATION_ADD",
            key: "INVESTMENT_CREATION_ADD",
            displayName: "Add",
          },
          {
            id: "INVESTMENT_CREATION_EDIT",
            key: "INVESTMENT_CREATION_EDIT",
            displayName: "Edit",
          },
          {
            id: "INVESTMENT_CREATION_DELETE",
            key: "INVESTMENT_CREATION_DELETE",
            displayName: "Delete",
          },
        ],
      },
      {
        id: "INVESTMENT_PUBLISHING",
        key: "INVESTMENT_PUBLISHING",
        displayName: "Investment Publishing",
        path: "/investment-publishing-workflow",
        sectionId: "POLICIES",
        actions: [
          {
            id: "INVESTMENT_PUBLISHING_VIEW",
            key: "INVESTMENT_PUBLISHING_VIEW",
            displayName: "View",
          },
          {
            id: "INVESTMENT_PUBLISHING_ADD",
            key: "INVESTMENT_PUBLISHING_ADD",
            displayName: "Add",
          },
          {
            id: "INVESTMENT_PUBLISHING_EDIT",
            key: "INVESTMENT_PUBLISHING_EDIT",
            displayName: "Edit",
          },
          {
            id: "INVESTMENT_PUBLISHING_DELETE",
            key: "INVESTMENT_PUBLISHING_DELETE",
            displayName: "Delete",
          },
        ],
      },
      {
        id: "INTEREST_APPROVAL_POLICY",
        key: "INTEREST_APPROVAL_POLICY",
        displayName: "Interest Approval",
        path: "/interest-approval-policy",
        sectionId: "POLICIES",
        actions: [
          {
            id: "INTEREST_APPROVAL_POLICY_VIEW",
            key: "INTEREST_APPROVAL_POLICY_VIEW",
            displayName: "View",
          },
          {
            id: "INTEREST_APPROVAL_POLICY_ADD",
            key: "INTEREST_APPROVAL_POLICY_ADD",
            displayName: "Add/Update",
          },
        ],
      },
    ],
  },
  {
    id: "OTHERS",
    key: "OTHERS",
    displayName: "Others",
    expandable: true,
    pages: [
      {
        id: "GET_MY_USERS",
        key: "GET_MY_USERS",
        displayName: "Get My Users",
        path: "/get-my-users",
        sectionId: "OTHERS",
        actions: [
          {
            id: "EXTERNAL_USERS_VIEW",
            key: "EXTERNAL_USERS_VIEW",
            displayName: "View",
          },
          {
            id: "EXTERNAL_USERS_DELETE",
            key: "EXTERNAL_USERS_DELETE",
            displayName: "Delete",
          },
        ],
      },
      {
        id: "CMS",
        key: "CMS",
        displayName: "CMS",
        path: "/cms",
        sectionId: "OTHERS",
        actions: [
          {
            id: "CMS_VIEW",
            key: "CMS_VIEW",
            displayName: "View",
          },
          {
            id: "CMS_EDIT",
            key: "CMS_EDIT",
            displayName: "Edit",
          },
        ],
      },
      {
        id: "DISTRIBUTOR_LEADS",
        key: "DISTRIBUTOR_LEADS",
        displayName: "Distributor Leads",
        path: "/distributor-leads",
        sectionId: "OTHERS",
        actions: [
          {
            id: "DISTRIBUTOR_LEADS_VIEW",
            key: "DISTRIBUTOR_LEADS_VIEW",
            displayName: "View",
          },
          {
            id: "DISTRIBUTOR_LEADS_DOWNLOAD",
            key: "DISTRIBUTOR_LEADS_DOWNLOAD",
            displayName: "Download",
          },
        ],
      },
      {
        id: "CONTACT_DATA",
        key: "CONTACT_DATA",
        displayName: "Contact Data",
        path: "/contact-us",
        sectionId: "OTHERS",
        actions: [
          {
            id: "CONTACT_DATA_VIEW",
            key: "CONTACT_DATA_VIEW",
            displayName: "View",
          },
        ],
      },
      {
        id: "EMAIL_LOGS",
        key: "EMAIL_LOGS",
        displayName: "Email Logs",
        path: "/email-logs",
        sectionId: "OTHERS",
        actions: [
          {
            id: "EMAIL_LOGS_VIEW",
            key: "EMAIL_LOGS_VIEW",
            displayName: "View",
          },
          {
            id: "EMAIL_LOGS_DOWNLOAD",
            key: "EMAIL_LOGS_DOWNLOAD",
            displayName: "Download",
          },
        ],
      },
      {
        id: "WHATSAPP_LOGS",
        key: "WHATSAPP_LOGS",
        displayName: "Whatsapp Logs",
        path: "/whatsapp-logs",
        sectionId: "OTHERS",
        actions: [
          {
            id: "WHATSAPP_LOGS_VIEW",
            key: "WHATSAPP_LOGS_VIEW",
            displayName: "View",
          },
          {
            id: "WHATSAPP_LOGS_DOWNLOAD",
            key: "WHATSAPP_LOGS_DOWNLOAD",
            displayName: "Download",
          },
        ],
      },
      {
        id: "TRADE_LOGS",
        key: "TRADE_LOGS",
        displayName: "Trade Logs",
        path: "/trade-logs",
        sectionId: "OTHERS",
        actions: [
          {
            id: "TRADE_LOGS_VIEW",
            key: "TRADE_LOGS_VIEW",
            displayName: "View",
          },
          {
            id: "TRADE_LOGS_DOWNLOAD",
            key: "TRADE_LOGS_DOWNLOAD",
            displayName: "Download",
          },
        ],
      },
      {
        id: "EI_CLICKS_DATA",
        key: "EI_CLICKS_DATA",
        displayName: "EI Clicks Data",
        path: "/invest-interest",
        sectionId: "OTHERS",
        actions: [
          {
            id: "EI_CLICKS_DATA_VIEW",
            key: "EI_CLICKS_DATA_VIEW",
            displayName: "View",
          },
          {
            id: "EI_CLICKS_DATA_DOWNLOAD",
            key: "EI_CLICKS_DATA_DOWNLOAD",
            displayName: "Download",
          },
        ],
      },
      {
        id: "SCHEDULED_CALLS",
        key: "SCHEDULED_CALLS",
        displayName: "Scheduled Calls",
        path: "/schedule-call",
        sectionId: "OTHERS",
        actions: [
          {
            id: "SCHEDULED_CALLS_VIEW",
            key: "SCHEDULED_CALLS_VIEW",
            displayName: "View",
          },
          {
            id: "SCHEDULED_CALLS_DOWNLOAD",
            key: "SCHEDULED_CALLS_DOWNLOAD",
            displayName: "Download",
          },
        ],
      },
      {
        id: "GEO_DATA",
        key: "GEO_DATA",
        displayName: "Geo Data",
        path: "/geo-data",
        sectionId: "OTHERS",
        actions: [
          {
            id: "GEO_DATA_VIEW",
            key: "GEO_DATA_VIEW",
            displayName: "View",
          },
        ],
      },
    ],
  },
  {
    id: "MANAGE_DISTRIBUTOR",
    key: "MANAGE_DISTRIBUTOR",
    displayName: "Manage Distributor",
    expandable: true,
    pages: [
      {
        id: "DISTRIBUTOR_REPORT",
        key: "DISTRIBUTOR_REPORT",
        displayName: "Distributor Report",
        path: "/distributor-report",
        sectionId: "MANAGE_DISTRIBUTOR",
        actions: [
          {
            id: "DISTRIBUTOR_REPORT_VIEW",
            key: "DISTRIBUTOR_REPORT_VIEW",
            displayName: "View",
          },
          {
            id: "DISTRIBUTOR_REPORT_DOWNLOAD",
            key: "DISTRIBUTOR_REPORT_DOWNLOAD",
            displayName: "Download",
          },
        ],
      },
      {
        id: "DISTRIBUTOR_KYC",
        key: "DISTRIBUTOR_KYC",
        displayName: "Distributor KYC",
        path: "/distributor-kyc",
        sectionId: "MANAGE_DISTRIBUTOR",
        actions: [
          {
            id: "DISTRIBUTOR_KYC_VIEW",
            key: "DISTRIBUTOR_KYC_VIEW",
            displayName: "View",
          },
          {
            id: "DISTRIBUTOR_KYC_ADD",
            key: "DISTRIBUTOR_KYC_ADD",
            displayName: "Add",
          },
          {
            id: "DISTRIBUTOR_KYC_DOWNLOAD",
            key: "DISTRIBUTOR_KYC_DOWNLOAD",
            displayName: "Download",
          },
          {
            id: "DISTRIBUTOR_KYC_UPDATE",
            key: "DISTRIBUTOR_KYC_UPDATE",
            displayName: "Update",
          },
        ],
      },
      {
        id: "DISTRIBUTOR_AGREEMENT",
        key: "DISTRIBUTOR_AGREEMENT",
        displayName: "Distributor Agreement",
        path: "/distributor-agreement",
        sectionId: "MANAGE_DISTRIBUTOR",
        actions: [
          {
            id: "DISTRIBUTOR_AGREEMENT_VIEW",
            key: "DISTRIBUTOR_AGREEMENT_VIEW",
            displayName: "View",
          },
          {
            id: "DISTRIBUTOR_AGREEMENT_ADD",
            key: "DISTRIBUTOR_AGREEMENT_ADD",
            displayName: "Add",
          },
          {
            id: "DISTRIBUTOR_AGREEMENT_UPDATE_BILLING_FREQUENCY",
            key: "DISTRIBUTOR_AGREEMENT_UPDATE_BILLING_FREQUENCY",
            displayName: "Update Billing Frequency",
          },
          {
            id: "DISTRIBUTOR_AGREEMENT_UPLOAD_SIGNED_AGREEMENT",
            key: "DISTRIBUTOR_AGREEMENT_UPLOAD_SIGNED_AGREEMENT",
            displayName: "Upload Signed Agreement",
          },
          {
            id: "DISTRIBUTOR_AGREEMENT_DOWNLOAD",
            key: "DISTRIBUTOR_AGREEMENT_DOWNLOAD",
            displayName: "Download",
          },
          {
            id: "DISTRIBUTOR_AGREEMENT_EDIT",
            key: "DISTRIBUTOR_AGREEMENT_EDIT",
            displayName: "Edit",
          },
          {
            id: "DISTRIBUTOR_AGREEMENT_SIGN",
            key: "DISTRIBUTOR_AGREEMENT_SIGN",
            displayName: "Sign, Cancel",
          },
        ],
      },
      {
        id: "DISTRIBUTOR_ADDENDUM",
        key: "DISTRIBUTOR_ADDENDUM",
        displayName: "Distributor Addendum",
        path: "/distributor-addendum",
        sectionId: "MANAGE_DISTRIBUTOR",
        actions: [
          {
            id: "DISTRIBUTOR_ADDENDUM_VIEW",
            key: "DISTRIBUTOR_ADDENDUM_VIEW",
            displayName: "View",
          },
          {
            id: "DISTRIBUTOR_ADDENDUM_ADD",
            key: "DISTRIBUTOR_ADDENDUM_ADD",
            displayName: "Add",
          },
          {
            id: "DISTRIBUTOR_ADDENDUM_UPLOAD_SIGNED_ADDENDUM",
            key: "DISTRIBUTOR_ADDENDUM_UPLOAD_SIGNED_ADDENDUM",
            displayName: "Upload Signed Addendum",
          },
          {
            id: "DISTRIBUTOR_ADDENDUM_UPDATE_ACTIVE_STATUS",
            key: "DISTRIBUTOR_ADDENDUM_UPDATE_ACTIVE_STATUS",
            displayName: "Update Active Status",
          },
          {
            id: "DISTRIBUTOR_ADDENDUM_DEACTIVATE_ADDENDUM",
            key: "DISTRIBUTOR_ADDENDUM_DEACTIVATE_ADDENDUM",
            displayName: "Deactivate Addendum",
          },
          {
            id: "DISTRIBUTOR_ADDENDUM_DOWNLOAD",
            key: "DISTRIBUTOR_ADDENDUM_DOWNLOAD",
            displayName: "Download",
          },
          {
            id: "DISTRIBUTOR_ADDENDUM_EDIT",
            key: "DISTRIBUTOR_ADDENDUM_EDIT",
            displayName: "Edit",
          },
          {
            id: "DISTRIBUTOR_ADDENDUM_SIGN",
            key: "DISTRIBUTOR_ADDENDUM_SIGN",
            displayName: "Sign, Cancel",
          },
        ],
      },
      {
        id: "DISTRIBUTOR_INVOICE",
        key: "DISTRIBUTOR_INVOICE",
        displayName: "Distributor Invoice",
        path: "/distributor-invoice",
        sectionId: "MANAGE_DISTRIBUTOR",
        actions: [
          {
            id: "DISTRIBUTOR_INVOICE_VIEW",
            key: "DISTRIBUTOR_INVOICE_VIEW",
            displayName: "View",
          },
          {
            id: "DISTRIBUTOR_INVOICE_UPDATE_STATUS",
            key: "DISTRIBUTOR_INVOICE_UPDATE_STATUS",
            displayName: "Update Status",
          },
          {
            id: "DISTRIBUTOR_INVOICE_DOWNLOAD",
            key: "DISTRIBUTOR_INVOICE_DOWNLOAD",
            displayName: "Download",
          },
        ],
      },
    ],
  },
  {
    id: "SALES_AND_INVESTOR_SERVICE",
    key: "SALES_AND_INVESTOR_SERVICE",
    displayName: "Sales & Investor Service",
    expandable: true,
    pages: [
      {
        id: "SALES_AND_INVESTOR_SERVICE_INVESTOR_DASHBOARDS",
        key: "SALES_AND_INVESTOR_SERVICE_INVESTOR_DASHBOARDS",
        displayName: "Investor Dashboards",
        path: "/rm-investor-dashboard",
        sectionId: "SALES_AND_INVESTOR_SERVICE",
        actions: [
          {
            id: "SALES_AND_INVESTOR_SERVICE_INVESTOR_DASHBOARDS_VIEW",
            key: "SALES_AND_INVESTOR_SERVICE_INVESTOR_DASHBOARDS_VIEW",
            displayName: "View",
          },
          {
            id: "SALES_AND_INVESTOR_SERVICE_INVESTOR_DASHBOARDS_DOWNLOAD",
            key: "SALES_AND_INVESTOR_SERVICE_INVESTOR_DASHBOARDS_DOWNLOAD",
            displayName: "Download",
          },
        ],
      },
      {
        id: "SALES_AND_INVESTOR_SERVICE_MANAGE_MY_INVESTORS",
        key: "SALES_AND_INVESTOR_SERVICE_MANAGE_MY_INVESTORS",
        displayName: "Manage My Investors",
        path: "/rm-users",
        sectionId: "SALES_AND_INVESTOR_SERVICE",
        actions: [
          {
            id: "SALES_AND_INVESTOR_SERVICE_MANAGE_MY_INVESTORS_VIEW",
            key: "SALES_AND_INVESTOR_SERVICE_MANAGE_MY_INVESTORS_VIEW",
            displayName: "View",
          },
          {
            id: "SALES_AND_INVESTOR_SERVICE_MANAGE_MY_INVESTORS_ADD",
            key: "SALES_AND_INVESTOR_SERVICE_MANAGE_MY_INVESTORS_ADD",
            displayName: "Add",
          },
          {
            id: "SALES_AND_INVESTOR_SERVICE_MANAGE_MY_INVESTORS_ADDUPDATE_KYC",
            key: "SALES_AND_INVESTOR_SERVICE_MANAGE_MY_INVESTORS_ADDUPDATE_KYC",
            displayName: "Add/Update KYC",
          },
        ],
      },
      {
        id: "SALES_AND_INVESTOR_SERVICE_DEAL_CASHFLOW_RETURNS",
        key: "SALES_AND_INVESTOR_SERVICE_DEAL_CASHFLOW_RETURNS",
        displayName: "Deal Cashflow & Returns",
        path: "/rm-deal-cashflow-returns",
        sectionId: "SALES_AND_INVESTOR_SERVICE",
        actions: [
          {
            id: "SALES_AND_INVESTOR_SERVICE_DEAL_CASHFLOW_RETURNS_VIEW",
            key: "SALES_AND_INVESTOR_SERVICE_DEAL_CASHFLOW_RETURNS_VIEW",
            displayName: "View",
          },
          {
            id: "SALES_AND_INVESTOR_SERVICE_DEAL_CASHFLOW_RETURNS_DOWNLOAD_EXCEL",
            key: "SALES_AND_INVESTOR_SERVICE_DEAL_CASHFLOW_RETURNS_DOWNLOAD_EXCEL",
            displayName: "Download Excel",
          },
          {
            id: "SALES_AND_INVESTOR_SERVICE_DEAL_CASHFLOW_RETURNS_DOWNLOAD_PDF",
            key: "SALES_AND_INVESTOR_SERVICE_DEAL_CASHFLOW_RETURNS_DOWNLOAD_PDF",
            displayName: "Download PDF",
          },
        ],
      },
      {
        id: "SALES_AND_INVESTOR_SERVICE_ADDMODIFY_INVESTMENTS",
        key: "SALES_AND_INVESTOR_SERVICE_ADDMODIFY_INVESTMENTS",
        displayName: "Add/Modify Investments",
        path: "/rm-user-investment",
        sectionId: "SALES_AND_INVESTOR_SERVICE",
        actions: [
          {
            id: "SALES_AND_INVESTOR_SERVICE_ADDMODIFY_INVESTMENTS_VIEW",
            key: "SALES_AND_INVESTOR_SERVICE_ADDMODIFY_INVESTMENTS_VIEW",
            displayName: "View",
          },
          {
            id: "SALES_AND_INVESTOR_SERVICE_ADDMODIFY_INVESTMENTS_ADD",
            key: "SALES_AND_INVESTOR_SERVICE_ADDMODIFY_INVESTMENTS_ADD",
            displayName: "Add",
          },
          {
            id: "SALES_AND_INVESTOR_SERVICE_ADDMODIFY_INVESTMENTS_DOWNLOAD",
            key: "SALES_AND_INVESTOR_SERVICE_ADDMODIFY_INVESTMENTS_DOWNLOAD",
            displayName: "Download",
          },
          {
            id: "SALES_AND_INVESTOR_SERVICE_ADDMODIFY_INVESTMENTS_EDIT",
            key: "SALES_AND_INVESTOR_SERVICE_ADDMODIFY_INVESTMENTS_EDIT",
            displayName: "Edit",
          },
        ],
      },
      {
        id: "SALES_AND_INVESTOR_SERVICE_ADDMODIFY_TOPUPS",
        key: "SALES_AND_INVESTOR_SERVICE_ADDMODIFY_TOPUPS",
        displayName: "Add/Modify Top-Ups",
        path: "/rm-user-investment-topup",
        sectionId: "SALES_AND_INVESTOR_SERVICE",
        actions: [
          {
            id: "SALES_AND_INVESTOR_SERVICE_ADDMODIFY_TOPUPS_VIEW",
            key: "SALES_AND_INVESTOR_SERVICE_ADDMODIFY_TOPUPS_VIEW",
            displayName: "View",
          },
          {
            id: "SALES_AND_INVESTOR_SERVICE_ADDMODIFY_TOPUPS_ADD",
            key: "SALES_AND_INVESTOR_SERVICE_ADDMODIFY_TOPUPS_ADD",
            displayName: "Add",
          },
          {
            id: "SALES_AND_INVESTOR_SERVICE_ADDMODIFY_TOPUPS_DOWNLOAD",
            key: "SALES_AND_INVESTOR_SERVICE_ADDMODIFY_TOPUPS_DOWNLOAD",
            displayName: "Download",
          },
          {
            id: "SALES_AND_INVESTOR_SERVICE_ADDMODIFY_TOPUPS_EDIT",
            key: "SALES_AND_INVESTOR_SERVICE_ADDMODIFY_TOPUPS_EDIT",
            displayName: "Edit",
          },
        ],
      },
      {
        id: "SALES_AND_INVESTOR_SERVICE_VA_TRANSACTIONS",
        key: "SALES_AND_INVESTOR_SERVICE_VA_TRANSACTIONS",
        displayName: "VA Transactions",
        path: "/rm-va-transactions",
        sectionId: "SALES_AND_INVESTOR_SERVICE",
        actions: [
          {
            id: "SALES_AND_INVESTOR_SERVICE_VA_TRANSACTIONS_VIEW",
            key: "SALES_AND_INVESTOR_SERVICE_VA_TRANSACTIONS_VIEW",
            displayName: "View",
          },
        ],
      },
      {
        id: "SALES_AND_INVESTOR_SERVICE_STF_STATUS",
        key: "SALES_AND_INVESTOR_SERVICE_STF_STATUS",
        displayName: "STF Status",
        path: "/rm-stf-status",
        sectionId: "SALES_AND_INVESTOR_SERVICE",
        actions: [
          {
            id: "SALES_AND_INVESTOR_SERVICE_STF_STATUS_VIEW",
            key: "SALES_AND_INVESTOR_SERVICE_STF_STATUS_VIEW",
            displayName: "View",
          },
        ],
      },
      {
        id: "SALES_AND_INVESTOR_SERVICE_BILLING",
        key: "SALES_AND_INVESTOR_SERVICE_BILLING",
        displayName: "Billing",
        path: "/rm-billing",
        sectionId: "SALES_AND_INVESTOR_SERVICE",
        actions: [
          {
            id: "SALES_AND_INVESTOR_SERVICE_BILLING_VIEW",
            key: "SALES_AND_INVESTOR_SERVICE_BILLING_VIEW",
            displayName: "View",
          },
        ],
      },
      {
        id: "SALES_AND_INVESTOR_SERVICE_CAMPAIGN_LEADS",
        key: "SALES_AND_INVESTOR_SERVICE_CAMPAIGN_LEADS",
        displayName: "Campaign Leads",
        path: "/campaign-leads",
        sectionId: "SALES_AND_INVESTOR_SERVICE",
        actions: [
          {
            id: "SALES_AND_INVESTOR_SERVICE_CAMPAIGN_LEADS_VIEW",
            key: "SALES_AND_INVESTOR_SERVICE_CAMPAIGN_LEADS_VIEW",
            displayName: "View",
          },
          {
            id: "SALES_AND_INVESTOR_SERVICE_CAMPAIGN_LEADS_DOWNLOAD",
            key: "SALES_AND_INVESTOR_SERVICE_CAMPAIGN_LEADS_DOWNLOAD",
            displayName: "Download",
          },
        ],
      },
      {
        id: "SALES_AND_INVESTOR_SERVICE_DEAL_WATCHLIST",
        key: "SALES_AND_INVESTOR_SERVICE_DEAL_WATCHLIST",
        displayName: "Deal Watchlist",
        path: "/deal-watchlist",
        sectionId: "SALES_AND_INVESTOR_SERVICE",
        actions: [
          {
            id: "SALES_AND_INVESTOR_SERVICE_DEAL_WATCHLIST_VIEW",
            key: "SALES_AND_INVESTOR_SERVICE_DEAL_WATCHLIST_VIEW",
            displayName: "View",
          },
          {
            id: "SALES_AND_INVESTOR_SERVICE_DEAL_WATCHLIST_DOWNLOAD",
            key: "SALES_AND_INVESTOR_SERVICE_DEAL_WATCHLIST_DOWNLOAD",
            displayName: "Download",
          },
        ],
      },
    ],
  },
  {
    id: "DISTRIBUTOR_PORTAL",
    key: "DISTRIBUTOR_PORTAL",
    displayName: "Distributor Portal",
    expandable: false,
    pages: [
      {
        id: "DISTRIBUTOR_PORTAL_MY_DASHBOARD",
        key: "DISTRIBUTOR_PORTAL_MY_DASHBOARD",
        displayName: "My Dashboard",
        path: "/my-dashboard",
        sectionId: "DISTRIBUTOR_PORTAL",
        actions: [
          {
            id: "DISTRIBUTOR_PORTAL_MY_DASHBOARD_VIEW",
            key: "DISTRIBUTOR_PORTAL_MY_DASHBOARD_VIEW",
            displayName: "View",
          },
        ],
      },
      {
        id: "DISTRIBUTOR_PORTAL_INVESTOR_DASHBOARDS",
        key: "DISTRIBUTOR_PORTAL_INVESTOR_DASHBOARDS",
        displayName: "Investor Dashboards",
        path: "/investor-dashboard",
        sectionId: "DISTRIBUTOR_PORTAL",
        actions: [
          {
            id: "DISTRIBUTOR_PORTAL_INVESTOR_DASHBOARDS_VIEW",
            key: "DISTRIBUTOR_PORTAL_INVESTOR_DASHBOARDS_VIEW",
            displayName: "View",
          },
          {
            id: "DISTRIBUTOR_PORTAL_INVESTOR_DASHBOARDS_DOWNLOAD",
            key: "DISTRIBUTOR_PORTAL_INVESTOR_DASHBOARDS_DOWNLOAD",
            displayName: "Download",
          },
        ],
      },
      {
        id: "DISTRIBUTOR_PORTAL_MANAGE_INVESTORS",
        key: "DISTRIBUTOR_PORTAL_MANAGE_INVESTORS",
        displayName: "Manage Investors",
        path: "/distributor-users",
        sectionId: "DISTRIBUTOR_PORTAL",
        actions: [
          {
            id: "DISTRIBUTOR_PORTAL_MANAGE_INVESTORS_VIEW",
            key: "DISTRIBUTOR_PORTAL_MANAGE_INVESTORS_VIEW",
            displayName: "View",
          },
          {
            id: "DISTRIBUTOR_PORTAL_MANAGE_INVESTORS_ADD",
            key: "DISTRIBUTOR_PORTAL_MANAGE_INVESTORS_ADD",
            displayName: "Add",
          },
          {
            id: "DISTRIBUTOR_PORTAL_MANAGE_INVESTORS_ADDUPDATE_KYC",
            key: "DISTRIBUTOR_PORTAL_MANAGE_INVESTORS_ADDUPDATE_KYC",
            displayName: "Add/Update KYC",
          },
        ],
      },
      {
        id: "DISTRIBUTOR_PORTAL_DEAL_CASHFLOW_RETURNS",
        key: "DISTRIBUTOR_PORTAL_DEAL_CASHFLOW_RETURNS",
        displayName: "Deal Cashflow & Returns",
        path: "/deal-cashflow-returns",
        sectionId: "DISTRIBUTOR_PORTAL",
        actions: [
          {
            id: "DISTRIBUTOR_PORTAL_DEAL_CASHFLOW_RETURNS_VIEW",
            key: "DISTRIBUTOR_PORTAL_DEAL_CASHFLOW_RETURNS_VIEW",
            displayName: "View",
          },
          {
            id: "DISTRIBUTOR_PORTAL_DEAL_CASHFLOW_RETURNS_DOWNLOAD_EXCEL",
            key: "DISTRIBUTOR_PORTAL_DEAL_CASHFLOW_RETURNS_DOWNLOAD_EXCEL",
            displayName: "Download Excel",
          },
          {
            id: "DISTRIBUTOR_PORTAL_DEAL_CASHFLOW_RETURNS_DOWNLOAD_PDF",
            key: "DISTRIBUTOR_PORTAL_DEAL_CASHFLOW_RETURNS_DOWNLOAD_PDF",
            displayName: "Download PDF",
          },
        ],
      },
      {
        id: "DISTRIBUTOR_PORTAL_ADDMODIFY_INVESTMENTS",
        key: "DISTRIBUTOR_PORTAL_ADDMODIFY_INVESTMENTS",
        displayName: "Add/Modify Investments",
        path: "/distributor-user-investment",
        sectionId: "DISTRIBUTOR_PORTAL",
        actions: [
          {
            id: "DISTRIBUTOR_PORTAL_ADDMODIFY_INVESTMENTS_VIEW",
            key: "DISTRIBUTOR_PORTAL_ADDMODIFY_INVESTMENTS_VIEW",
            displayName: "View",
          },
          {
            id: "DISTRIBUTOR_PORTAL_ADDMODIFY_INVESTMENTS_ADD",
            key: "DISTRIBUTOR_PORTAL_ADDMODIFY_INVESTMENTS_ADD",
            displayName: "Add",
          },
          {
            id: "DISTRIBUTOR_PORTAL_ADDMODIFY_INVESTMENTS_DOWNLOAD",
            key: "DISTRIBUTOR_PORTAL_ADDMODIFY_INVESTMENTS_DOWNLOAD",
            displayName: "Download",
          },
          {
            id: "DISTRIBUTOR_PORTAL_ADDMODIFY_INVESTMENTS_EDIT",
            key: "DISTRIBUTOR_PORTAL_ADDMODIFY_INVESTMENTS_EDIT",
            displayName: "Edit",
          },
        ],
      },
      {
        id: "DISTRIBUTOR_PORTAL_BILLING",
        key: "DISTRIBUTOR_PORTAL_BILLING",
        displayName: "Billing",
        path: "/billing",
        sectionId: "DISTRIBUTOR_PORTAL",
        actions: [
          {
            id: "DISTRIBUTOR_PORTAL_BILLING_VIEW",
            key: "DISTRIBUTOR_PORTAL_BILLING_VIEW",
            displayName: "View",
          },
          {
            id: "DISTRIBUTOR_PORTAL_BILLING_UPLOAD_INVOICE",
            key: "DISTRIBUTOR_PORTAL_BILLING_UPLOAD_INVOICE",
            displayName: "Upload Invoice",
          },
        ],
      },
      {
        id: "DISTRIBUTOR_PORTAL_MY_KYC",
        key: "DISTRIBUTOR_PORTAL_MY_KYC",
        displayName: "My KYC",
        path: "/kyc",
        sectionId: "DISTRIBUTOR_PORTAL",
        actions: [
          {
            id: "DISTRIBUTOR_PORTAL_MY_KYC_VIEW",
            key: "DISTRIBUTOR_PORTAL_MY_KYC_VIEW",
            displayName: "View",
          },
        ],
      },
    ],
  },
  {
    id: "REPORTS",
    key: "REPORTS",
    displayName: "Reports",
    path: "/reports",
    expandable: false, //Decides whether it is a section or a page
    groups: [
      {
        id: "INVESTMENT_SUMMARY_GROUP",
        key: "INVESTMENT_SUMMARY_GROUP",
        displayName: "Investment Summary",
      },
      {
        id: "TRANSACTION_GROUP",
        key: "TRANSACTION_GROUP",
        displayName: "Transactions",
      },
      { id: "DOCUMENT_GROUP", key: "DOCUMENT_GROUP", displayName: "Documents" },
    ],
    pages: [
      {
        id: "COMPLETED_INVESTMENT_REPORT",
        key: "COMPLETED_INVESTMENT_REPORT",
        displayName: "Completed Investment",
        path: "/reports/completed-investments",
        sectionId: "REPORTS",
        groupId: "INVESTMENT_SUMMARY_GROUP",
        actions: [
          {
            id: "COMPLETED_INVESTMENT_REPORT_VIEW",
            key: "COMPLETED_INVESTMENT_REPORT_VIEW",
            displayName: "View",
          },
          {
            id: "COMPLETED_INVESTMENT_REPORT_DOWNLOAD",
            key: "COMPLETED_INVESTMENT_REPORT_DOWNLOAD",
            displayName: "Download",
          },
        ],
      },
      {
        id: "INVESTOR_COUNT_SUMMARY_REPORT",
        key: "INVESTOR_COUNT_SUMMARY_REPORT",
        displayName: "Investor Count Summary",
        path: "/reports/investor-count-summary",
        sectionId: "REPORTS",
        groupId: "INVESTMENT_SUMMARY_GROUP",
        actions: [
          {
            id: "INVESTOR_COUNT_SUMMARY_REPORT_VIEW",
            key: "INVESTOR_COUNT_SUMMARY_REPORT_VIEW",
            displayName: "View",
          },
          {
            id: "INVESTOR_COUNT_SUMMARY_REPORT_DOWNLOAD",
            key: "INVESTOR_COUNT_SUMMARY_REPORT_DOWNLOAD",
            displayName: "Download",
          },
        ],
      },
      {
        id: "INVESTMENT_TRANSACTION_REPORT",
        key: "INVESTMENT_TRANSACTION_REPORT",
        displayName: "Investment Transactions",
        path: "/reports/investment-transactions",
        sectionId: "REPORTS",
        groupId: "TRANSACTION_GROUP",
        actions: [
          {
            id: "INVESTMENT_TRANSACTION_REPORT_VIEW",
            key: "INVESTMENT_TRANSACTION_REPORT_VIEW",
            displayName: "View",
          },
          {
            id: "INVESTMENT_TRANSACTION_REPORT_DOWNLOAD",
            key: "INVESTMENT_TRANSACTION_REPORT_DOWNLOAD",
            displayName: "Download",
          },
        ],
      },
      {
        id: "INTEREST_AND_REPAYMENT_REPORT",
        key: "INTEREST_AND_REPAYMENT_REPORT",
        displayName: "Interest & Repayment",
        path: "/reports/interest-and-repayments",
        sectionId: "REPORTS",
        groupId: "TRANSACTION_GROUP",
        actions: [
          {
            id: "INTEREST_AND_REPAYMENT_REPORT_VIEW",
            key: "INTEREST_AND_REPAYMENT_REPORT_VIEW",
            displayName: "View",
          },
          {
            id: "INTEREST_AND_REPAYMENT_REPORT_DOWNLOAD",
            key: "INTEREST_AND_REPAYMENT_REPORT_DOWNLOAD",
            displayName: "Download",
          },
        ],
      },
      {
        id: "PAYOUT_SUMMARY_REPORT",
        key: "PAYOUT_SUMMARY_REPORT",
        displayName: "Payout Summary",
        path: "/reports/payout-summary",
        sectionId: "REPORTS",
        groupId: "TRANSACTION_GROUP",
        actions: [
          {
            id: "PAYOUT_SUMMARY_VIEW",
            key: "PAYOUT_SUMMARY_VIEW",
            displayName: "View",
          },
          {
            id: "PAYOUT_SUMMARY_DOWNLOAD",
            key: "PAYOUT_SUMMARY_DOWNLOAD",
            displayName: "Download",
          },
        ],
      },
      {
        id: "DEAL_UPDATES_REPORT",
        key: "DEAL_UPDATES_REPORT",
        displayName: "Deal Updates",
        path: "/reports/documents/deal-updates",
        sectionId: "REPORTS",
        groupId: "DOCUMENT_GROUP",
        actions: [
          {
            id: "DEAL_UPDATES_REPORT_VIEW",
            key: "DEAL_UPDATES_REPORT_VIEW",
            displayName: "View",
          },
        ],
      },
    ],
  },
  {
    id: "SUPPORT_PORTAL",
    key: "SUPPORT_PORTAL",
    displayName: "Support Portal",
    path: "/support-portal",
    expandable: false, //Decides whether it is a section or a page
    pages: [
      {
        id: "SUPPORT_PORTAL_PAGE",
        key: "SUPPORT_PORTAL_PAGE",
        displayName: "Support Portal",
        path: "/support-portal",
        isVisible: false, //Decides whether it will be visible in sidebar or not
        sectionId: "SUPPORT_PORTAL",
        actions: [
          {
            id: "SUPPORT_PORTAL_VIEW",
            key: "SUPPORT_PORTAL_VIEW",
            displayName: "View",
          },
          {
            id: "SUPPORT_PORTAL_ADD",
            key: "SUPPORT_PORTAL_ADD",
            displayName: "Add",
          },
        ],
      },
    ],
  },
]

export const getSectionByPageId = pageId => {
  for (const section of SidebarOptions) {
    const page = section.pages?.find(page => page.id === pageId)
    if (page) {
      return {
        section: section,
        page: page,
      }
    }
  }
  return null
}
