import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { useLocation, Link } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import Loader from "common/Loader"
import ReactSelect from "constants/ReactSelect"
import { exceptionReportFilters } from "constants/ConstantFields"
import { toast } from "react-toastify"
import { axiosInstance } from "ConfigAxioxinstance"
import { csvDownloadData, handleValidDate } from "constants/common"
import { JSONToCSVConvertor } from "common/jsontocsv"
import nodata from "../../assets/images/nodata/no-data.svg"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { permissionChecker } from "common/PermissionChecker"
import Unauthorized from "common/Unauthorized"
import BenposProjectCard from "components/Common/BenposProjectCard"
import AuditTrail from "common/AuditTrail/AuditTrail"
import PoliciesCard from "components/Common/PoliciesCard"

const ExceptionFilter = [
  {
    id: 1,
    label: "Missing IFSC",
    endpoint: "exception?category=COMMON&category=BENPOS&ifsc=null",
  },
  {
    id: 2,
    label: "IFSC Not Matched",
    endpoint: "exception?category=COMMON&category=BENPOS&ifsc_matched=false",
  },
  {
    id: 3,
    label: "Bank Account Number Not Matched",
    endpoint:
      "exception?category=COMMON&category=BENPOS&bank_account_matched=false",
  },
]

const ExceptionReport = () => {
  const [loading, setLoading] = useState(false)
  const [tableData, setTableData] = useState([])
  const [selectedReport, setSelectedReport] = useState()
  const [editIfscModal, setEditIfscModal] = useState(false)
  const [selectedData, setSelectedData] = useState()
  const [manualIfcs, setManualIfsc] = useState("")
  const [proofAttachment, setProofAttachment] = useState()
  const [signedUrl, setSignedUrl] = useState("")
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const invIdFromUrl = queryParams.get("investmentId")
  const benposDateFromUrl = queryParams.get("benpos-date")
  const [manualBankAccoutNumber, setManualBankAccoutNumber] = useState("")
  const [selectedExceptionFilter, setSelectedExceptionFilter] = useState(null)
  const [selectedLogId, setSelectedLogId] = useState("")
  const [auditTrailModal, setAuditTrailModal] = useState(false)

  const [accessControlData, setAccessControlData] = useState([])
  const localUser = JSON.parse(localStorage.getItem("userInfo"))
  const isAdmin = localUser?.permissions == "Admin"
  const [policies, setPolicies] = useState([])
  const [approveModal, setApproveModal] = useState(false)
  const [selectedProject, setSelectedProject] = useState()
  const [selectedAction, setSelectedAction] = useState("")
  const [actionReason, setActionReason] = useState("")
  const [consent, setConsent] = useState(false)
  const [myPolicy, setMyPolicy] = useState(null)

  const [checkedReports, setCheckedReports] = useState(
    exceptionReportFilters.map(item => ({ ...item, checked: false }))
  )

  useEffect(() => {
    const permissions = permissionChecker("/benpos-master")
    setAccessControlData(permissions)
  }, [])

  const defaultSorted = [
    {
      dataField: "created_at",
      order: "desc",
    },
  ]

  const toggleIFSCModal = async () => {
    setEditIfscModal(!editIfscModal)
  }

  const handleOrderClick = async data => {
    setSelectedData(data)
    if (data.manual_ifsc_code || data.bank_ifsc) {
      setManualIfsc(data?.manual_ifsc_code || data.bank_ifsc)
    } else if (data.bank_account_number == data.user_account_number) {
      setManualIfsc(data.user_ifsc_code || "")
    }
    setManualIfsc(data?.manual_ifsc_code || data.bank_ifsc)
    setManualBankAccoutNumber(
      data?.manual_account_number || data.bank_account_number
    )
    toggleIFSCModal()
  }

  const getSignedUrlHandler = async file => {
    try {
      const response = await axiosInstance.post("/get-signed-url", {
        fileName: file,
      })
      return response?.data
    } catch (error) {
      throw error
    }
  }

  const DocumentOpenHandler = async link => {
    const url = await getSignedUrlHandler(link)
    window.open(url)
  }

  const getApprovalPolicy = async () => {
    try {
      const res = await axiosInstance.get(
        `/interest-approval-policy?investmentId=${invIdFromUrl}&benpos_date=${benposDateFromUrl}&report_type=EXCEPTION_REPORT`
      )
      setMyPolicy(res.data.data.find(item => item.userId == localUser.id))
      setPolicies(res.data.data)
    } catch (error) {
      toast.error(error.message)
    }
  }

  useEffect(() => {
    getApprovalPolicy()
  }, [])

  const toggleApproveModal = () => {
    setApproveModal(!approveModal)
  }
  const handleApprove = () => {
    setSelectedAction("APPROVE")
    setActionReason("")
    setConsent(false)
    setCheckedReports(checkedReports.map(prev => ({ ...prev, checked: false })))
    toggleApproveModal()
  }
  const handleReject = () => {
    setSelectedAction("REJECT")
    setActionReason("")
    setConsent(false)
    toggleApproveModal()
  }

  const handleReportAction = async (e, values) => {
    try {
      setLoading(true)
      const payload = {
        modifiedById: localUser.id,
        investmentId: invIdFromUrl,
        benpos_date: benposDateFromUrl,
        status: selectedAction == "APPROVE" ? "APPROVED" : "REJECTED",
        report_type: "EXCEPTION_REPORT",
        comment: actionReason,
      }
      const res = await axiosInstance.post(`/interest-approval`, payload)
      if (res) {
        await getApprovalPolicy()
        toast.success(
          `${
            selectedAction == "APPROVE" ? "Approved" : "Rejected"
          } Successfully`
        )
        toggleApproveModal()
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  const tableColumns = (download = false) => {
    let columns = []
    if (
      download == false &&
      selectedReport?.id === "common-benpos-bank-details"
    ) {
      columns.push({
        dataField: "",
        isDummyField: true,
        text: "Edit",
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, order) => {
          if (
            policies.some(item => item.interest_approval?.status == "APPROVED")
          ) {
            return "NA"
          }
          return (
            <>
              <div className="d-flex gap-3" style={{ cursor: "pointer" }}>
                <div
                  className="text-success"
                  onClick={() => handleOrderClick(order)}
                >
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </div>
              </div>
            </>
          )
        },
      })
    }
    columns.push(
      {
        dataField: "investor_name",
        text: "Investor Name",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => {
          return (
            <span>
              {selectedReport?.id == "system-entries"
                ? row.name_on_pan
                : cellContent}
            </span>
          )
        },
      },
      {
        dataField: "category",
        text: "Category",
        sort: true,
      }
    )
    if (selectedReport?.id === "common-benpos-bank-details") {
      columns.push(
        {
          dataField: "bank_ifsc",
          text: "BenPos IFSC",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (benpos_ifsc, row) => {
            if (row.manual_ifsc_code) {
              return (
                <span className={`text-end d-flex align-items-center`}>
                  {row.manual_ifsc_code}
                  <i
                    className="mdi mdi-information-outline font-size-16 ms-2 text-secondary"
                    id={`ifsctooltip-${row.id}`}
                    style={{ cursor: "pointer" }}
                  />
                  <UncontrolledTooltip target={`#ifsctooltip-${row.id}`}>
                    Replaced with Manually added IFCS Code
                  </UncontrolledTooltip>
                </span>
              )
            }

            if (benpos_ifsc) {
              return benpos_ifsc
            }

            if (row.bank_account_number === row.user_account_number) {
              return (
                <span>
                  {row.user_ifsc_code}
                  <i
                    className="mdi mdi-information-outline  font-size-16 ms-2 text-secondary"
                    id={`ifsctooltip-${row.id}`}
                    style={{ cursor: "pointer" }}
                  />
                  <UncontrolledTooltip target={`#ifsctooltip-${row.id}`}>
                    Replaced with Investor IFSC as Account Number was same
                  </UncontrolledTooltip>
                </span>
              )
            }
            return benpos_ifsc
          },
        },
        {
          dataField: "user_ifsc_code",
          text: "Investor IFSC Code",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) => {
            return <span className={`text-end d-block`}>{cellContent}</span>
          },
        },
        {
          dataField: "bank_ifsc_exception",
          text: "IFSC Exception",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) => {
            return (
              <span
                className={`badge badge-pill font-size-12 badge-soft-${
                  row.bank_ifsc
                    ? row.ifsc_matched
                      ? "success"
                      : "danger"
                    : "warning"
                }`}
              >
                {row.bank_ifsc
                  ? row.ifsc_matched
                    ? "IFSC MATCHED"
                    : "IFSC Not Matched"
                  : "Missing IFSC"}
              </span>
            )
          },
        },
        {
          dataField: "bank_account_number",
          text: "Bank Account Number(BenPos)",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) => {
            if (row.manual_account_number) {
              return (
                <span
                  className={`text-end d-flex justify-content-end align-items-center`}
                >
                  {row.manual_account_number}
                  <i
                    className="mdi mdi-information-outline font-size-16 ms-2 text-secondary"
                    id={`bankAccounttooltip-${row.id}`}
                    style={{ cursor: "pointer" }}
                  />
                  <UncontrolledTooltip target={`#bankAccounttooltip-${row.id}`}>
                    Replaced with Manually added Bank Account Number
                  </UncontrolledTooltip>
                </span>
              )
            }
            return <span className={`text-end d-block`}>{cellContent}</span>
          },
        },
        {
          dataField: "user_account_number",
          text: "Bank Account Number(Investor)",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) => {
            return <span className={`text-end d-block`}>{cellContent}</span>
          },
        },
        {
          dataField: "bank_account_number_exception",
          text: "Bank Account Number Exception",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) => {
            return (
              <span
                className={`badge badge-pill font-size-12 badge-soft-${
                  row.bank_account_number == row.user_account_number
                    ? "success"
                    : "danger"
                }`}
              >
                {row.bank_account_number == row.user_account_number
                  ? "Bank Account Number Matched"
                  : "Bank Account Number Not Matched"}
              </span>
            )
          },
        },

        { dataField: "bank_name", text: "BenPos Bank Name", sort: true },

        {
          dataField: "bank_update_proof_file",
          text: "Update Proof Attachment",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) => {
            if (!cellContent) {
              return null
            }
            return (
              <div
                onClick={() => DocumentOpenHandler(cellContent)}
                style={{ cursor: "pointer" }}
              >
                <svg viewBox="0 0 24 24" width="26px" fill={"#556ee6"}>
                  <path d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z" />
                </svg>
              </div>
            )
          },
        }
      )
    } else if (selectedReport?.id === "system-entries") {
      columns.push(
        {
          dataField: "user_ifsc_code",
          text: "Investor IFSC Code",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) => {
            return <span>{cellContent}</span>
          },
        },
        {
          dataField: "user_account_number",
          text: "Investor Account Number",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) => {
            return <span className={`text-end d-block`}>{cellContent}</span>
          },
          // eslint-disable-next-line react/display-name
          headerFormatter: (column, colIndex) => (
            <div className="text-end">{column.text}</div>
          ),
        },
        {
          dataField: "lot_size",
          text: "Investor Lot Size",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) => {
            return <span className={`text-end d-block`}>{cellContent}</span>
          },
          // eslint-disable-next-line react/display-name
          headerFormatter: (column, colIndex) => (
            <div className="text-end">{column.text}</div>
          ),
        },

        {
          dataField: "nil_tds_applicable",
          text: "Nil Tds Applicable",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) => {
            return (
              <span
                className={`${
                  selectedReport?.id === "common-nil-tds" && "text-success"
                }`}
              >
                {cellContent.toString()}
              </span>
            )
          },
        },
        {
          dataField: "user_demat_id",
          text: "Investor Demat Id",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) => {
            return <span>{cellContent}</span>
          },
        }
      )
    } else if (selectedReport?.id === "benpos-entries") {
      columns.push(
        {
          dataField: "debentures",
          text: "BenPos Debenture",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) => {
            return <span className={`text-end d-block`}>{cellContent}</span>
          },
          // eslint-disable-next-line react/display-name
          headerFormatter: (column, colIndex) => (
            <div className="text-end">{column.text}</div>
          ),
        },
        {
          dataField: "bank_ifsc",
          text: "BenPos IFSC",
          sort: true,
        },
        { dataField: "bank_name", text: "BenPos Bank Name", sort: true },
        {
          dataField: "bank_account_number",
          text: "BenPos Account Number",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) => {
            return <span className={`text-end d-block`}>{cellContent}</span>
          },
          // eslint-disable-next-line react/display-name
          headerFormatter: (column, colIndex) => (
            <div className="text-end">{column.text}</div>
          ),
        },
        {
          dataField: "nil_tds_applicable",
          text: "Nil Tds Applicable",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) => {
            return (
              <span
                className={`${
                  selectedReport?.id === "common-nil-tds" && "text-success"
                }`}
              >
                {cellContent.toString()}
              </span>
            )
          },
        },
        {
          dataField: "demat_id",
          text: "BenPos Demat Id",
          sort: true,
        }
      )
    } else if (selectedReport?.id == "common-nil-tds") {
      columns.push(
        {
          dataField: "nil_tds_applicable",
          text: "Nil Tds Applicable",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) => {
            return (
              <span
                className={`${
                  selectedReport?.id === "common-nil-tds" && "text-success"
                }`}
              >
                {cellContent.toString()}
              </span>
            )
          },
        },
        {
          dataField: "total_benpos_debenture",
          text: "BenPos Debenture",
          sort: true, // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) => {
            return (
              <span
                className={`${
                  selectedReport?.id === "common-debenture-mismatch"
                    ? row.total_benpos_debenture ===
                      row.total_investor_debenture
                      ? "text-success"
                      : "text-danger"
                    : ""
                } text-end d-block`}
              >
                {cellContent}
              </span>
            )
          },
          // eslint-disable-next-line react/display-name
          headerFormatter: (column, colIndex) => (
            <div className="text-end">{column.text}</div>
          ),
        },
        {
          dataField: "total_investor_debenture",
          text: "Investor Lot Size",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) => {
            return <span className={`text-end d-block`}>{cellContent}</span>
          },
          // eslint-disable-next-line react/display-name
          headerFormatter: (column, colIndex) => (
            <div className="text-end">{column.text}</div>
          ),
        },
        // {
        //   dataField: "bank_ifsc",
        //   text: "BenPos IFSC",
        //   sort: true,
        // },
        // {
        //   dataField: "user_ifsc_code",
        //   text: "Investor IFSC Code",
        //   sort: true,
        //   // eslint-disable-next-line react/display-name
        //   formatter: (cellContent, row) => {
        //     return <span className={`text-end d-block`}>{cellContent}</span>
        //   },
        // },
        // { dataField: "bank_name", text: "BenPos Bank Name", sort: true },
        // {
        //   dataField: "bank_account_number",
        //   text: "BenPos Account Number",
        //   sort: true,
        //   // eslint-disable-next-line react/display-name
        //   formatter: (cellContent, row) => {
        //     return <span className={`text-end d-block`}>{cellContent}</span>
        //   },
        // },
        // {
        //   dataField: "user_account_number",
        //   text: "Investor Account Number",
        //   sort: true,
        //   // eslint-disable-next-line react/display-name
        //   formatter: (cellContent, row) => {
        //     return <span className={`text-end d-block`}>{cellContent}</span>
        //   },
        // },

        {
          dataField: "demat_id",
          text: "BenPos Demat Id",
          sort: true, // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) => {
            return <span>{cellContent}</span>
          },
        },
        {
          dataField: "user_demat_id",
          text: "Investor Demat Id",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) => {
            return <span>{cellContent}</span>
          },
        }
      )
    } else if (selectedReport?.id == "common-debenture-mismatch") {
      columns.push(
        {
          dataField: "total_benpos_debenture",
          text: "BenPos Debenture",
          sort: true, // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) => {
            return (
              <span
                className={`${
                  selectedReport?.id === "common-debenture-mismatch"
                    ? row.total_benpos_debenture ===
                      row.total_investor_debenture
                      ? "text-success"
                      : "text-danger"
                    : ""
                } text-end d-block`}
              >
                {cellContent}
              </span>
            )
          },
          // eslint-disable-next-line react/display-name
          headerFormatter: (column, colIndex) => (
            <div className="text-end">{column.text}</div>
          ),
        },
        {
          dataField: "total_investor_debenture",
          text: "Investor Lot Size",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) => {
            return <span className={`text-end d-block`}>{cellContent}</span>
          },
          // eslint-disable-next-line react/display-name
          headerFormatter: (column, colIndex) => (
            <div className="text-end">{column.text}</div>
          ),
        },
        {
          dataField: "nil_tds_applicable",
          text: "Nil Tds Applicable",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) => {
            return (
              <span
                className={`${
                  selectedReport?.id === "common-nil-tds" && "text-success"
                }`}
              >
                {cellContent.toString()}
              </span>
            )
          },
        },
        // {
        //   dataField: "bank_ifsc",
        //   text: "BenPos IFSC",
        //   sort: true,
        // },
        // {
        //   dataField: "user_ifsc_code",
        //   text: "Investor IFSC Code",
        //   sort: true,
        //   // eslint-disable-next-line react/display-name
        //   formatter: (cellContent, row) => {
        //     return <span className={`text-end d-block`}>{cellContent}</span>
        //   },
        // },
        // { dataField: "bank_name", text: "BenPos Bank Name", sort: true },
        // {
        //   dataField: "bank_account_number",
        //   text: "BenPos Account Number",
        //   sort: true,
        //   // eslint-disable-next-line react/display-name
        //   formatter: (cellContent, row) => {
        //     return <span className={`text-end d-block`}>{cellContent}</span>
        //   },
        // },
        // {
        //   dataField: "user_account_number",
        //   text: "Investor Account Number",
        //   sort: true,
        //   // eslint-disable-next-line react/display-name
        //   formatter: (cellContent, row) => {
        //     return <span className={`text-end d-block`}>{cellContent}</span>
        //   },
        // },

        {
          dataField: "demat_id",
          text: "BenPos Demat Id",
          sort: true, // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) => {
            return <span>{cellContent}</span>
          },
        },
        {
          dataField: "user_demat_id",
          text: "Investor Demat Id",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) => {
            return <span>{cellContent}</span>
          },
        }
      )
    }
    columns.push({
      dataField: "",
      text: "Logs",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => {
        const localUser = JSON.parse(localStorage.getItem("userInfo"))
        if (localUser?.permissions === "Admin") {
          return (
            <>
              <Link
                to="#"
                onClick={() => {
                  setSelectedLogId(row.benpos_id)
                  setAuditTrailModal(!auditTrailModal)
                }}
              >
                <i
                  className="mdi mdi-content-paste font-size-18"
                  id="logsToolTip"
                />
              </Link>
              <UncontrolledTooltip placement="top" target="logsToolTip">
                Logs
              </UncontrolledTooltip>
            </>
          )
        } else {
          return "N/A"
        }
      },
    })
    return columns
  }

  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    // paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "60",
        value: 60,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  }

  const filterUrl = `${invIdFromUrl ? `&investmentId=${invIdFromUrl}` : ""}${
    benposDateFromUrl ? `&benpos_date=${benposDateFromUrl}` : ""
  }`

  const masterData = async () => {
    if (!selectedReport) {
      return null
    }
    setLoading(true)
    const getUrl = `/benpos-report/${
      selectedReport?.filterUrl || selectedReport.endpoint
    }${filterUrl}`

    try {
      const response = await axiosInstance.get(getUrl)
      if (response) {
        const data = response?.data?.data || response?.data
        const total = response?.data?.length || response?.length
        setTableData(data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }

  const { SearchBar } = Search

  useEffect(async () => {
    if (selectedReport) {
      masterData()
    }
  }, [selectedReport])

  const downloadData = async () => {
    try {
      const data = tableData
      const csvTableHeaders = tableColumns(true)
      const str = JSON.stringify(data)
      const arr = JSON.parse(str)
      arr.forEach(el => {
        if (selectedReport.id === "common-benpos-bank-details") {
          el.bank_ifsc = el.manual_ifsc_code
            ? el.manual_ifsc_code
            : el.bank_account_number === el.user_account_number
            ? el.user_ifsc_code
            : el.bank_ifsc
          el.bank_account_number_exception =
            el.bank_account_number == el.user_account_number
              ? "Bank Account Number Matched"
              : "Bank Account Number Not Matched"
          el.bank_ifsc_exception = el.bank_ifsc
            ? el.ifsc_matched
              ? "IFSC Matched"
              : "IFSC Not Matched"
            : "Missing IFSC"
        }
        if (selectedReport?.id == "system-entries") {
          el.investor_name = el.name_on_pan
        }
        return el
      })
      const downloadableArr = csvDownloadData(csvTableHeaders, arr)
      JSONToCSVConvertor(downloadableArr, selectedReport?.name, true)
    } catch (error) {
      toast.error(error?.message)
    }
  }

  const uploadAttachmentHandler = async () => {
    try {
      const formData = new FormData()
      formData.append("file", proofAttachment)
      const response = await axiosInstance.post(
        `/file-upload/?type=exception_report`,
        formData
      )
      if (response) {
        return response?.data?.[0]?.name
      }
    } catch (error) {
      toast.error(error?.message)
      throw error
    }
  }
  const handleFileChange = e => {
    const file = e.target.files[0]
    if (file) {
      console.log(file)
      setProofAttachment(file)
    }
  }

  const handleValidIfscForm = async (e, values) => {
    if (!manualIfcs || !proofAttachment || !manualBankAccoutNumber) {
      toast.error("All fields are required")
      return
    }
    setLoading(true)
    try {
      const proofFile = await uploadAttachmentHandler()
      let prev_ifsc = selectedData.bank_ifsc
      if (
        !prev_ifsc &&
        selectedData.bank_account_number == selectedData.user_account_number
      ) {
        prev_ifsc = selectedData.user_ifsc_code
      }
      const response = await axiosInstance.patch(
        `benpos/${selectedData?.benpos_id}`,
        {
          manual_ifsc_code: prev_ifsc == manualIfcs ? undefined : manualIfcs,
          manual_account_number:
            selectedData.bank_account_number == manualBankAccoutNumber
              ? undefined
              : manualBankAccoutNumber,
          bank_update_proof_file: proofFile,
        }
      )
      if (response) {
        toast.success("Bank Details Updated successfully")
        masterData()
        toggleIFSCModal()
        setManualIfsc("")
        setManualBankAccoutNumber("")
        setProofAttachment()
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        {accessControlData?.permissions?.BENPOS_EXCEPTION_REPORT ? (
          <Container fluid>
            <Breadcrumbs
              breadcrumbItem={
                accessControlData?.permissions?.BENPOS_EXCEPTION_REPORT
              }
              breadcrumbRight={"Benpos Master / Exception Report"}
            />
            <BenposProjectCard
              investmentId={invIdFromUrl}
              benpos_date={benposDateFromUrl}
              onLoadComplete={setSelectedProject}
            />
            <PoliciesCard policies={policies} />
            <Row>
              <Col xs="12">
                <Row className="mb-2 row justify-content-between align-items-center">
                  <div className="col-md-8">
                    <Row className="mb-4">
                      <div className="col-md-6">
                        <label className="card-title">Report Type</label>
                        <ReactSelect
                          users={exceptionReportFilters}
                          multiOptionLabel={true}
                          optionLabelKeys={["name"]}
                          selectedOption={selectedReport}
                          setSelectedOption={item => {
                            setSelectedExceptionFilter(null)
                            setSelectedReport(item)
                          }}
                        />
                      </div>
                    </Row>
                  </div>
                  {selectedReport ? (
                    <div className="col-auto">
                      {accessControlData?.permissions?.BENPOS_DOWNLOAD ? (
                        <>
                          {myPolicy?.id ? (
                            <>
                              <Button
                                type="button"
                                color="success"
                                className="btn-rounded  mb-2 me-2"
                                style={{ marginRight: "3px" }}
                                onClick={handleApprove}
                                // disabled={
                                //   myApprovalStatus?.status == "APPROVED"
                                // }
                              >
                                <i className="mdi mdi-check-circle"></i> Approve
                              </Button>
                              <Button
                                type="button"
                                color="danger"
                                className="btn-rounded  mb-2 me-2"
                                style={{ marginRight: "3px" }}
                                onClick={handleReject}
                                // disabled={
                                //   myApprovalStatus?.status == "REJECTED"
                                // }
                              >
                                <i className="mdi mdi-close-circle"></i> Reject
                              </Button>
                            </>
                          ) : null}
                          <Button
                            type="button"
                            color="primary"
                            className="btn-rounded  mb-2 me-2"
                            style={{ marginRight: "3px" }}
                            onClick={downloadData}
                          >
                            <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                            {accessControlData?.permissions?.BENPOS_DOWNLOAD}
                          </Button>
                        </>
                      ) : null}
                    </div>
                  ) : null}
                </Row>
                {!selectedReport && !loading ? (
                  <Row>
                    <Col lg="12">
                      <div className="text-center">
                        <Row className="justify-content-center">
                          <Col sm="4">
                            <div className="maintenance-img">
                              <img
                                src={nodata}
                                alt=""
                                className="img-fluid mx-auto d-block"
                              />
                            </div>
                          </Col>
                        </Row>
                        <h4 className="mt-5">Please select a report</h4>
                      </div>
                    </Col>
                  </Row>
                ) : null}

                {selectedReport && !loading ? (
                  <Card>
                    <CardBody>
                      <ToolkitProvider
                        keyField="id"
                        data={tableData}
                        columns={tableColumns()}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2 row align-items-end">
                              {selectedReport.id ==
                              "common-benpos-bank-details" ? (
                                <Col md={3}>
                                  <div className="mb-3">
                                    <label>Exception Type</label>
                                    <ReactSelect
                                      users={ExceptionFilter}
                                      selectedOption={selectedExceptionFilter}
                                      setSelectedOption={filter => {
                                        setSelectedExceptionFilter(filter)
                                        setSelectedReport({
                                          ...selectedReport,
                                          filterUrl: filter?.endpoint || "",
                                        })
                                      }}
                                      multiOptionLabel={true}
                                      optionLabelKeys={["label"]}
                                      isClearable={true}
                                    />
                                  </div>
                                </Col>
                              ) : null}
                              <Col md={3}>
                                <div className="search-box me-2 mb-1 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <BootstrapTable
                                  keyField="id"
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  columns={tableColumns()}
                                  data={tableData}
                                  pagination={paginationFactory(pageOptions)}
                                  wrapperClasses={"table-responsive mb-4"}
                                  classes={
                                    "table align-middle table-nowrap table-check"
                                  }
                                  headerWrapperClasses={"table-light"}
                                  {...toolkitProps.baseProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    </CardBody>
                  </Card>
                ) : null}
              </Col>
            </Row>
            <Modal
              isOpen={editIfscModal}
              toggle={toggleIFSCModal}
              className="modal-lg"
              backdrop="static"
              keyboard={false}
              scrollable={true}
            >
              <div className="modal-header">
                <h4 className="modal-title mt-0">Update Bank Details</h4>
                <button
                  type="button"
                  onClick={toggleIFSCModal}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <ModalBody className="p-4 pb-0">
                <Row>
                  <Col className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <div>Project Name</div>
                        <p
                          className="fw-medium font-size-15"
                          style={{
                            fontSize: "13px",
                          }}
                        >
                          {selectedData?.project_name}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <div>BenPos Date</div>
                        <p
                          className="fw-medium font-size-15"
                          style={{
                            fontSize: "13px",
                          }}
                        >
                          {handleValidDate(selectedData?.benpos_date)}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <div>Investor Name</div>
                        <p
                          className="fw-medium font-size-15"
                          style={{
                            fontSize: "13px",
                          }}
                        >
                          {selectedData?.investor_name}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <div>Category</div>
                        <p
                          className="fw-medium font-size-15"
                          style={{
                            fontSize: "13px",
                          }}
                        >
                          {selectedData?.category}
                        </p>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <div>Investor Bank Account Number</div>
                        <p
                          className="fw-medium font-size-15"
                          style={{
                            fontSize: "13px",
                          }}
                        >
                          {selectedData?.user_account_number || "NA"}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <div>Investor IFSC Code</div>
                        <p
                          className="fw-medium font-size-15"
                          style={{
                            fontSize: "13px",
                          }}
                        >
                          {selectedData?.user_ifsc_code || "NA"}
                        </p>
                      </div>
                    </div>

                    <AvForm>
                      <div className="row">
                        <div className="mb-3 col-md-6">
                          <AvField
                            name="manual_account_number"
                            label="Benpos Bank Account Number"
                            type="text"
                            errorMessage="Invalid value"
                            sort="true"
                            validate={{
                              required: { value: true },
                            }}
                            value={manualBankAccoutNumber}
                            onChange={e =>
                              setManualBankAccoutNumber(e.target.value)
                            }
                          />
                        </div>
                        <div className="mb-3 col-md-6">
                          <AvField
                            name="manual_ifcs_code"
                            label="Benpos IFSC Code"
                            type="text"
                            errorMessage="Invalid value"
                            sort="true"
                            validate={{
                              required: { value: true },
                            }}
                            value={manualIfcs}
                            onChange={e => setManualIfsc(e.target.value)}
                          />
                        </div>
                        <div className="mb-3 col-md-12">
                          <AvField
                            className="form-control"
                            type="file"
                            id="formFile"
                            validate={{
                              required: { value: false },
                            }}
                            name="Attachments"
                            label="Upload Proof Attachment"
                            onChange={handleFileChange}
                            accept="image/*,.pdf"
                          />
                        </div>
                      </div>
                    </AvForm>
                    {selectedData?.bank_update_proof_file && (
                      <a
                        target="_blank"
                        rel="noreferrer"
                        className="block text-end mb-2"
                        onClick={() => {
                          DocumentOpenHandler(
                            selectedData?.bank_update_proof_file
                          )
                        }}
                      >
                        View Uploaded Proof Attachment
                      </a>
                    )}
                  </Col>
                </Row>
                <Row>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={handleValidIfscForm}
                      disabled={!proofAttachment || !manualIfcs}
                    >
                      Submit
                    </button>
                  </div>
                </Row>
              </ModalBody>
            </Modal>
            <AuditTrail
              recordId={selectedLogId}
              modal={auditTrailModal}
              setModal={setAuditTrailModal}
            />
            {/* Approve Modal */}
            <Modal isOpen={approveModal} toggle={toggleApproveModal}>
              <ModalHeader toggle={toggleApproveModal} tag="h4">
                {selectedAction == "APPROVE" ? "Approve" : "Reject"} Exception
                Report
              </ModalHeader>
              <ModalBody>
                <AvForm onValidSubmit={handleReportAction}>
                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <div>Project Name</div>
                        <p
                          className="fw-medium font-size-15"
                          style={{
                            fontSize: "13px",
                          }}
                        >
                          {selectedProject?.project_name || "NA"}
                        </p>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <div>Benpos Date</div>
                        <p
                          className="fw-medium font-size-15"
                          style={{
                            fontSize: "13px",
                          }}
                        >
                          {handleValidDate(benposDateFromUrl) || "NA"}
                        </p>
                      </div>
                    </Col>
                    {selectedAction == "APPROVE" ? (
                      <div>
                        <div className="mb-2 fw-bold">I have checked</div>
                        {checkedReports.map(item => (
                          <div className="mb-1" key={item.id}>
                            <AvField
                              name={item.id}
                              label={
                                <span
                                  className={
                                    item.checked ? "fw-normal" : "text-muted"
                                  }
                                >
                                  {item.name}
                                </span>
                              }
                              type="checkbox"
                              checked={item?.checked || false}
                              value={item?.checked || false}
                              onChange={e =>
                                setCheckedReports(prev =>
                                  prev.map(obj => ({
                                    ...obj,
                                    checked:
                                      obj.id == item.id
                                        ? e.target.checked
                                        : obj.checked,
                                  }))
                                )
                              }
                            ></AvField>
                          </div>
                        ))}
                      </div>
                    ) : null}
                    <div className="mt-2">
                      <AvField
                        name="description"
                        label={
                          selectedAction == "APPROVE" ? "Comment" : "Reason"
                        }
                        type="textarea"
                        errorMessage="Invalid value"
                        validate={{
                          required: { value: true },
                        }}
                        value={actionReason || ""}
                        onChange={e => setActionReason(e.target.value)}
                        rows={3}
                      ></AvField>
                    </div>
                    <div className="mt-4">
                      <AvField
                        name={"consent"}
                        label={
                          <span className={consent ? "" : "text-muted"}>
                            I have check all the exception reports and i{" "}
                            {selectedAction} it.
                          </span>
                        }
                        type="checkbox"
                        checked={consent}
                        value={consent}
                        onChange={e => setConsent(e.target.checked)}
                      ></AvField>
                    </div>
                    <Col>
                      <div className="text-end gap-4 mt-2">
                        <button
                          type="submit"
                          className={`btn w-100 ${
                            selectedAction == "APPROVE"
                              ? "btn-success"
                              : "btn-danger"
                          } save-user`}
                          disabled={
                            selectedAction == "APPROVE"
                              ? checkedReports.some(item => !item.checked) ||
                                !consent
                              : !consent
                          }
                        >
                          {selectedAction == "APPROVE" ? "Approve" : "Reject"}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </ModalBody>
            </Modal>
          </Container>
        ) : (
          <Unauthorized />
        )}
      </div>
    </React.Fragment>
  )
}

export default ExceptionReport
